/* eslint-disable no-undef */
/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This proposal uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { notification } from 'antd'
import { call, put } from 'redux-saga/effects'
import ProposalActions from '../appRedux/reducers/ProposalRedux'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * proposalRequest (api, action) {
    const { data } = action
    // console.log('TES', data)
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.proposalRequest, data)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        // console.log('proposal response.data', response.data)
        yield put(ProposalActions.proposalSuccess(response.data.results, response.data.meta))
    } else {
        yield put(ProposalActions.proposalFailure())
    }
}

export function * proposalCrRequest (api, action) {
    const { data } = action
    // console.log('TES', data)
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.proposalCrRequest, data)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        // console.log('proposal response.data', response.data)
        yield put(ProposalActions.proposalCrSuccess(response.data.results, response.data.meta))
    } else {
        yield put(ProposalActions.proposalCrFailure())
    }
}

export function * proposalDetailRequest (api, action) {
    const { id, datadetail, cb } = action
    
    const response = yield call(api.proposalRequest, datadetail, id)

    // success?
    if (response.ok) {
        console.log('test propsal detail', response.data.result)
        if (cb) cb(response.data.result)
        yield put(ProposalActions.proposalDetailSuccess(response.data.result))
    } else {
        // if (cb) cb(null)
        // yield put(ProposalActions.proposalDetailFailure())
        if (response.data && response.data.meta && response.data.meta.message) {
            let err = (response.data && response.data.meta && response.data.meta.message) ? response.data.meta.message : 'Error Get Detail Proposal'
            notification.error({
                message: 'Failed',
                description: err,
                placement: 'topRight',
            });
        }
        if (cb) cb(null)
        yield put(ProposalActions.proposalDetailFailure())
    }
}

export function * proposalCrDetailRequest (api, action) {
    const { id, datadetail, cb } = action
    
    const response = yield call(api.proposalCrRequest, datadetail, id)

    // success?
    if (response.ok) {
        console.log('test propsal CR detail', JSON.stringify(response))
        if (cb) cb(response.data.result)
        yield put(ProposalActions.proposalCrDetailSuccess(response.data.result))
    } else {
        // if (cb) cb(null)
        // yield put(ProposalActions.proposalCrDetailFailure())
        if (response.data && response.data.meta && response.data.meta.message) {
            let err = (response.data && response.data.meta && response.data.meta.message) ? response.data.meta.message : 'Error Get Detail Proposal'
            notification.error({
                message: 'Failed',
                description: err,
                placement: 'topRight',
            });
        }
        if (cb) cb(null)
        yield put(ProposalActions.proposalCrDetailFailure())
    }
}

export function * proposalCopyRequest (api, action) {
    const { datacopy, act, cb } = action
    console.log('asdsaproposalCopyRequest')
    let response = null
    if (act === 'revisi') {
        response = yield call(api.proposalCopyRevisiRequest, datacopy)
    } else {
        response = yield call(api.proposalCopyRequest, datacopy)
    }
 
    // success?
    if (response.ok) {
        console.log('test propsal copy', response.data.result)
        if (cb) cb(response.data.result)
        yield put(ProposalActions.proposalCopySuccess(response.data.result))
    } else { 
        if (response.data && response.data.meta && response.data.meta.message) {
            let err = (response.data && response.data.meta && response.data.meta.message) ? response.data.meta.message : 'Error Get Detail Proposal'
            notification.error({
                message: 'Failed',
                description: err,
                placement: 'topRight',
            });
        }
        if (cb) cb(null)
        yield put(ProposalActions.proposalCopyFailure())
    }
}
 
export function * proposalCreateRequest(api, action) {
    const { datacreate, cb } = action 
    try {
        const formdata = new FormData() 
        formdata.append('document', JSON.stringify(datacreate)) 
        for (const key in datacreate.file) {
        // console.log(datacreate[key])
            if (!datacreate.file[key].status || (datacreate.file[key].status && datacreate.file[key].status != 'done')) {
                formdata.append('file', datacreate.file[key].originFileObj, datacreate.file[key].name)  
            }
        } 

        let response = null
        if (datacreate.isEdit) {
            response = yield call(api.proposalEditRequest, formdata)
        } else {
            response = yield call(api.proposalCreateRequest, formdata)
        }
 
        // success?
        if (response.ok) {
            console.log('test propsal create', response.data)
            notification.success({
                message: 'Success',
                description: response.data.message,
                placement: 'topRight',
            });
            if (cb) cb(response.data)
            yield put(ProposalActions.proposalCreateSuccess(response.data))
        } else {
            if (response.data && response.data.meta && response.data.meta.message) {
                let err = (response.data && response.data.meta && response.data.meta.message) ? response.data.meta.message : 'Error Create Proposal'
                notification.error({
                    message: 'Failed',
                    description: err,
                    placement: 'topRight',
                });
            }
            if (cb) cb(null, 'backenderror')
            yield put(ProposalActions.proposalCreateFailure())
        }
    } catch(e) {
        if (cb) cb(null, e)
        yield put(ProposalActions.proposalCreateFailure())
    }
}

export function * proposalDeleteFileRequest (api, action) {
    const { datadeletefile, cb } = action
    
    const response = yield call(api.proposalDeleteFileRequest, datadeletefile)

    // success?
    if (response.ok) {
        console.log('test delete proposal file', response.data)
        notification.success({
            message: 'Success',
            description: 'File berhasil di hapus',
            placement: 'topRight',
        });
        if (cb) cb(true)
        yield put(ProposalActions.proposalDeleteFileSuccess(response.data))
    } else {
        notification.error({
            message: 'Failed',
            description: 'File gagal di hapus',
            placement: 'topRight',
        });
        if (cb) cb(false)
        yield put(ProposalActions.proposalDeleteFileFailure())
    }
}

export function * proposalKirimRequest (api, action) {
    const { datakirim, cb } = action
    
    const response = yield call(api.proposalKirimRequest, datakirim)

    // success?
    if (response.ok) {
        console.log('test propsal kirim', response.data.result)
        notification.success({
            message: 'Success',
            description: 'Berhasil Kirim',
            placement: 'topRight',
        });
        if (cb) cb(true)
        yield put(ProposalActions.proposalKirimSuccess(response.data.result))
    } else {
        if (response.data && response.data.meta && response.data.meta.message) {
            let err = (response.data && response.data.meta && response.data.meta.message) ? response.data.meta.message : 'Gagal Kirim Proposal'
            notification.error({
                message: 'Failed',
                description: err,
                placement: 'topRight',
            });
        }
        if (cb) cb(false)
        yield put(ProposalActions.proposalKirimFailure())
    }
}

export function * proposalDeleteRequest (api, action) {
    const { datadelete, cb } = action
    
    const response = yield call(api.proposalDeleteRequest, datadelete)

    // success?
    if (response.ok) {
        console.log('test propsal delete', response.data.result)
        if (cb) cb(response.data.result)
        yield put(ProposalActions.proposalDeleteSuccess(datadelete))
    } else {
        if (cb) cb(null)
        yield put(ProposalActions.proposalDeleteFailure())
    }
}

export function * proposalApRjRequest (api, action) {
    const { dataaprj, cb } = action
    
    const response = yield call(api.proposalApRjRequest, dataaprj)
    // success?
    if (response.ok) {
        console.log('test propsal aprj', response.data)
        notification.success({
            message: 'Success',
            description: response.data.message,
            placement: 'topRight',
        });
        if (cb) cb(response.data)
        yield put(ProposalActions.proposalApRjSuccess(response.data))
    } else {
        if (response.data && response.data.meta && response.data.meta.message) {
            let err = (response.data && response.data.meta && response.data.meta.message) ? response.data.meta.message : 'Gagal Approve / Reject Proposal'
            notification.error({
                message: 'Failed',
                description: err,
                placement: 'topRight',
            });
        }
        if (cb) cb(null)
        yield put(ProposalActions.proposalApRjFailure())
    }
}

export function * proposalDasboardRequest (api, action) {
    const { datadashboard } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.proposalDasboardRequest, datadashboard)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        // console.log('proposal response.data', response.data)
        yield put(ProposalActions.proposalDasboardSuccess(response.data.results, response.data.meta))
    } else {
        yield put(ProposalActions.proposalDasboardFailure())
    }
}

export function * proposalCancelRequest (api, action) {
    const { datacancel, cb } = action
    
    const response = yield call(api.proposalCancelRequest, datacancel)
    // success?
    if (response.ok) {
        console.log('test propsal CANCEL', response.data)
        notification.success({
            message: 'Success',
            description: response.data.message,
            placement: 'topRight',
        });
        if (cb) cb(response.data)
        yield put(ProposalActions.proposalCancelSuccess(response.data))
    } else {
        if (response.data && response.data.meta && response.data.meta.message) {
            let err = (response.data && response.data.meta && response.data.meta.message) ? response.data.meta.message : 'Gagal Cancel Proposal'
            notification.error({
                message: 'Failed',
                description: err,
                placement: 'topRight',
            });
        }
        if (cb) cb(null)
        yield put(ProposalActions.proposalCancelFailure())
    }
}

export function * proposalReversalRequest (api, action) {
    const { datareversal, cb } = action
    console.log('datareversal kirim ke server', datareversal)
    const response = yield call(api.proposalReversalRequest, datareversal)
    // success?
    if (response.ok) {
        console.log('test propsal Reversal', response.data)
        notification.success({
            message: 'Success',
            description: response.data.message,
            placement: 'topRight',
        });
        if (cb) cb(response.data)
        yield put(ProposalActions.proposalReversalSuccess(response.data))
    } else {
        if (response.data && response.data.meta && response.data.meta.message) {
            let err = (response.data && response.data.meta && response.data.meta.message) ? response.data.meta.message : 'Proposal Reversal Gagal'
            notification.error({
                message: 'Failed',
                description: err,
                placement: 'topRight',
            });
        }
        if (cb) cb(null)
        yield put(ProposalActions.proposalReversalFailure())
    }
}

export function * proposalCrProcessRequest (api, action) {
    const { datacrproc, cb } = action
    console.log('datacrproc kirim ke server', datacrproc)
    const response = yield call(api.proposalCrProcessRequest, datacrproc)
    // success?
    if (response.ok) {
        console.log('test process cr ', response.data)
        notification.success({
            message: 'Success',
            description: response.data.message,
            placement: 'topRight',
        });
        if (cb) cb(response.data)
        yield put(ProposalActions.proposalCrProcessSuccess(response.data))
    } else {
        if (response.data && response.data.meta && response.data.meta.message) {
            let err = (response.data && response.data.meta && response.data.meta.message) ? response.data.meta.message : 'Proposal Reversal Gagal'
            notification.error({
                message: 'Failed',
                description: err,
                placement: 'topRight',
            });
        }
        if (cb) cb(null)
        yield put(ProposalActions.proposalCrProcessFailure())
    }
}

