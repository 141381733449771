import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    fkrBarangRequest: ['data', 'cb'],
    fkrBarangSuccess: ['payload', 'pagination'],
    fkrBarangFailure: null,

    // testing tambahan
    fkrBarangNoticeBAPRequest:['data','cb'],
    fkrBarangNoticeBAPSuccess: ['payloaddetail'],
    fkrBarangNoticeBAPFailure: null,


    fkrBarangDetailRequest: ['datadetail', 'id'],
    fkrBarangDetailSuccess: ['payloaddetail'],
    fkrBarangDetailFailure: null,
    fkrBarangSetDetail: ['propertykey', 'val'],

    fkrBarangAddRequest: ['dataadd', 'cb'],
    fkrBarangAddSuccess: ['payloadadd'],
    fkrBarangAddFailure: null,

    fkrBarangDeleteRequest: ['datadelete', 'cb'],
    fkrBarangDeleteSuccess: ['payloaddelete'],
    fkrBarangDeleteFailure: null,

    fkrBarangEditRequest: ['dataedit', 'cb'],
    fkrBarangEditSuccess: ['payloadedit'],
    fkrBarangEditFailure: null,

    fkrBarangClearDetail: null,
    fkrBarangReset: null,

    fkrBarangUploadRequest: ['dataupload', 'cb'],
    fkrBarangUploadSuccess: ['payloadupload'],
    fkrBarangUploadFailure: null,

    fkrBarangApproveRejectRequest : ['act', 'dataar', 'cb'],
    fkrBarangApproveRejectSuccess: ['payloadar'],
    fkrBarangApproveRejectFailure: null,

    fkrBarangOnLoadRequest: ['dataonload', 'cb'],
    fkrBarangOnLoadSuccess: ['payloadonload'],
    fkrBarangOnLoadFailure: null,

    fkrBarangPrintRequest: ['dataprint', 'cb'],
    fkrBarangPrintSuccess: ['payloadprint'],
    fkrBarangPrintFailure: null,
})

export const FkrBarangTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: null,
    fetching: null,
    payload: [],
    error: null,

    datadetail: null,
    fetchingdetail: null,
    payloaddetail: null
    // {
    //     fkr_id:null,
    //     isactive:null,
    //     created:null,
    //     createdby:null,
    //     updated:null,
    //     updatedby:null,
    //     nomor_fkr:null,
    //     bulan:null,
    //     tahun:null,
    //     m_distributor_id:null,
    //     nama_pajak:null,
    //     nama_distributor:null,
    //     nama_channel:null,
    //     tujuan_retur:null,
    //     status:null,
    //     reason:null,
    //     lines: []
    // }
    ,
    errordetail: null,

    dataadd: null,
    fetchingadd: null,
    payloadadd: null,
    erroradd: null,

    dataedit: null,
    fetchingedit: null,
    payloadedit: null,
    erroredit: null,

    datadelete: null,
    fetchingdelete: null,
    payloaddelete: null,
    errordelete: null,

    dataupload: null,
    fetchingupload: null,
    payloadupload: null,
    errorupload: null,
    
    dataar: null,
    fetchingar: null,
    payloadar: null,
    errorar: null,

    dataprint: null,
    fetchingprint: null,
    payloadprint: null,
    errorprint: null,
})

/* ------------- Selectors ------------- */

export const FkrBarangSelectors = {
    getData: state => state.fkrbarang.data
}

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
    state.merge({ fetching: true, data })

// successful api lookup
export const success = (state, action) => {
    const { payload, pagination } = action
    return state.merge({ fetching: false, error: null, payload, pagination: (pagination) ? pagination : state.pagination })
}

// Something went wrong somewhere.
export const failure = state =>
    state.merge({ fetching: false, error: true })


//DETAIL
export const requestdetail = (state, { datadetail }) =>
    state.merge({ fetchingdetail: true, datadetail, payloaddetail: null })

// successful api lookup
export const successdetail = (state, action) => {
    const { payloaddetail } = action
    return state.merge({ fetchingdetail: false, errordetail: null, payloaddetail })
}

// Something went wrong somewhere.
export const failuredetail = state =>
    state.merge({ fetchingdetail: false, errordetail: true, payloaddetail: null })

export const setdetail = (state, action) => {
    const { propertykey, val } = action

    return state.merge({ payloaddetail: {...state.payloaddetail, [propertykey]: val } })
}

//ADD
export const requestadd = (state, { dataadd }) =>
    state.merge({ fetchingadd: true, dataadd, payloadadd: null })

// successful api lookup
export const successadd = (state, action) => {
    const { payloadadd } = action
    return state.merge({ fetchingadd: false, erroradd: null, payloadadd/*, payload:[...state.payload, payloadadd]*/ })
}

// Something went wrong somewhere.
export const failureadd = state =>
    state.merge({ fetchingadd: false, erroradd: true, payloadadd: null })


//EDIT
export const requestedit = (state, { dataedit }) =>
    state.merge({ fetchingedit: true, dataedit, payloadedit: null })

// successful api lookup
export const successedit = (state, action) => {
    const { payloadedit } = action
    return state.merge({ fetchingedit: false, erroredit: null, payloadedit/*, payload:[...state.payload, payloadedit]*/ })
}

// Something went wrong somewhere.
export const failureedit = state =>
    state.merge({ fetchingedit: false, erroredit: true, payloadedit: null })


//DELETE
export const requestdelete = (state, { datadelete }) =>
    state.merge({ fetchingdelete: true, datadelete, payloaddelete: null })

// successful api lookup
export const successdelete = (state, action) => {
    const { payloaddelete } = action
    return state.merge({ fetchingdelete: false, errordelete: null, payloaddelete, payload:[...state.payload.filter( x => x.m_fkrBarang_id !== payloaddelete )] })
}

// Something went wrong somewhere.
export const failuredelete = state =>
    state.merge({ fetchingdelete: false, errordelete: true, payloaddelete: null })  

export const cleardetail = state =>
    state.merge({ payloaddetail: INITIAL_STATE.payloaddetail })    

export const reset = () => INITIAL_STATE   


export const requestupload = (state, { dataupload }) =>
    state.merge({ fetchingupload: true, dataupload, payloadupload: null })

// successful api lookup
export const successupload = (state, action) => {
    const { payloadupload } = action
    return state.merge({ fetchingupload: false, errorupload: null, payloadupload })
}

// Something went wrong somewhere.
export const failureupload = state =>
    state.merge({ fetchingupload: false, errorupload: true, payloadupload: null })


    
//ONLOAD
export const requestonload = (state, { dataonload }) =>
    state.merge({ fetchingonload: true, dataonload, payloadonload: null })

// successful api lookup
export const successonload = (state, action) => {
    const { payloadonload } = action
    return state.merge({ fetchingonload: false, erroronload: null, payloadonload })
}

// Something went wrong somewhere.
export const failureonload = state =>
    state.merge({ fetchingonload: false, erroronload: true, payloadonload: null })   

//APPROVE REJECT
export const requestar = (state, { dataar }) =>
    state.merge({ fetchingar: true, dataar, payloadar: null })

// successful api lookup
export const successar = (state, action) => {
    const { payloadar } = action
    return state.merge({ fetchingar: false, errorar: null, payloadar/*, payload:[...state.payload, payloadar]*/ })
}

// Something went wrong somewhere.
export const failurear = state =>
    state.merge({ fetchingar: false, errorar: true, payloadar: null })


export const requestprint = (state, { data }) =>
    state.merge({ fetchingprint: true, data })

export const successprint = (state, action) => {
    const { payloadprint, pagination } = action
    return state.merge({ fetchingprint: false, error: null, payloadprint, pagination: (pagination) ? pagination : state.pagination })
}
// Something went wrong somewhere.
export const failureprint = state =>
    state.merge({ fetchingprint: false, error: true })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    // tambahan
    [Types.FKR_BARANG_NOTICE_BAP_REQUEST]: request,
    [Types.FKR_BARANG_NOTICE_BAP_SUCCESS]: success,
    [Types.FKR_BARANG_NOTICE_BAP_FAILURE]: failure,

    [Types.FKR_BARANG_REQUEST]: request,
    [Types.FKR_BARANG_SUCCESS]: success,
    [Types.FKR_BARANG_FAILURE]: failure,

    [Types.FKR_BARANG_DETAIL_REQUEST]: requestdetail,
    [Types.FKR_BARANG_DETAIL_SUCCESS]: successdetail,
    [Types.FKR_BARANG_DETAIL_FAILURE]: failuredetail,
    [Types.FKR_BARANG_SET_DETAIL]: setdetail,

    [Types.FKR_BARANG_ADD_REQUEST]: requestadd,
    [Types.FKR_BARANG_ADD_SUCCESS]: successadd,
    [Types.FKR_BARANG_ADD_FAILURE]: failureadd,

    [Types.FKR_BARANG_EDIT_REQUEST]: requestedit,
    [Types.FKR_BARANG_EDIT_SUCCESS]: successedit,
    [Types.FKR_BARANG_EDIT_FAILURE]: failureedit,

    [Types.FKR_BARANG_DELETE_REQUEST]: requestdelete,
    [Types.FKR_BARANG_DELETE_SUCCESS]: successdelete,
    [Types.FKR_BARANG_DELETE_FAILURE]: failuredelete,

    [Types.FKR_BARANG_CLEAR_DETAIL]: cleardetail,
    [Types.FKR_BARANG_RESET]: reset,

    [Types.FKR_BARANG_UPLOAD_REQUEST]: requestupload,
    [Types.FKR_BARANG_UPLOAD_SUCCESS]: successupload,
    [Types.FKR_BARANG_UPLOAD_FAILURE]: failureupload,

    [Types.FKR_BARANG_APPROVE_REJECT_REQUEST]: requestar,
    [Types.FKR_BARANG_APPROVE_REJECT_SUCCESS]: successar,
    [Types.FKR_BARANG_APPROVE_REJECT_FAILURE]: failurear,

    [Types.FKR_BARANG_ON_LOAD_REQUEST]: requestonload,
    [Types.FKR_BARANG_ON_LOAD_SUCCESS]: successonload,
    [Types.FKR_BARANG_ON_LOAD_FAILURE]: failureonload,
    

    [Types.FKR_BARANG_PRINT_REQUEST]: requestprint,
    [Types.FKR_BARANG_PRINT_SUCCESS]: successprint,
    [Types.FKR_BARANG_PRINT_FAILURE]: failureprint,


})
