import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'antd';
import './styles.scss';

export default function ModalView(props) {

    return (
        <Modal
            title={props.title}
            visible={props.visible}
            onOk={props.confirm}
            okText={props.confirmTxt}
            onCancel={props.cancel}
            cancelText={props.cancelTxt}
            width={props.width}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            footer={props.footer}
        >
            <div className="modal-view-tracking-order">
                {props.content}
            </div>
        </Modal>

    )
}
