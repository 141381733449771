import React from "react";
import PropTypes from "prop-types";
import {Card} from "antd";
import './card-children.scss'

const CardBox = ({heading, children, styleName, childrenStyle, style, styleCard, bodyStyle}) => {

  return (
    <Card className={`gx-card ${styleName}`} title={heading || null} headStyle={{borderBottom: '1px solid #e8e8e8'}} bodyStyle={bodyStyle}>
      {/*{heading &&*/}
      {/*<div className="gx-card-head" style={styleCard}>*/}
      {/*  <h3 className="gx-card-title">{heading}</h3>*/}
      {/*</div>}*/}
      {/* <div className={`gx-card-body ${childrenStyle}`}>
        {children}
      </div> */}
      <div className={`${childrenStyle}`} style={style}>
        {children}
      </div>
    </Card>
  )
};

export default CardBox;

CardBox.propTypes = {
  children: PropTypes.node.isRequired
};

CardBox.defaultProps = {
  styleName: '',
  childrenStyle: ''
};
