import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    budgetingRequest: ['data'],
    budgetingSuccess: ['payload', 'pagination'],
    budgetingFailure: null,

    budgetingCreateRequest: ['data', 'util'],
    budgetingCreateSuccess: null,
    budgetingCreateFailure: null,

    budgetingCreatePemindahanBudgetRequest: ['data', 'util'],
    budgetingCreatePemindahanBudgetSuccess: null,
    budgetingCreatePemindahanBudgetFailure: null,
})

export const BudgetingTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: null,
    fetching: null,
    payload: [],
    error: null,

    pagination: null
})

/* ------------- Selectors ------------- */

export const BudgetingSelectors = {
    getData: state => state.budgeting.data,
    getPayload: state => state.budgeting.payload,
}

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
    state.merge({ fetching: true, data })

// successful api lookup
export const success = (state, action) => {
    const { payload, pagination } = action
    return state.merge({ fetching: false, error: null, payload, pagination: (pagination) ? pagination : state.pagination })
}

// Something went wrong somewhere.
export const failure = state =>
    state.merge({ fetching: false, error: true })

// request the data from an api
export const requestCreate = (state, { data }) =>
  state.merge({ fetching: true, data })

// successful api lookup
export const successCreate = (state) => {
  return state.merge({ fetching: false, error: null })
}

// Something went wrong somewhere.
export const failureCreate = state =>
  state.merge({ fetching: false, error: true })

// request the data from an api
export const requestCreatePemindahanBudget = (state, { data }) =>
  state.merge({ fetching: true, data })

// successful api lookup
export const successCreatePemindahanBudget = (state) => {
  return state.merge({ fetching: false, error: null })
}

// Something went wrong somewhere.
export const failureCreatePemindahanBudget = state =>
  state.merge({ fetching: false, error: true })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.BUDGETING_REQUEST]: request,
    [Types.BUDGETING_SUCCESS]: success,
    [Types.BUDGETING_FAILURE]: failure,

    [Types.BUDGETING_CREATE_REQUEST]: requestCreate,
    [Types.BUDGETING_CREATE_SUCCESS]: successCreate,
    [Types.BUDGETING_CREATE_FAILURE]: failureCreate,

    [Types.BUDGETING_CREATE_PEMINDAHAN_BUDGET_REQUEST]: requestCreatePemindahanBudget,
    [Types.BUDGETING_CREATE_PEMINDAHAN_BUDGET_SUCCESS]: successCreatePemindahanBudget,
    [Types.BUDGETING_CREATE_PEMINDAHAN_BUDGET_FAILURE]: failureCreatePemindahanBudget,
})
