/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This menu uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, select } from 'redux-saga/effects'
import MenuActions, { MenuSelectors } from '../appRedux/reducers/MenuRedux'
import { notification } from 'antd';
import _ from 'lodash' 
export function * menuRequest (api, action) {
    const { data, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.menuRequest, data)

    // success?
    if (response.ok) {
        var dataresult = response.data.results

        yield put(MenuActions.menuSuccess(dataresult, response.data.meta))
        if (cb) cb(true, response.data.results)
    } else {
        yield put(MenuActions.menuFailure())
        if (cb) cb(false)
    }
}

export function * menuDetailRequest (api, action) {
    const { datadetail, id } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.menuRequest, datadetail, id) 

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        console.log('menuDetail response.data', response.data.result)
        yield put(MenuActions.menuDetailSuccess(response.data.result))
    } else {
        yield put(MenuActions.menuDetailFailure())
    }
}

export function * menuAddRequest (api, action) {
    const { dataadd, cb } = action
    // get current data from Store
    
    // make the call to the api
    const response = yield call(api.menuAddRequest, dataadd) 

    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Data Menu Added',
            placement: 'topRight',
        }); 
 
        yield put(MenuActions.menuAddSuccess(response.data.result))  
        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when creating Data Menu, Please try again',
            placement: 'topRight',
        });
        yield put(MenuActions.menuAddFailure())
        if (cb) cb(false)
    }
}

export function * menuEditRequest (api, action) {
    const { dataedit, cb } = action
    // get current data from Store
    // make the call to the api
    const response = yield call(api.menuEditRequest, dataedit) 
  
    // success?
    if (response.ok) {
        let currentData = _.cloneDeep(yield select(MenuSelectors.getPayload))
        currentData = _.keyBy(currentData, 'm_menu_id')

        Object.assign(currentData[dataedit.id], dataedit);

        notification.success({
            message: 'Success',
            description: 'Data menu Modified',
            placement: 'topRight',
        });

        yield put(MenuActions.menuEditSuccess(response.data))
        yield put(MenuActions.menuSuccess(_.values(currentData)))

        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when Modifying Data Menu, Please try again',
            placement: 'topRight',
        });
        yield put(MenuActions.menuEditFailure())
        if (cb) cb(false)
    }
}

export function * menuDeleteRequest (api, action) {
    const { datadelete, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.menuDeleteRequest, datadelete) 

    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Data Menu Deleted',
            placement: 'topRight',
        });

        yield put(MenuActions.menuDeleteSuccess(datadelete))
        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when Deleting Data Menu, Please try again',
            placement: 'topRight',
        });
        yield put(MenuActions.menuDeleteFailure())
        if (cb) cb(false)
    }
}