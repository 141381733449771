import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Popover, Modal, Form, Button, Row, Col, Input, Typography, notification, Spin } from "antd";
import { userSignOut } from "../../appRedux/actions/Auth";
import ModalView from "../../components/Modals/ModalView";
import AvatarIconExample from "../../assets/images/placeholder.jpg"
import { connect } from 'react-redux'
import Api from "../../Services/Api"
import {URL} from "../../util/URL"
import './styles.scss'

import {
  SettingOutlined
} from '@ant-design/icons';
import { configConsumerProps } from "antd/lib/config-provider";

const FormItem = Form.Item;

const { confirm } = Modal;

const UserProfile = (props) => {

  const [openViewAccount, setOpenViewAccount] = useState(false)
  const [changepassword, setchangepassword] = useState(false)
  const [imgPreview, setImgPreview] = useState(AvatarIconExample);
  const [img, setImg] = useState('');
  const [isImgValid, setIsImgValid] = useState('');
  const [imgErrorMsg, setImgErrorMsg] = useState('');
  const [dataprofile, setdataprofile] = useState({});
  const [photoprofile, setphotoprofile] = useState(null);
  const [loadingchangepassword, setloadingchangepassword] = useState(false);
  const [validation, setValidation] = useState([{ required: true, message: 'Please input your Password!' }])
  const [validationMatch, setValidationMatch] = useState(false)

  const nullAlert = [{ required: true, message: 'Please input your Password!' }]

  const dispatch = useDispatch();
  const m_user_id = useSelector(({ auth }) => {
    if (auth.authUser) {
      return auth.authUser.m_user_id;
    }
    return '';
  });

  const signOut = () => {
    confirm({
      title: 'Are you sure to Logout from this app?',
      onOk() {
        dispatch(userSignOut({ m_user_id }))
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => setOpenViewAccount(true)}>My Account</li>
      <li onClick={() => signOut()}>Keluar</li>
    </ul>
  );

  const usernama = useSelector(({ auth }) => {
    if (auth.authUser) {
      return auth.authUser.nama;
    }
    return '';
  });

  const changePassword = () => {
    setchangepassword(!changepassword)
  }

  const imgRef = useRef(null);

  const handleInputPhoto = () => {
    imgRef.current.click()
  }
  const handleImage = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0],
      pattern = /image-*/;

    if (!file.type.match(pattern)) {
      setIsImgValid(true)
      setImgErrorMsg('Format File tidak sesuai')
      return;
    }

    reader.onloadend = () => {
      setIsImgValid(false);
      setImgErrorMsg('');
      setImg(file);
      setImgPreview(reader.result)
    };

    reader.readAsDataURL(file);
  }

  const getDetailUser = () => {
    const data = JSON.parse(localStorage.getItem("user"))
    const photo = JSON.parse(localStorage.getItem("user"))
    // props.userDetailRequest(data.m_user_id)
    setdataprofile(data.profile)
    setphotoprofile(photo.image)
  }

  const { getFieldDecorator } = props.form;


  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (values.passwordnew !== values.passwordnew2) {
        setValidationMatch(true)
      }
      else {
        var data = {
          username: dataprofile.username ? dataprofile.username : dataprofile.kode,
          old_password: values.passwordold,
          newpassword: values.passwordnew,
          renewpassword: values.passwordnew2
        }
        Api.create(URL).userChangePasswordRequest(data).then(response => {
          if (response.ok) {
            notification.open({
              message: <Typography style={{fontSize:12,marginTop:5}}>Password Berhasil diubah</Typography>,
              placement: "topRight",
              style:{padding:0,paddingLeft:10}
            });
            setOpenViewAccount(false)
          }
          else{
            notification.open({
              message:  <Typography style={{fontSize:12,marginTop:5}}>Password Gagal diubah Cek Kembali Isian Anda</Typography>,
              placement: "topRight",
              style:{padding:0,paddingLeft:10}
            });
          }
        })
      }
    });



  };

  useEffect(() => {
    getDetailUser()
  }, [])

  return (
    <>

      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottom" content={userMenuOptions} trigger="click">
          <Avatar src='https://via.placeholder.com/150x150'
            className="gx-size-40 gx-pointer gx-mr-3" alt="" />
          <span className="gx-avatar-name" style={{ letterSpacing: 1, fontWeight: "lighter", lineHeight: 3.5 }}>{usernama}<i
            className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
        </Popover>
      </div>
      <ModalView
        width={600}
        visible={openViewAccount}
        title={"Setting My Account"}
        cancel={() => setOpenViewAccount(false)}
        content={
          <>

            <Row gutter={[8, 16]}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                ref={imgRef}
                multiple
                type="file"
                onChange={e => handleImage(e)}
              />
              <Col span={10}>
                <img src={photoprofile ? photoprofile : imgPreview} width={"100%"} height={250} />
                <Col span={24}><Button onClick={handleInputPhoto} style={{ width: "100%", margin: 0, backgroundColor: "#004925", color: "#fff", borderColor: "#004925", fontWeight: "lighter" }}>Ganti Foto Profil</Button></Col>
              </Col>
              <Col span={14}>
                <Row gutter={[4, 8]}>
                  <Col span={24}><Input placeholder="Nama" value={dataprofile.nama} disabled /></Col>
                  <Col span={24}><Input placeholder="Email" value={dataprofile.email} disabled /></Col>
                  <Col span={24}><Input placeholder="Username" value={dataprofile.kode} disabled /></Col>
                  <Col span={24}><Button onClick={() => changePassword()} style={{ width: "100%", margin: 0, backgroundColor: changepassword ? "#004925" : "transparent", color: changepassword ? "#fff" : "#000", borderColor: "#004925", fontWeight: "lighter" }}>Ganti Password ?</Button></Col>
                  <Form onSubmit={handleSubmit} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    {
                      changepassword ?
                        <Row>
                          <Col span={24} style={{ margin: 0 }}>
                            <FormItem style={{ marginBottom: 0 }}>
                              {getFieldDecorator('passwordold', {
                                initialValue: "",
                                rules: nullAlert,
                              })(
                                <Input.Password type="password" placeholder="Masukkan Password Lama" />
                              )}
                            </FormItem>
                          </Col>
                          <Col span={24} style={{ margin: 0 }}>
                            <FormItem style={{ marginBottom: 0 }}>
                              {getFieldDecorator('passwordnew', {
                                initialValue: "",
                                rules: nullAlert,
                              })(
                                <Input.Password type="password" onChange={() => setValidationMatch(false)} placeholder="Masukkan Password Baru" />
                              )}
                              {validationMatch ? <Typography style={{ margin: 0 }}>Those password didn't match. Try again</Typography> : null}
                            </FormItem>
                          </Col>
                          <Col span={24} style={{ margin: 0 }}>
                            <FormItem style={{ marginBottom: 0 }}>
                              {getFieldDecorator('passwordnew2', {
                                initialValue: "",
                                rules: nullAlert,
                              })(
                                <Input.Password type="password" onChange={() => setValidationMatch(false)} placeholder="Ulangi Password Baru" />
                              )}
                              {validationMatch ? <Typography style={{ margin: 0 }}>Those password didn't match. Try again</Typography> : null}
                            </FormItem>
                          </Col>
                        </Row> : null
                    }

                    <Row style={{ height: 35, padding: "5px 17px" }}>
                      <div className="btn-create">
                        <Button onClick={() => setOpenViewAccount(false)} style={{ marginRight: 7 }}>Cancel</Button>
                      </div>
                      {

                        changepassword ?
                          <div className="btn-create">
                            <Button htmlType="submit" style={{ backgroundColor: "#004925", color: "#fff", borderColor: "#004925", fontWeight: "lighter" }}>
                              Save Changes
                            </Button>
                          </div> : null
                      }

                    </Row>
                  </Form>
                </Row>
              </Col>
            </Row>
          </>
        }
      />
    </>
  )
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // userDetailRequest: (data) => dispatch(UserAction.userDetailRequest(data))
  }
}


const WrappedNormalLoginForm = Form.create()(UserProfile);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedNormalLoginForm);
