/* eslint-disable no-undef */
/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This fkrBarang uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, select } from 'redux-saga/effects'
import FkrBarangActions, { FkrBarangSelectors } from '../appRedux/reducers/FkrBarangRedux'
import { notification } from 'antd';
import _ from 'lodash'
import GetFileExt from '../util/GetFileExt';

export function * fkrBarangRequest (api, action) {
    const { data, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.fkrBarangRequest, data)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        console.log('fkrBarang response.data', response.data)
        yield put(FkrBarangActions.fkrBarangSuccess(response.data.results, response.data.meta))
        if (cb) cb(true, response.data.results)
    } else {
        yield put(FkrBarangActions.fkrBarangFailure())
        if (cb) cb(false)
    }
}

export function * fkrBarangDetailRequest (api, action) {
    const { datadetail, id } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.fkrBarangRequest, datadetail, id) 

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        console.log('fkrBarangDetail response.data', response.data.result)
        yield put(FkrBarangActions.fkrBarangDetailSuccess(response.data.result))
    } else {
        yield put(FkrBarangActions.fkrBarangDetailFailure())
    }
}


export function * fkrBarangOnLoadRequest (api, action) {
    const { dataonload } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.fkrBarangOnLoadRequest, dataonload) 

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        console.log('fkrBarangOnLoad response.data', response.data)
        yield put(FkrBarangActions.fkrBarangOnLoadSuccess(response.data))
    } else {
        yield put(FkrBarangActions.fkrBarangOnLoadFailure())
    }
}

export function * fkrBarangUploadRequest (api, action) {
    const { dataupload, cb } = action
    
    // eslint-disable-next-line no-undef
    const formdata = new FormData() 
    for (const key in dataupload) {
        console.log(key,'-', dataupload[key])
        formdata.append(key, dataupload[key])  
    }  

    // console.log('formdata', formdata)
    const response = yield call(api.fkrBarangUploadRequest, formdata) 

    // success?
    if (response.ok) {
        // You might need to change the response here - do this with a 'transform',
        // located in ../Transforms/. Otherwise, just pass the data back from the api.
        console.log('fkrBarangUpload response.data', response.data.result)
        yield put(FkrBarangActions.fkrBarangUploadSuccess(response.data.result))
        yield put(FkrBarangActions.fkrBarangSetDetail('lines', response.data.result.lines))
        yield put(FkrBarangActions.fkrBarangSetDetail('fkr_id', response.data.result.fkr_id))
        yield put(FkrBarangActions.fkrBarangSetDetail('headers', response.data.result.headers))
        if (cb) cb(true, response.data.result)
    } else {
        yield put(FkrBarangActions.fkrBarangUploadFailure())
        if (response.data && response.data.meta && response.data.meta.message) {
            notification.error({
                message: 'Failed',
                description: response.data.meta.message,
                placement: 'topRight',
            });
          
        }
        if (cb) cb(false)
    }
} 


export function * fkrBarangAddRequest (api, action) {
    const { dataadd, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api 

    let hasUpload = false
 

    const formdata = new FormData() 
    for (const key in dataadd) {
        // console.log('sss', dataar[key])
        if (key.includes('file_')) {
            hasUpload = true
            formdata.append(key, dataadd[key], `${key}${GetFileExt(dataadd[key].name)}`)   
        } else {
            formdata.append(key, dataadd[key])   
        }
    } 

    let response = null
    if (hasUpload) {
        response = yield call(api.fkrBarangAddRequestWithBast, formdata) 
    } else {
        response = yield call(api.fkrBarangAddRequest, dataadd) 
    }

    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Data FKR Added',
            placement: 'topRight',
        });

        console.log('fkrBarangAdd response.data', response.data)
        yield put(FkrBarangActions.fkrBarangAddSuccess(response.data))
        if (cb) cb(true)
    } else {
        if (response.data && response.data.meta && response.data.meta.message) {
            notification.error({
                message: 'Failed',
                description: response.data.meta.message,
                placement: 'topRight',
            });
          
        }
        yield put(FkrBarangActions.fkrBarangAddFailure())
        if (cb) cb(false)
    }
}

export function * fkrBarangApproveRejectRequest (api, action) {
    const { act, dataar, cb } = action

    console.log(api.fkrBarangApproveRejectRequest, act, dataar);

    const response = yield call(api.fkrBarangApproveRejectRequest, act, dataar /*param*/) 

    // success?
    if (response.ok) {  
        yield put(FkrBarangActions.fkrBarangApproveRejectSuccess(response.data)) 
        if (cb) cb(true, response.data.message)
    } else {
        //
        if (response.data && response.data.meta) {
            notification.error({
                message: 'Failed',
                description: response.data.meta,
                placement: 'topRight',
            }); 
            //    
        } else {
            notification.error({
                message: 'Failed',
                description: 'Error when Modifying Data Faktur, Please try again',
                placement: 'topRight',
            })
        }
        yield put(FkrBarangActions.fkrBarangApproveRejectFailure())
        if (cb) cb(false)
    }
} 

export function * fkrBarangPrintRequest (api, action) {
    const { data, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.fkrBarangPrintRequest, data)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        console.log('fkrBarang response.data', response.data)
        yield put(FkrBarangActions.fkrBarangPrintSuccess(response.data.results, response.data.meta))
        if (cb) cb(true, response.data.results)
    } else {
        yield put(FkrBarangActions.fkrBarangPrintFailure())
        if (cb) cb(false)
    }
}

/*
export function * fkrBarangEditRequest (api, action) {
  const { dataedit, cb } = action
  // get current data from Store
  // make the call to the api
  const response = yield call(api.fkrBarangEditRequest, dataedit) 
  
  // success?
  if (response.ok) {
    let currentData = _.cloneDeep(yield select(FkrBarangSelectors.getPayload))
    currentData = _.keyBy(currentData, 'm_fkrBarang_id')

    Object.assign(currentData[dataedit.m_fkrBarang_id], dataedit);



    notification.success({
      message: `Success`,
      description: 'Data FkrBarang Modified',
      placement: 'topRight',
    });

    console.log('fkrBarangEdit response.data', response.data)
    yield put(FkrBarangActions.fkrBarangEditSuccess(response.data))
    yield put(FkrBarangActions.fkrBarangSuccess(_.values(currentData)))

    if (cb) cb(true)
  } else {
    notification.error({
      message: `Failed`,
      description: 'Error when Modifying Data FkrBarang, Please try again',
      placement: 'topRight',
    });
    yield put(FkrBarangActions.fkrBarangEditFailure())
    if (cb) cb(false)
  }
}

export function * fkrBarangDeleteRequest (api, action) {
  const { datadelete, cb } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.fkrBarangDeleteRequest, datadelete) 

  // success?
  if (response.ok) {
    notification.success({
      message: `Success`,
      description: 'Data FkrBarang Deleted',
      placement: 'topRight',
    });

    console.log('fkrBarangDelete response.data', response.data)
    yield put(FkrBarangActions.fkrBarangDeleteSuccess(datadelete.m_fkrBarang_id))
    if (cb) cb(true)
  } else {
    notification.error({
      message: `Failed`,
      description: 'Error when Deleting Data FkrBarang, Please try again',
      placement: 'topRight',
    });
    yield put(FkrBarangActions.fkrBarangDeleteFailure())
    if (cb) cb(false)
  }
}*/