/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This order uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put } from 'redux-saga/effects'
import OrderActions from '../appRedux/reducers/OrderRedux'
import { notification } from 'antd'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * orderRequest (api, action) {
    const { data } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.orderRequest, data)
    console.log('order res', response)
    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        console.log('order response.data', response.data)
        const result = response.data.results ? response.data.results : [response.data.result]
        yield put(OrderActions.orderSuccess(result))
    } else {
        yield put(OrderActions.orderFailure())
    }
}

export function * orderToSapDpdRequest (api, action) {
    const { dataordertosapdpd, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.orderToSapDpdRequest, dataordertosapdpd)
    console.log('ordertosapdpd', response)

    // success?
    if (response.ok) {      
        //disini kasih pesan
        notification.success({
            message: 'Success',
            description: 'Data Has Been Approve',
            placement: 'topRight',
        });
        yield put(OrderActions.orderToSapDpdSuccess(response.data)) 
        if (cb) cb(true)
    } else {
        let err = (response.data && response.data.meta && response.data.meta.message) ? response.data.meta.message : 'Error when Starting Process Order, Please try again'
        notification.error({
            message: 'Failed',
            description: err,
            placement: 'topRight',
        });
        yield put(OrderActions.orderToSapDpdFailure())
        if (cb) cb(false)
    }
}