/* eslint-disable no-undef */
/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This approvalBudget uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { notification } from 'antd'
import { call, put } from 'redux-saga/effects'
import ApprovalBudgetActions from '../appRedux/reducers/ApprovalBudgetRedux'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * getApprovalBudget (api, action) {
  const { data } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.getApprovalBudget, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('approvalBudget response.data', response.data)
    yield put(ApprovalBudgetActions.approvalBudgetSuccess(response.data.results, response.data.meta))
  } else {
    yield put(ApprovalBudgetActions.approvalBudgetFailure())
  }
}

// export function * approvalBudgetDetailRequest (api, action) {
//   const { id, datadetail, cb } = action
//
//   const response = yield call(api.approvalBudgetRequest, datadetail, id)
//
//   // success?
//   if (response.ok) {
//     console.log('test propsal detail', response.data.result)
//     if (cb) cb(response.data.result)
//     yield put(ApprovalBudgetActions.approvalBudgetDetailSuccess(response.data.result))
//   } else {
//     if (cb) cb(null)
//     yield put(ApprovalBudgetActions.approvalBudgetDetailFailure())
//   }
// }


