/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This budgetActivity uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put } from 'redux-saga/effects'
import ActivityActions from '../../appRedux/reducers/MasterData/ActivityRedux'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * getMasterActivity (api, action) {
  const { data } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.getMasterActivity, data)

  // success?
  if (response.ok) {
    // console.log('as', JSON.stringify(response))
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetActivity response.data', response.data)
    yield put(ActivityActions.masterActivitySuccess(response.data.results, response.data.meta))
  } else {
    yield put(ActivityActions.masterActivityFailure())
  }
}

export function * getDetailMasterActivity (api, action) {
  const { data, id } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.getMasterActivity, data, id)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetActivity response.data', response.data)
    yield put(ActivityActions.masterActivityDetailSuccess(response.data.result))
  } else {
    yield put(ActivityActions.masterActivityDetailFailure())
  }
}

export function * createMasterActivity (api, action) {
  const { data, util: { info, callback } } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.postMasterActivity, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetActivity response.data', response.data)
    info.success(response.data.message)
    callback()
    yield put(ActivityActions.masterActivityCreateSuccess())
  } else {
    info.error(response.data.message)
    yield put(ActivityActions.masterActivityCreateFailure())
  }
}

export function * editMasterActivity (api, action) {
  const { data, util: { info, callback } } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.putMasterActivity, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetActivity response.data', response.data)
    info.success(response.data.message)
    callback()
    yield put(ActivityActions.masterActivityEditSuccess())
  } else {
    info.error(response.data.message)
    yield put(ActivityActions.masterActivityEditFailure())
  }
}

export function * deleteMasterActivity (api, action) {
  const { data, id, info } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.deleteMasterActivity, data, id)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetActivity response.data', response.data)
    info.success(response.data.message)
    yield put(ActivityActions.masterActivityDeleteSuccess())
    yield put(ActivityActions.masterActivityRequest())
  } else {
    info.error(response.data.message)
    yield put(ActivityActions.masterActivityDeleteFailure())
  }
}
