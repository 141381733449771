/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This transporter uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put } from 'redux-saga/effects'
import TransporterActions from '../appRedux/reducers/TransporterRedux'
import _ from 'lodash'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * transporterRequest (api, action) {
    const { data, getnormal } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.transporterRequest, data)
    
    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log(response.data.results,"here we call...................");
        if (getnormal) {
            yield put(TransporterActions.transporterSuccess(response.data.results))
        } else {
            var grouped = _.mapValues(_.groupBy(response.data.results, 'tier'),
                clist => clist.map(data => _.omit(data, 'tier'))) 
            yield put(TransporterActions.transporterSuccess(grouped))
        }
    } else {
        yield put(TransporterActions.transporterFailure())
    }
}
