/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This budgetBrand uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put } from 'redux-saga/effects'
import ActivityActions from '../appRedux/reducers/ActivityRedux'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * getActivity (api, action) {
    const { data, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    console.log('data', data)
    const response = yield call(api.getActivity, data)

    // success?
    if (response.ok) {
        console.log('response', JSON.stringify(response))
        // You might need to change the response here - do this with a 'transform',
        // located in ../Transforms/. Otherwise, just pass the data back from the api.
        // console.log('budgetBrand response.data', response.data)
        if (cb) cb(true)
        yield put(ActivityActions.activitySuccess(response.data.results))
    } else {
        if (cb) cb(false)
        yield put(ActivityActions.activityFailure())
    }

}
