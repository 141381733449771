import { call, put } from 'redux-saga/effects'
import { notification } from 'antd'
import {ApproveCMO} from '../appRedux/reducers/CmoRedux'

export function * cmorequest(api, action){
    const { data } = action
}


export function * DataCmoDPDRequest(api, action){
    const { dataordertosapdpd, cb } = action
    // const response = yield call(api.ApproveCMO, data)
    // console.log('order res', response)
    console.log(dataordertosapdpd," sagas jalan disini oi......");
}

