import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import _ from 'lodash'
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    klaimProposalRequest: ['data', 'cb'],
    klaimProposalSuccess: ['payload', 'pagination'],
    klaimProposalFailure: null,

    klaimProposalDetailRequest: ['datadetail', 'id', 'cb', 'cekdivisi'],
    klaimProposalDetailSuccess: ['payloaddetail'],
    klaimProposalDetailFailure: null,
    klaimProposalSetDetail: ['propertykey', 'val'],

    klaimProposalAddRequest: ['dataadd', 'cb'],
    klaimProposalAddSuccess: ['payloadadd'],
    klaimProposalAddFailure: null,

    klaimProposalDeleteRequest: ['datadelete', 'cb'],
    klaimProposalDeleteSuccess: ['payloaddelete'],
    klaimProposalDeleteFailure: null,

    klaimProposalEditRequest: ['dataedit', 'cb'],
    klaimProposalEditSuccess: ['payloadedit'],
    klaimProposalEditFailure: null,

    klaimProposalClearDetail: null,
    klaimProposalReset: null,

    klaimProposalArsRequest : ['act', 'dataars', 'cb'],
    klaimProposalArsSuccess: ['payloadars'],
    klaimProposalArsFailure: null,

    klaimProposalPayRequest: ['datapay', 'cb'],
    klaimProposalPaySuccess: ['payloadpay'],
    klaimProposalPayFailure: null,

    klaimProposalResetChannel: null,

    klaimProposalApproveStatusRequest: ['dataapprovestatus', 'cb'],
    klaimProposalApproveStatusSuccess: ['payloadapprovestatus'],
    klaimProposalApproveStatusFailure: null,
    
})

export const KlaimProposalTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: null,
    fetching: null,
    payload: [],
    error: null,

    datadetail: null,
    fetchingdetail: null,
    payloaddetail: {
        proposal_id:null,
        no_doc:null,
        title:null,
        date_prop:null,
        divisi:null,
        comp:null,
        region:null,
        status:null,
        lines: []
    },
    errordetail: null,

    dataadd: null,
    fetchingadd: null,
    payloadadd: null,
    erroradd: null,

    dataedit: null,
    fetchingedit: null,
    payloadedit: null,
    erroredit: null,

    datadelete: null,
    fetchingdelete: null,
    payloaddelete: null,
    errordelete: null,

    dataars: null,
    fetchingars: null,
    payloadars: null,
    errorars: null,

    datapay: null,
    fetchingpay: null,
    payloadpay: null,
    errorpay: null,

    dataapprovestatus: null,
    fetchingapprovestatus: null,
    payloadapprovestatus: null,
    errorapprovestatus: null,
})

/* ------------- Selectors ------------- */

export const KlaimProposalSelectors = {
    getData: state => state.klaimproposal.data,
    getPayloadDetail: state => state.klaimproposal.payloaddetail,
    getDivisi: state => state.klaimproposal.payloaddetail.divisi,
    getPayload: state => state.klaimproposal.payload,
}

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
    state.merge({ fetching: true, data })

// successful api lookup
export const success = (state, action) => {
    const { payload, pagination } = action
    return state.merge({ fetching: false, error: null, payload, pagination: (pagination) ? pagination : state.pagination })
}

// Something went wrong somewhere.
export const failure = state =>
    state.merge({ fetching: false, error: true })


//DETAIL
export const requestdetail = (state, { datadetail }) =>
    state.merge({ fetchingdetail: true, datadetail, /*payloaddetail: INITIAL_STATE.payloaddetail*/ })

// successful api lookup
export const successdetail = (state, action) => {
    const { payloaddetail } = action
    const currentLinesObject = _.keyBy(state.payloaddetail.lines, 'budget_id')
    const incomingLinesObject = _.keyBy(payloaddetail.lines, 'budget_id')
    const mergeLinesObject = {...currentLinesObject, ...incomingLinesObject}

    const tempLines = {...state.payloaddetail, ...payloaddetail, lines: _.values(mergeLinesObject) }
    return state.merge({ fetchingdetail: false, errordetail: null, payloaddetail: tempLines  })
}

// Something went wrong somewhere.
export const failuredetail = state =>
    state.merge({ fetchingdetail: false, errordetail: true, payloaddetail: INITIAL_STATE.payloaddetail })

export const setdetail = (state, action) => {
    const { propertykey, val } = action

    return state.merge({ payloaddetail: {...state.payloaddetail, [propertykey]: val } })
}

//ADD
export const requestadd = (state, { dataadd }) =>
    state.merge({ fetchingadd: true, dataadd, payloadadd: null })

// successful api lookup
export const successadd = (state, action) => {
    const { payloadadd } = action
    return state.merge({ fetchingadd: false, erroradd: null, payloadadd/*, payload:[...state.payload, payloadadd]*/ })
}

// Something went wrong somewhere.
export const failureadd = state =>
    state.merge({ fetchingadd: false, erroradd: true, payloadadd: null })


//EDIT
export const requestedit = (state, { dataedit }) =>
    state.merge({ fetchingedit: true, dataedit, payloadedit: null })

// successful api lookup
export const successedit = (state, action) => {
    const { payloadedit } = action
    return state.merge({ fetchingedit: false, erroredit: null, payloadedit/*, payload:[...state.payload, payloadedit]*/ })
}

// Something went wrong somewhere.
export const failureedit = state =>
    state.merge({ fetchingedit: false, erroredit: true, payloadedit: null })


//DELETE
export const requestdelete = (state, { datadelete }) =>
    state.merge({ fetchingdelete: true, datadelete, payloaddelete: null })

// successful api lookup
export const successdelete = (state, action) => {
    const { payloaddelete } = action
    return state.merge({ fetchingdelete: false, errordelete: null, payloaddelete, payload:[...state.payload.filter( x => x.klaim_id !== payloaddelete )] })
}

// Something went wrong somewhere.
export const failuredelete = state =>
    state.merge({ fetchingdelete: false, errordelete: true, payloaddelete: null })  

export const cleardetail = state =>
    state.merge({ payloaddetail: INITIAL_STATE.payloaddetail })    

export const reset = () => INITIAL_STATE   


export const requestupload = (state, { dataupload }) =>
    state.merge({ fetchingupload: true, dataupload, payloadupload: null })

// successful api lookup
export const successupload = (state, action) => {
    const { payloadupload } = action
    return state.merge({ fetchingupload: false, errorupload: null, payloadupload })
}

// Something went wrong somewhere.
export const failureupload = state =>
    state.merge({ fetchingupload: false, errorupload: true, payloadupload: null })


export const requestars = (state, { dataars }) =>
    state.merge({ fetchingars: true, dataars, payloadars: null })

// successful api lookup
export const successars = (state, action) => {
    const { payloadars } = action
    return state.merge({ fetchingars: false, errorars: null, payloadars/*, payload:[...state.payload, payloadars]*/ })
}

// Something went wrong somewhere.
export const failurears = state =>
    state.merge({ fetchingars: false, errorars: true, payloadars: null })   


export const requestpay = (state, { datapay }) =>
    state.merge({ fetchingpay: true, datapay, payloadpay: null })

// successful api lookup
export const successpay = (state, action) => {
    const { payloadpay } = action
    return state.merge({ fetchingpay: false, errorpay: null, payloadpay/*, payload:[...state.payload, payloadpay]*/ })
}

// Something went wrong somewhere.
export const failurepay = state =>
    state.merge({ fetchingpay: false, errorpay: true, payloadpay: null })   


export const requestapprovestatus = (state, { dataapprovestatus }) =>
    state.merge({ fetchingapprovestatus: true, dataapprovestatus, payloadapprovestatus: null })

// successful api lookup
export const successapprovestatus = (state, action) => {
    const { dataapprovestatus } = action
    return state.merge({ fetchingapprovestatus: false, errorapprovestatus: null, dataapprovestatus/*, payload:[...state.payload, dataapprovestatus]*/ })
}

// Something went wrong somewhere.
export const failureapprovestatus = state =>
    state.merge({ fetchingapprovestatus: false, errorapprovestatus: true, dataapprovestatus: null })   
     
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.KLAIM_PROPOSAL_REQUEST]: request,
    [Types.KLAIM_PROPOSAL_SUCCESS]: success,
    [Types.KLAIM_PROPOSAL_FAILURE]: failure,

    [Types.KLAIM_PROPOSAL_DETAIL_REQUEST]: requestdetail,
    [Types.KLAIM_PROPOSAL_DETAIL_SUCCESS]: successdetail,
    [Types.KLAIM_PROPOSAL_DETAIL_FAILURE]: failuredetail,
    [Types.KLAIM_PROPOSAL_SET_DETAIL]: setdetail,

    [Types.KLAIM_PROPOSAL_ADD_REQUEST]: requestadd,
    [Types.KLAIM_PROPOSAL_ADD_SUCCESS]: successadd,
    [Types.KLAIM_PROPOSAL_ADD_FAILURE]: failureadd,

    [Types.KLAIM_PROPOSAL_EDIT_REQUEST]: requestedit,
    [Types.KLAIM_PROPOSAL_EDIT_SUCCESS]: successedit,
    [Types.KLAIM_PROPOSAL_EDIT_FAILURE]: failureedit,

    [Types.KLAIM_PROPOSAL_DELETE_REQUEST]: requestdelete,
    [Types.KLAIM_PROPOSAL_DELETE_SUCCESS]: successdelete,
    [Types.KLAIM_PROPOSAL_DELETE_FAILURE]: failuredelete,

    [Types.KLAIM_PROPOSAL_CLEAR_DETAIL]: cleardetail,
    [Types.KLAIM_PROPOSAL_RESET]: reset,

    [Types.KLAIM_PROPOSAL_ARS_REQUEST]: requestars,
    [Types.KLAIM_PROPOSAL_ARS_SUCCESS]: successars,
    [Types.KLAIM_PROPOSAL_ARS_FAILURE]: failurears,
    
    [Types.KLAIM_PROPOSAL_PAY_REQUEST]: requestpay,
    [Types.KLAIM_PROPOSAL_PAY_SUCCESS]: successpay,
    [Types.KLAIM_PROPOSAL_PAY_FAILURE]: failurepay,

    [Types.KLAIM_PROPOSAL_APPROVE_STATUS_REQUEST]: requestapprovestatus,
    [Types.KLAIM_PROPOSAL_APPROVE_STATUS_SUCCESS]: successapprovestatus,
    [Types.KLAIM_PROPOSAL_APPROVE_STATUS_FAILURE]: failureapprovestatus,

    [Types.KLAIM_PROPOSAL_RESET_CHANNEL]: cleardetail,
})
