/* eslint-disable no-undef */
/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This faktur uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, select } from 'redux-saga/effects'
import FakturActions, { FakturSelectors } from '../appRedux/reducers/FakturRedux'
import { notification } from 'antd';
import _ from 'lodash'
import GetFileExt from '../util/GetFileExt';
import { URL } from '../util/URL';

export function * fakturRequest (api, action) {
    const { data } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.fakturRequest, data)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        console.log('faktur response.data', response.data)
        yield put(FakturActions.fakturSuccess(response.data.results, response.data.meta))
    } else {
        yield put(FakturActions.fakturFailure())
    }
}

export function * fakturCekBastRequest (api, action) {
    const { datacekbast } = action
    // get current datacekbast from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.fakturCekBastRequest, datacekbast)

    // success?
    if (response.ok) {
        console.log('response.data.data.bast', response.data.data.bast)
        const linkToOpen = [
            `${URL}submitfaktur/cetak/${datacekbast.c_invoice_id}?m_user_id=${datacekbast.m_user_id}`,
            `${URL}submitfaktur/file/${datacekbast.c_invoice_id}/file_dokumen_pengecekan`
        ]
        if (response.data.data.bast === 'N') {            
            window.open(linkToOpen[0], '_blank')             
        } else {
            for (var i = 0; i < linkToOpen.length; i++) {
                window.open(linkToOpen[i], '_blank');
            } 
        }
    }  
    yield put(FakturActions.fakturCekBastEnd(datacekbast.c_invoice_id))
}

export function * fakturDetailRequest (api, action) {
    const { datadetail, id } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.fakturRequest, datadetail, id) 

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        console.log('fakturDetail response.data', response.data.result)
        yield put(FakturActions.fakturDetailSuccess(response.data.result))
    } else {
        yield put(FakturActions.fakturDetailFailure())
    }
}

export function * fakturAddRequest (api, action) {
    const { dataadd, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api

    let dokumentParam = {}
    let dokumentFile = {}

    // for (const key in dataadd) {
    //     if (key.includes('file_')) {
    //         if (!dataadd[key].isremote) {
    //             dokumentFile[key] = dataadd[key]//dokumentFile.push(dataadd[key])
    //         }
    //         dokumentParam[key] = key+GetFileExt(dataadd[key].name) 
    //     } 
    // }

    for (const key in dataadd) {
        if (key.includes('file_') && (dataadd[key])) {
            if (!dataadd[key].isremote) {
                dokumentFile[key] = dataadd[key]//dokumentFile.push(dataadd[key])
            }
            dokumentParam[key] = key+GetFileExt(dataadd[key].name) 
        } else {
            dokumentParam[key] = ''
        }
    }
    for (const key in dataadd) {
        if (!key.includes('file_')) 
            dokumentParam[key] = dataadd[key]
    }
    console.log('param', JSON.stringify(dokumentParam))
    console.log('file', dokumentFile)
    // return //wait
    // eslint-disable-next-line no-undef
    const formdata = new FormData() 
    formdata.append('document', JSON.stringify(dokumentParam))
    for (const key in dokumentFile) {
        formdata.append('file', dokumentFile[key], `${key}${GetFileExt(dokumentFile[key].name)}`) 
    }  

    const response = yield call(api.fakturAddRequest, formdata) 

    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Data Faktur Added',
            placement: 'topRight',
        });

        console.log('fakturAdd response.data', response.data)
        // const mergeResult = {...response.data.data, lines: dataadd.lines} // ini bila mau merge

        yield put(FakturActions.fakturAddSuccess(response.data.data))
        if (cb) cb(true)
    } else {
        console.log(response.data)
        notification.error({
            message: 'Failed',
            description: (response.data ? `${response.data.meta.message}`: ''),
            placement: 'topRight',
        });
        yield put(FakturActions.fakturAddFailure())
        if (cb) cb(false)
    }
}

export function * fakturEditRequest (api, action) {
    const { dataedit, cb } = action
  
    let dokumentParam = {}
    let dokumentFile = {}

    // for (const key in dataedit) {
    //     if (key.includes('file_')) {
    //         if (!dataedit[key].isremote) {
    //             dokumentFile[key] = dataedit[key]//dokumentFile.push(dataedit[key])
    //         }
    //         dokumentParam[key] = key+GetFileExt(dataedit[key].name) 
    //     } 
    // }

    for (const key in dataedit) {
        if (key.includes('file_') && (dataedit[key])) {
            if (!dataedit[key].isremote) {
                dokumentFile[key] = dataedit[key] 
            }
            dokumentParam[key] = key+GetFileExt(dataedit[key].name) 
        } else {
            dokumentParam[key] = ''
        }
    }
    
    for (const key in dataedit) {
        if (!key.includes('file_')) 
            dokumentParam[key] = dataedit[key]
    }
    console.log('param edit', JSON.stringify(dokumentParam))
    console.log('file edit', dokumentFile)
    // return //wait
    // eslint-disable-next-line no-undef
    const formdata = new FormData() 
    formdata.append('document', JSON.stringify(dokumentParam))
    for (const key in dokumentFile) {
        formdata.append('file', dokumentFile[key], `${key}${GetFileExt(dokumentFile[key].name)}`) 
    }  

    const response = yield call(api.fakturEditRequest, formdata) 
  
    // success?
    if (response.ok) {
        let currentData = _.cloneDeep(yield select(FakturSelectors.getPayload))
        currentData = _.keyBy(currentData, 'c_invoice_id')

        Object.assign(currentData[dataedit.c_invoice_id], dokumentParam);



        notification.success({
            message: 'Success',
            description: 'Data Faktur Modified',
            placement: 'topRight',
        });

        console.log('fakturEdit response.data', response.data)
        yield put(FakturActions.fakturEditSuccess(response.data))
        yield put(FakturActions.fakturSuccess(_.values(currentData)))

        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when Modifying Data Faktur, Please try again',
            placement: 'topRight',
        });
        yield put(FakturActions.fakturEditFailure())
        if (cb) cb(false)
    }
}

export function * fakturDeleteRequest (api, action) {
    const { datadelete, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    console.log('datadelete', JSON.stringify(datadelete))
    const response = yield call(api.fakturDeleteRequest, datadelete) 
    // console.log('response', response)
    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Data Faktur Berhasil Dihapus',
            placement: 'topRight',
        });

        console.log('fakturDelete response.data', response.data)
        yield put(FakturActions.fakturDeleteSuccess(datadelete.id))
        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when Deleting Data Faktur, Please try again',
            placement: 'topRight',
        });
        yield put(FakturActions.fakturDeleteFailure())
        if (cb) cb(false)
    }
}

export function * fakturApproveRejectRequest (api, action) {
    const { act, dataar, cb } = action

    let param = undefined

    if (act === 'rjl') {
        param = dataar
    } else {
        //ini approve
        // eslint-disable-next-line no-undef
        const formdata = new FormData() 
        for (const key in dataar) {
            console.log('sss', dataar[key])
            formdata.append(key, dataar[key])   
        }
        param = formdata
    }

    const response = yield call(api.fakturApproveRejectRequest, act, param) 
    // success?
    if (response.ok) {
        console.log('approve reject response ok', response.data)
        yield put(FakturActions.fakturApproveRejectSuccess(response.data))

        let currentData = _.cloneDeep(yield select(FakturSelectors.getPayload))
        currentData = _.keyBy(currentData, 'c_invoice_id')

        Object.assign(currentData[dataar.c_invoice_id], _.keyBy([response.data.result], 'c_invoice_id')[dataar.c_invoice_id]);

        console.log('new currentData', JSON.stringify(currentData))
        yield put(FakturActions.fakturSuccess(_.values(currentData)))

        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when Modifying Data Faktur, Please try again',
            placement: 'topRight',
        });
        yield put(FakturActions.fakturApproveRejectFailure())
        if (cb) cb(false)
    }
}