import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  masterWorkflowRequest: ['data'],
  masterWorkflowSuccess: ['payload', 'pagination'],
  masterWorkflowFailure: null,

  masterWorkflowDetailRequest: ['data', 'id'],
  masterWorkflowDetailSuccess: ['payload'],
  masterWorkflowDetailFailure: null,

  masterWorkflowCreateRequest: ['data', 'util'],
  masterWorkflowCreateSuccess: null,
  masterWorkflowCreateFailure: null,

  masterWorkflowEditRequest: ['data', 'util'],
  masterWorkflowEditSuccess: null,
  masterWorkflowEditFailure: null,

  masterWorkflowDeleteRequest: ['data', 'id', 'info'],
  masterWorkflowDeleteSuccess: ['id'],
  masterWorkflowDeleteFailure: null,

  masterWorkflowDeleteDetailRequest: ['data', 'id', 'info'],
  masterWorkflowDeleteDetailSuccess: ['id'],
  masterWorkflowDeleteDetailFailure: null,
})

export const MasterWorkflowTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  fetching: null,
  payload: null,
  error: null,

  pagination: null,
  id: null,
  detail: null
})

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
  state.merge({ fetching: true, data, payload: null })

// successful api lookup
export const success = (state, action) => {
  const { payload, pagination } = action
  return state.merge({ fetching: false, error: null, payload, pagination })
}

// Something went wrong somewhere.
export const failure = state =>
  state.merge({ fetching: false, error: true, payload: null })

// request the data from an api
export const requestDetail = (state, { data, id }) =>
  state.merge({ fetching: true, data, id, detail: null })

// successful api lookup
export const successDetail = (state, action) => {
  const { payload } = action
  return state.merge({ fetching: false, error: null, detail: payload })
}

// Something went wrong somewhere.
export const failureDetail = state =>
  state.merge({ fetching: false, error: true, detail: null })

// request the data from an api
export const requestCreate = (state, { data }) =>
  state.merge({ fetching: true, data })

// successful api lookup
export const successCreate = (state) => {
  return state.merge({ fetching: false, error: null })
}

// Something went wrong somewhere.
export const failureCreate = state =>
  state.merge({ fetching: false, error: true })

// request the data from an api
export const requestEdit = (state, { data, id }) =>
  state.merge({ fetching: true, data, id, detail: null })

// successful api lookup
export const successEdit = (state, action) => {
  return state.merge({ fetching: false, error: null, detail: null })
}

// Something went wrong somewhere.
export const failureEdit = state =>
  state.merge({ fetching: false, error: true, detail: null })

// request the data from an api
export const requestDelete = (state, { data, id }) =>
  state.merge({ fetching: true, data, id })

// successful api lookup
export const successDelete = (state) => {
  return state.merge({ fetching: false, error: null })
}

// Something went wrong somewhere.
export const failureDelete = state =>
  state.merge({ fetching: false, error: true })

export const requestDeleteDetail = (state, { data, id }) =>
  state.merge({ fetching: true, data, id })

// successful api lookup
export const successDeleteDetail = (state) => {
  return state.merge({ fetching: false, error: null })
}

// Something went wrong somewhere.
export const failureDeleteDetail = state =>
  state.merge({ fetching: false, error: true })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.MASTER_WORKFLOW_REQUEST]: request,
  [Types.MASTER_WORKFLOW_SUCCESS]: success,
  [Types.MASTER_WORKFLOW_FAILURE]: failure,

  [Types.MASTER_WORKFLOW_DETAIL_REQUEST]: requestDetail,
  [Types.MASTER_WORKFLOW_DETAIL_SUCCESS]: successDetail,
  [Types.MASTER_WORKFLOW_DETAIL_FAILURE]: failureDetail,

  [Types.MASTER_WORKFLOW_CREATE_REQUEST]: requestCreate,
  [Types.MASTER_WORKFLOW_CREATE_SUCCESS]: successCreate,
  [Types.MASTER_WORKFLOW_CREATE_FAILURE]: failureCreate,

  [Types.MASTER_WORKFLOW_EDIT_REQUEST]: requestEdit,
  [Types.MASTER_WORKFLOW_EDIT_SUCCESS]: successEdit,
  [Types.MASTER_WORKFLOW_EDIT_FAILURE]: failureEdit,

  [Types.MASTER_WORKFLOW_DELETE_REQUEST]: requestDelete,
  [Types.MASTER_WORKFLOW_DELETE_SUCCESS]: successDelete,
  [Types.MASTER_WORKFLOW_DELETE_FAILURE]: failureDelete,

  [Types.MASTER_WORKFLOW_DELETE_DETAIL_REQUEST]: requestDeleteDetail,
  [Types.MASTER_WORKFLOW_DELETE_DETAIL_SUCCESS]: successDeleteDetail,
  [Types.MASTER_WORKFLOW_DELETE_DETAIL_FAILURE]: failureDeleteDetail,
})
