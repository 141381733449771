import React, { useEffect } from 'react';
import { Button, Checkbox, Form, Input, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';


import { userSignIn } from '../appRedux/actions/Auth';
import IntlMessages from 'util/IntlMessages';
import InfoView from 'components/InfoView';
import './styles.scss';

const FormItem = Form.Item;
const cookies = new Cookies();

const SignIn = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(({ auth }) => auth.token);

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                dispatch(userSignIn(values));
            }
        });
    };

    useEffect(() => {
        if (token !== null) {
            props.history.push('/');
        }
    }, [token, props.history]);

    const { getFieldDecorator } = props.form;

    const redirect_cause = cookies.get('redirect_cause')
    console.log('redirect_cause', redirect_cause)

    return (
        <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
                <div className="gx-app-login-main-content">
                    <div className="gx-app-logo-content">
                        <div className="gx-app-logo-content-bg">
                            <img alt="" src={require('assets/images/green-leaf-2178028.jpg')} />
                        </div>
                        <div className="gx-app-logo-wid">
                            <h1><IntlMessages id="app.userAuth.signIn" /></h1>
                            <h3 style={{ color: 'white' }}><IntlMessages id="Welcome to Enesis." /></h3>
                            {/* <p><IntlMessages id="app.userAuth.bySigning"/></p>
              <p><IntlMessages id="app.userAuth.getAccount"/></p> */}
                        </div>
                        <div className="gx-app-logo">
                            <img alt="example" src={require('assets/images/slurr.png')} style={{ width: 120, height: 60 }} />
                        </div>
                    </div>
                    <div className="gx-app-login-content">
                        <div className="form-signin">
                            <Form onSubmit={handleSubmit} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>

                                <FormItem label="Email">
                                    {getFieldDecorator('email', {
                                        initialValue: '',
                                        rules: [{
                                            required: true, message: 'Please input your Username!',
                                        }],
                                    })(
                                        <Input placeholder="Username" />
                                    )}
                                </FormItem>
                                <FormItem label="Password">
                                    {getFieldDecorator('password', {
                                        initialValue: '',
                                        rules: [{ required: true, message: 'Please input your Password!' }],
                                    })(
                                        <Input.Password type="password" placeholder="Password" />
                                    )}
                                </FormItem>
                                <FormItem>
                                    {/* {getFieldDecorator('remember', {
                                        valuePropName: 'checked',
                                        initialValue: true,
                                    })(
                                        <Checkbox><IntlMessages id="appModule.iAccept" /></Checkbox>
                                    )} */}
                                    {/* <span className="gx-signup-form-forgot gx-link"><IntlMessages
    id="appModule.termAndCondition"/></span> */}
                                    <span style={{ color: '#057d43', cursor: 'pointer' }}>
                                        {/* Contoh Message : Password anda salah */} 
                                        {
                                            (redirect_cause) ? redirect_cause : null
                                        }
                                    </span>
                                </FormItem>
                                <FormItem>
                                    <Row type="flex" justify="end" style={{marginTop: -10}}>
                                        <Col>
                                            <Link to="/forget-password">
                                                <span style={{ color: 'green' }}><IntlMessages id="Forget Password?" /> </span>
                                            </Link>
                                        </Col>
                                    </Row>
                                </FormItem>
                                <FormItem>
                                    <Row type="flex" justify="start" gutter={[10, 0]}>
                                        <Col>
                                            <div className="btn-submit-login">
                                                <Button type="primary" className="gx-mb-0" htmlType="submit" style={{ backgroundColor: '#004926 !important', borderColor: '#004926 !important' }}>
                                                    <IntlMessages id="app.userAuth.signIn" />
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col>
                                            {/* <span><IntlMessages id="app.userAuth.or" /> </span><Link to="/signup"><IntlMessages
        id="app.userAuth.signUp" /></Link> */}
                                            <span><IntlMessages id="app.userAuth.or" /> </span>
                                            <Link to="/signup">
                                                <span style={{ color: 'green' }}><IntlMessages id="app.userAuth.signUp" /> </span>
                                            </Link>
                                        </Col>
                                    </Row>
                                </FormItem>
                                {/* <span
  className="gx-text-light gx-fs-sm"> demo user email: 'demo@example.com' and password: 'demo#123'</span> */}
                            </Form>
                        </div>
                    </div>
                    <InfoView />
                </div>
            </div>
        </div >
    );
};

const WrappedNormalLoginForm = Form.create()(SignIn);

export default WrappedNormalLoginForm;
