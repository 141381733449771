/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This topApproval uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, select } from 'redux-saga/effects'
import TopApprovalActions, { TopApprovalSelectors } from '../appRedux/reducers/TopApprovalRedux' 
import _ from 'lodash'

export function * topApprovalRequest (api, action) {
  const { data, id } = action
  
  // make the call to the api
  const response = yield call(api.topApprovalRequest, data, id)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    console.log('topApproval response.data', response.data)
    yield put(TopApprovalActions.topApprovalSuccess(response.data.results, response.data.meta)) 
  } else {
    yield put(TopApprovalActions.topApprovalFailure())
  }
}

export function * topApprovalProcessRequest (api, action) {
  const { dataprocess, cb } = action
  
  // make the call to the api
  const response = yield call(api.topApprovalProcessRequest, dataprocess)

  // success?
  if (response.ok) { 
    const currentData = _.cloneDeep( yield select(TopApprovalSelectors.getPayload))

    const mergeObject = {..._.keyBy(currentData, 'request_top_id'), ..._.keyBy([response.data.data], 'request_top_id') }

    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    console.log('topApprovalProcess response.data', response.data.data)
    yield put(TopApprovalActions.topApprovalProcessSuccess(response.data.data)) 
    yield put(TopApprovalActions.topApprovalSuccess(_.values(mergeObject))) 
    
    if (cb) cb.ok()
  } else {
    yield put(TopApprovalActions.topApprovalProcessFailure())
    if (cb) cb.error()
  }
}
