/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This Organisasi uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, select } from 'redux-saga/effects'
import UserOrganisasiActions, { UserOrganisasiSelectors } from '../appRedux/reducers/UserOrganisasiRedux'
import { notification } from 'antd';
import _ from 'lodash' 
export function * userOrganisasiRequest (api, action) {
    const { data, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.userOrganisasiRequest, data)

    // success?
    if (response.ok) {
        var dataresult = response.data.results

        yield put(UserOrganisasiActions.userOrganisasiSuccess(dataresult, response.data.meta))
        if (cb) cb(true, response.data.results)
    } else {
        yield put(UserOrganisasiActions.userOrganisasiFailure())
        if (cb) cb(false)
    }
}

export function * userOrganisasiDetailRequest (api, action) {
    const { datadetail, id } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.userOrganisasiRequest, datadetail, id) 

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
        yield put(UserOrganisasiActions.userOrganisasiDetailSuccess(response.data.result))
    } else {
        yield put(UserOrganisasiActions.userOrganisasiDetailFailure())
    }
}

export function * userOrganisasiAddRequest (api, action) {
    const { dataadd, cb } = action
    // get current data from Store
    
    // make the call to the api
    const response = yield call(api.userOrganisasiAddRequest, dataadd) 

    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Data User Organisasi Added',
            placement: 'topRight',
        }); 
 
        yield put(UserOrganisasiActions.userOrganisasiAddSuccess(response.data.result))  
        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when creating Data User Organisasi, Please try again',
            placement: 'topRight',
        });
        yield put(UserOrganisasiActions.userOrganisasiAddFailure())
        if (cb) cb(false)
    }
}

export function * userOrganisasiEditRequest (api, action) {
    const { dataedit, cb } = action
    // get current data from Store
    // make the call to the api
    const response = yield call(api.userOrganisasiEditRequest, dataedit) 
  
    // success?
    if (response.ok) {
        let currentData = _.cloneDeep(yield select(UserOrganisasiSelectors.getPayload))
        currentData = _.keyBy(currentData, 'm_user_organisasi_id')

        Object.assign(currentData[dataedit.id], dataedit);



        notification.success({
            message: 'Success',
            description: 'Data User Organisasi Modified',
            placement: 'topRight',
        });

        console.log('Organisasi Edit response.data', response.data)
        yield put(UserOrganisasiActions.userOrganisasiEditSuccess(response.data.result))
        yield put(UserOrganisasiActions.userOrganisasiSuccess(_.values(currentData)))

        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when Modifying Data User Organisasi, Please try again',
            placement: 'topRight',
        });
        yield put(UserOrganisasiActions.userOrganisasiEditFailure())
        if (cb) cb(false)
    }
}

export function * userOrganisasiDeleteRequest (api, action) {
    const { datadelete, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.userOrganisasiDeleteRequest, datadelete) 

    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Data User Organisasi Deleted',
            placement: 'topRight',
        });

        console.log('OrganisasiDelete response.data', response.data)
        yield put(UserOrganisasiActions.userOrganisasiDeleteSuccess(datadelete))
        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when Deleting Data User Organisasi, Please try again',
            placement: 'topRight',
        });
        yield put(UserOrganisasiActions.userOrganisasiDeleteFailure())
        if (cb) cb(false)
    }
}