import axios from 'axios';
import {URL} from './URL';

export default axios.create({
  // baseURL: "http://g-axon.work/jwtauth/api",
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json',
  }
});
