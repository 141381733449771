/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This termOfPayment uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, select, put } from 'redux-saga/effects'
import TermOfPaymentActions, { TermOfPaymentSelectors } from '../appRedux/reducers/TermOfPaymentRedux' 
import { notification } from "antd";
import _ from 'lodash'

export function * termOfPaymentRequest (api, action) {
  const { data } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.termOfPaymentRequest, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    console.log('termOfPayment response.data', response.data)
    yield put(TermOfPaymentActions.termOfPaymentSuccess(response.data.results))
  } else {
    yield put(TermOfPaymentActions.termOfPaymentFailure())
  }
}

export function * termOfPaymentCalcRequest (api, action) {
  const { datacalc } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.termOfPaymentCalcRequest, datacalc)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    console.log('termOfPaymentCalcRequest response.data', response.data)
    yield put(TermOfPaymentActions.termOfPaymentCalcSuccess(response.data.result ? response.data.result : 0))
  } else {
    yield put(TermOfPaymentActions.termOfPaymentCalcFailure())
  }
}

export function * termOfPaymentTopRequest (api, action) {
  const { datatop, cb } = action
  // get current data from Store
  let currentData = _.cloneDeep(yield select(TermOfPaymentSelectors.getPayload))
  // make the call to the api
  const response = yield call(api.termOfPaymentTopRequest, datatop)

  // success?
  if (response.ok) {
    currentData = {..._.keyBy(currentData, 'term_of_payment_id'), ..._.keyBy([response.data.data], 'term_of_payment_id')}
    console.log('termOfPaymentTopRequest response.data', response.data.data)
    yield put(TermOfPaymentActions.termOfPaymentTopSuccess(response.data.data))
    yield put(TermOfPaymentActions.termOfPaymentSuccess(_.values(currentData)))
    // yield put(TermOfPaymentActions.termOfPaymentTopSuccess(response.data))
    notification['success']({
      message: 'Success',
      description:
        'Request term of payment Success',
    });
  } else {
    yield put(TermOfPaymentActions.termOfPaymentTopFailure())
    notification['error']({
      message: 'Failed',
      description:
        'Request term of payment Failed',
    });
  }
  if (cb) cb()
}