import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    fkrExecutionRequest: ['data', 'cb'],
    fkrExecutionSuccess: ['payload', 'pagination'],
    fkrExecutionFailure: null,

    fkrExecutionDetailRequest: ['datadetail', 'id'],
    fkrExecutionDetailSuccess: ['payloaddetail'],
    fkrExecutionDetailFailure: null,
    fkrExecutionSetDetail: ['propertykey', 'val'],

    fkrExecutionAddRequest: ['dataadd', 'cb'],
    fkrExecutionAddSuccess: ['payloadadd'],
    fkrExecutionAddFailure: null,

    fkrExecutionDeleteRequest: ['datadelete', 'cb'],
    fkrExecutionDeleteSuccess: ['payloaddelete'],
    fkrExecutionDeleteFailure: null,

    fkrExecutionEditRequest: ['dataedit', 'cb'],
    fkrExecutionEditSuccess: ['payloadedit'],
    fkrExecutionEditFailure: null,

    fkrExecutionClearDetail: null,
    fkrExecutionReset: null,

})

export const FkrExecutionTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: null,
    fetching: null,
    payload: [],
    error: null,

    datadetail: null,
    fetchingdetail: null,
    payloaddetail: null,
    errordetail: null,

    dataadd: null,
    fetchingadd: null,
    payloadadd: null,
    erroradd: null,

    dataedit: null,
    fetchingedit: null,
    payloadedit: null,
    erroredit: null,

    datadelete: null,
    fetchingdelete: null,
    payloaddelete: null,
    errordelete: null,

})

/* ------------- Selectors ------------- */

export const FkrExecutionSelectors = {
    getData: state => state.fkrexecution.data
}

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
    state.merge({ fetching: true, data })

// successful api lookup
export const success = (state, action) => {
    const { payload, pagination } = action
    return state.merge({ fetching: false, error: null, payload, pagination: (pagination) ? pagination : state.pagination })
}

// Something went wrong somewhere.
export const failure = state =>
    state.merge({ fetching: false, error: true })


//DETAIL
export const requestdetail = (state, { datadetail }) =>
    state.merge({ fetchingdetail: true, datadetail, payloaddetail: null })

// successful api lookup
export const successdetail = (state, action) => {
    const { payloaddetail } = action
    return state.merge({ fetchingdetail: false, errordetail: null, payloaddetail })
}

// Something went wrong somewhere.
export const failuredetail = state =>
    state.merge({ fetchingdetail: false, errordetail: true, payloaddetail: null })

export const setdetail = (state, action) => {
    const { propertykey, val } = action

    return state.merge({ payloaddetail: {...state.payloaddetail, [propertykey]: val } })
}

//ADD
export const requestadd = (state, { dataadd }) =>
    state.merge({ fetchingadd: true, dataadd, payloadadd: null })

// successful api lookup
export const successadd = (state, action) => {
    const { payloadadd } = action
    return state.merge({ fetchingadd: false, erroradd: null, payloadadd/*, payload:[...state.payload, payloadadd]*/ })
}

// Something went wrong somewhere.
export const failureadd = state =>
    state.merge({ fetchingadd: false, erroradd: true, payloadadd: null })


//EDIT
export const requestedit = (state, { dataedit }) =>
    state.merge({ fetchingedit: true, dataedit, payloadedit: null })

// successful api lookup
export const successedit = (state, action) => {
    const { payloadedit } = action
    return state.merge({ fetchingedit: false, erroredit: null, payloadedit/*, payload:[...state.payload, payloadedit]*/ })
}

// Something went wrong somewhere.
export const failureedit = state =>
    state.merge({ fetchingedit: false, erroredit: true, payloadedit: null })


//DELETE
export const requestdelete = (state, { datadelete }) =>
    state.merge({ fetchingdelete: true, datadelete, payloaddelete: null })

// successful api lookup
export const successdelete = (state, action) => {
    const { payloaddelete } = action
    return state.merge({ fetchingdelete: false, errordelete: null, payloaddelete, payload:[...state.payload.filter( x => x.m_fkrExecution_id !== payloaddelete )] })
}

// Something went wrong somewhere.
export const failuredelete = state =>
    state.merge({ fetchingdelete: false, errordelete: true, payloaddelete: null })  

export const cleardetail = state =>
    state.merge({ payloaddetail: INITIAL_STATE.payloaddetail })    

export const reset = () => INITIAL_STATE   

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.FKR_EXECUTION_REQUEST]: request,
    [Types.FKR_EXECUTION_SUCCESS]: success,
    [Types.FKR_EXECUTION_FAILURE]: failure,

    [Types.FKR_EXECUTION_DETAIL_REQUEST]: requestdetail,
    [Types.FKR_EXECUTION_DETAIL_SUCCESS]: successdetail,
    [Types.FKR_EXECUTION_DETAIL_FAILURE]: failuredetail,
    [Types.FKR_EXECUTION_SET_DETAIL]: setdetail,

    [Types.FKR_EXECUTION_ADD_REQUEST]: requestadd,
    [Types.FKR_EXECUTION_ADD_SUCCESS]: successadd,
    [Types.FKR_EXECUTION_ADD_FAILURE]: failureadd,

    [Types.FKR_EXECUTION_EDIT_REQUEST]: requestedit,
    [Types.FKR_EXECUTION_EDIT_SUCCESS]: successedit,
    [Types.FKR_EXECUTION_EDIT_FAILURE]: failureedit,

    [Types.FKR_EXECUTION_DELETE_REQUEST]: requestdelete,
    [Types.FKR_EXECUTION_DELETE_SUCCESS]: successdelete,
    [Types.FKR_EXECUTION_DELETE_FAILURE]: failuredelete,

    [Types.FKR_EXECUTION_CLEAR_DETAIL]: cleardetail,
    [Types.FKR_EXECUTION_RESET]: reset,
})
