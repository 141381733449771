/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This budgetVariant uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put } from 'redux-saga/effects'
import VariantActions from '../../appRedux/reducers/MasterData/VariantRedux'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * getMasterVariant (api, action) {
  const { data } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.getMasterVariant, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetVariant response.data', response.data)
    yield put(VariantActions.masterVariantSuccess(response.data.results, response.data.meta))
  } else {
    yield put(VariantActions.masterVariantFailure())
  }
}

export function * getDetailMasterVariant (api, action) {
  const { data, id } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.getMasterVariant, data, id)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetVariant response.data', response.data)
    yield put(VariantActions.masterVariantDetailSuccess(response.data.result))
  } else {
    yield put(VariantActions.masterVariantDetailFailure())
  }
}

export function * createMasterVariant (api, action) {
  const { data, util: { info, callback } } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.postMasterVariant, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetVariant response.data', response.data)
    info.success(response.data.message)
    callback()
    yield put(VariantActions.masterVariantCreateSuccess())
  } else {
    info.error(response.data.message)
    yield put(VariantActions.masterVariantCreateFailure())
  }
}

export function * editMasterVariant (api, action) {
  const { data, util: { info, callback } } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.putMasterVariant, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetVariant response.data', response.data)
    info.success(response.data.message)
    callback()
    yield put(VariantActions.masterVariantEditSuccess())
  } else {
    info.error(response.data.message)
    yield put(VariantActions.masterVariantEditFailure())
  }
}

export function * deleteMasterVariant (api, action) {
  const { data, id, info } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.deleteMasterVariant, data, id)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetVariant response.data', response.data)
    info.success(response.data.message)
    yield put(VariantActions.masterVariantDeleteSuccess())
    yield put(VariantActions.masterVariantRequest())
  } else {
    info.error(response.data.message)
    yield put(VariantActions.masterVariantDeleteFailure())
  }
}
