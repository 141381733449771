/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This resetPassword uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put } from 'redux-saga/effects'
import ResetPasswordActions from '../appRedux/reducers/ResetPasswordRedux'
import { notification } from 'antd'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * resetPasswordRequest (api, action) {
    const { data, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.resetPasswordRequest, data)

    // success?
    if (response.ok && !response.data.error) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        console.log('resetPassword response.data', response.data)
        notification.success({
            message: 'Success',
            description: response.data.message,
            placement: 'topRight',
        })     
        if (cb) cb(true)
        yield put(ResetPasswordActions.resetPasswordSuccess(response.data.data))        
    } else {
        let err = 'Unknown ERROR, Gagal Mengubah Password'
        if (response.data.meta && response.data.meta.message) {
            err = response.data.meta.message
        }
        notification.error({
            message: 'Failed',
            description: err,
            placement: 'topRight',
        })
        if (cb) cb(false)
        yield put(ResetPasswordActions.resetPasswordFailure())        
    }
}
