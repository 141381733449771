import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  masterMarketTypeRequest: ['data'],
  masterMarketTypeSuccess: ['payload', 'pagination'],
  masterMarketTypeFailure: null,

  masterMarketTypeDetailRequest: ['data', 'id'],
  masterMarketTypeDetailSuccess: ['payload'],
  masterMarketTypeDetailFailure: null,

  masterMarketTypeCreateRequest: ['data', 'util'],
  masterMarketTypeCreateSuccess: null,
  masterMarketTypeCreateFailure: null,

  masterMarketTypeEditRequest: ['data', 'util'],
  masterMarketTypeEditSuccess: null,
  masterMarketTypeEditFailure: null,

  masterMarketTypeDeleteRequest: ['data', 'id', 'info'],
  masterMarketTypeDeleteSuccess: null,
  masterMarketTypeDeleteFailure: null,
})

export const MasterMarketTypeTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  fetching: null,
  payload: null,
  error: null,

  pagination: null,
  id: null,
  detail: null
})

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
  state.merge({ fetching: true, data, payload: null })

// successful api lookup
export const success = (state, action) => {
  const { payload, pagination } = action
  return state.merge({ fetching: false, error: null, payload, pagination })
}

// Something went wrong somewhere.
export const failure = state =>
  state.merge({ fetching: false, error: true, payload: null })

// request the data from an api
export const requestDetail = (state, { data, id }) =>
  state.merge({ fetching: true, data, id, detail: null })

// successful api lookup
export const successDetail = (state, action) => {
  const { payload } = action
  return state.merge({ fetching: false, error: null, detail: payload })
}

// Something went wrong somewhere.
export const failureDetail = state =>
  state.merge({ fetching: false, error: true, detail: null })

// request the data from an api
export const requestCreate = (state, { data }) =>
  state.merge({ fetching: true, data })

// successful api lookup
export const successCreate = (state) => {
  return state.merge({ fetching: false, error: null })
}

// Something went wrong somewhere.
export const failureCreate = state =>
  state.merge({ fetching: false, error: true })

// request the data from an api
export const requestEdit = (state, { data, id }) =>
  state.merge({ fetching: true, data, id, detail: null })

// successful api lookup
export const successEdit = (state, action) => {
  return state.merge({ fetching: false, error: null, detail: null })
}

// Something went wrong somewhere.
export const failureEdit = state =>
  state.merge({ fetching: false, error: true, detail: null })

// request the data from an api
export const requestDelete = (state, { data, id }) =>
  state.merge({ fetching: true, data, id })

// successful api lookup
export const successDelete = (state) => {
  return state.merge({ fetching: false, error: null })
}

// Something went wrong somewhere.
export const failureDelete = state =>
  state.merge({ fetching: false, error: true })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.MASTER_MARKET_TYPE_REQUEST]: request,
  [Types.MASTER_MARKET_TYPE_SUCCESS]: success,
  [Types.MASTER_MARKET_TYPE_FAILURE]: failure,

  [Types.MASTER_MARKET_TYPE_DETAIL_REQUEST]: requestDetail,
  [Types.MASTER_MARKET_TYPE_DETAIL_SUCCESS]: successDetail,
  [Types.MASTER_MARKET_TYPE_DETAIL_FAILURE]: failureDetail,

  [Types.MASTER_MARKET_TYPE_CREATE_REQUEST]: requestCreate,
  [Types.MASTER_MARKET_TYPE_CREATE_SUCCESS]: successCreate,
  [Types.MASTER_MARKET_TYPE_CREATE_FAILURE]: failureCreate,

  [Types.MASTER_MARKET_TYPE_EDIT_REQUEST]: requestEdit,
  [Types.MASTER_MARKET_TYPE_EDIT_SUCCESS]: successEdit,
  [Types.MASTER_MARKET_TYPE_EDIT_FAILURE]: failureEdit,

  [Types.MASTER_MARKET_TYPE_DELETE_REQUEST]: requestDelete,
  [Types.MASTER_MARKET_TYPE_DELETE_SUCCESS]: successDelete,
  [Types.MASTER_MARKET_TYPE_DELETE_FAILURE]: failureDelete,
})
