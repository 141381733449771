import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  topApprovalRequest: ['data', 'id'],
  topApprovalSuccess: ['payload', 'pagination'],
  topApprovalFailure: null,

  topApprovalProcessRequest: ['dataprocess', 'cb'],
  topApprovalProcessSuccess: ['payloadprocess'],
  topApprovalProcessFailure: null
})

export const TopApprovalTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  fetching: null,
  payload: [],
  error: null,
  pagination: {},

  dataprocess: null,
  fetchingprocess: null,
  payloadprocess: [],
  errorprocess: null,
})

/* ------------- Selectors ------------- */

export const TopApprovalSelectors = {
  getPayload: state => state.topapproval.payload
}

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
  state.merge({ fetching: true, data })

// successful api lookup
export const success = (state, action) => {
  const { payload, pagination } = action
  return state.merge({ fetching: false, error: null, payload, pagination: (pagination) ? pagination : state.pagination })
}

// Something went wrong somewhere.
export const failure = state =>
  state.merge({ fetching: false, error: true })

//PROCCESS
export const requestprocess = (state, { dataprocess }) =>
  state.merge({ fetchingprocess: true, dataprocess })

// successful api lookup
export const successprocess = (state, action) => {
  const { payloadprocess } = action
  return state.merge({ fetchingprocess: false, errorprocess: null, payloadprocess })
}

// Something went wrong somewhere.
export const failureprocess = state =>
  state.merge({ fetchingprocess: false, errorprocess: true })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.TOP_APPROVAL_REQUEST]: request,
  [Types.TOP_APPROVAL_SUCCESS]: success,
  [Types.TOP_APPROVAL_FAILURE]: failure,

  [Types.TOP_APPROVAL_PROCESS_REQUEST]: requestprocess,
  [Types.TOP_APPROVAL_PROCESS_SUCCESS]: successprocess,
  [Types.TOP_APPROVAL_PROCESS_FAILURE]: failureprocess,
  


})
