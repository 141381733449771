/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This budgetEmployee uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put } from 'redux-saga/effects'
import EmployeeActions from '../../appRedux/reducers/MasterData/EmployeeRedux'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * getMasterEmployee (api, action) {
  const { data } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.getMasterEmployee, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetEmployee response.data', response.data)
    yield put(EmployeeActions.masterEmployeeSuccess(response.data.results, response.data.meta))
  } else {
    yield put(EmployeeActions.masterEmployeeFailure())
  }
}

export function * getDetailMasterEmployee (api, action) {
  const { data, id } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.getMasterEmployee, data, id)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetEmployee response.data', response.data)
    yield put(EmployeeActions.masterEmployeeDetailSuccess(response.data.result))
  } else {
    yield put(EmployeeActions.masterEmployeeDetailFailure())
  }
}

export function * createMasterEmployee (api, action) {
  const { data, util: { info, callback } } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.postMasterEmployee, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetEmployee response.data', response.data)
    info.success(response.data.message)
    callback()
    yield put(EmployeeActions.masterEmployeeCreateSuccess())
  } else {
    info.error(response.data.message)
    yield put(EmployeeActions.masterEmployeeCreateFailure())
  }
}

export function * editMasterEmployee (api, action) {
  const { data, util: { info, callback } } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.putMasterEmployee, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetEmployee response.data', response.data)
    info.success(response.data.message)
    callback()
    yield put(EmployeeActions.masterEmployeeEditSuccess())
  } else {
    info.error(response.data.message)
    yield put(EmployeeActions.masterEmployeeEditFailure())
  }
}

export function * deleteMasterEmployee (api, action) {
  const { data, id, info } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.deleteMasterEmployee, data, id)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetEmployee response.data', response.data)
    info.success(response.data.message)
    yield put(EmployeeActions.masterEmployeeDeleteSuccess())
    yield put(EmployeeActions.masterEmployeeRequest())
  } else {
    info.error(response.data.message)
    yield put(EmployeeActions.masterEmployeeDeleteFailure())
  }
}
