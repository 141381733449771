import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    userRequest: ['data', 'cb'],
    userSuccess: ['payload', 'pagination'],
    userFailure: null,

    userDetailRequest: ['datadetail', 'id'],
    userDetailSuccess: ['payloaddetail'],
    userDetailFailure: null,

    userAddRequest: ['dataadd', 'cb'],
    userAddSuccess: ['payloadadd'],
    userAddFailure: null,

    userDeleteRequest: ['datadelete', 'cb'],
    userDeleteSuccess: ['payloaddelete'],
    userDeleteFailure: null,

    userEditRequest: ['dataedit', 'cb'],
    userEditSuccess: ['payloadedit'],
    userEditFailure: null,

    userChangeNameRequest: ['datachangename', 'id'],
    userChangeNameSuccess: ['payloadchangename'],
    userChangeNameFailure: null,

    userChangePasswordRequest: ['datachangepassword'],
    userChangePasswordSuccess: ['payloadchangepassword'],
    userChangePasswordFailure: ['payloadchangepassword'],

    userGetPlannerRequest: ['datagetplanner'],
    userGetPlannerSuccess: ['payloadgetplanner'],
    userGetPlannerFailure: null,

})

export const UserTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: null,
    fetching: null,
    payload: [],
    error: null,

    datadetail: null,
    fetchingdetail: null,
    payloaddetail: {},
    errordetail: null,

    datachangename: null,
    fetchingchangename: null,
    payloadchangename: null,
    errorchangename: null,

    datachangepassword: null,
    fetchingchangepassword: null,
    payloadchangepassword: null,
    errorchangepassword: null,

    datagetplanner: null,
    fetchinggetplanner: null,
    payloadgetplanner: [],
    errorgetplanner: null,

    dataadd: null,
    fetchingadd: null,
    payloadadd: null,
    erroradd: null,

    dataedit: null,
    fetchingedit: null,
    payloadedit: null,
    erroredit: null,

    datadelete: null,
    fetchingdelete: null,
    payloaddelete: null,
    errordelete: null,

})

/* ------------- Selectors ------------- */

export const UserSelectors = {
    getData: state => state.data
}

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
    state.merge({ fetching: true, data })

// successful api lookup
export const success = (state, action) => {
    const { payload, pagination } = action
    return state.merge({ fetching: false, error: null, payload, pagination: (pagination) ? pagination : state.pagination })
}

// Something went wrong somewhere.
export const failure = state =>
    state.merge({ fetching: false, error: true })


// successful api lookup
export const requestdetail = (state, { datadetail }) =>
    state.merge({ fetchingdetail: true, datadetail, payloaddetail: INITIAL_STATE.payloaddetail })

// successful api lookup
export const successdetail = (state, action) => {
    const { payloaddetail } = action
    return state.merge({ fetchingdetail: false, errordetail: null, payloaddetail })
}

export const failuredetail = state =>
    state.merge({ fetchingdetail: false, errordetail: true, payloaddetail: INITIAL_STATE.payloaddetail })


// Change Name.
export const requestchangename = (state, { datachangename }) =>
    state.merge({ fetchingchangename: true, datachangename, payloadchangename: INITIAL_STATE.payloadchangename })

export const successchangename = (state, action) => {
    const { payloadchangename } = action
    return state.merge({ fetchingchangename: false, errorchangename: null, payloadchangename })
}

export const failurechangename = state =>
    state.merge({ fetchingchangename: false, errorchangename: true, payloadchangename: INITIAL_STATE.payloadchangename })



//Change Password
export const requestchangepassword = (state, { datachangepassword }) =>
    state.merge({ fetchingchangepassword: true, datachangepassword,errorchangepassword: null, payloadchangepassword: INITIAL_STATE.payloadchangepassword })

export const successchangepassword = (state, action) => {
    const { payloadchangepassword } = action
    return state.merge({ fetchingchangepassword: false, errorchangepassword: null, payloadchangepassword/*, payload:[...state.payload, payloadchangepassword]*/ })
}
export const failurechangepassword = (state, action) => {
    const { payloadchangepassword } = action
    return state.merge({ fetchingchangepassword: false, errorchangepassword: true,payloadchangepassword})
}


// GET PLANNER.
export const requestgetplanner = (state, { datagetplanner }) =>
    state.merge({ fetchinggetplanner: true, datagetplanner, payloadgetplanner: [] })

export const successgetplanner = (state, action) => {
    const { payloadgetplanner } = action
    return state.merge({ fetchinggetplanner: false, errorgetplanner: null, payloadgetplanner })
}

export const failuregetplanner = state =>
    state.merge({ fetchinggetplanner: false, errorgetplanner: true, payloadgetplanner: [] })


//ADD
export const requestadd = (state, { dataadd }) =>
    state.merge({ fetchingadd: true, dataadd, payloadadd: null })

// successful api lookup
export const successadd = (state, action) => {
    const { payloadadd } = action
    return state.merge({ fetchingadd: false, erroradd: null, payloadadd, payload:[...state.payload, payloadadd] })
}

// Something went wrong somewhere.
export const failureadd = state =>
    state.merge({ fetchingadd: false, erroradd: true, payloadadd: null })


//EDIT
export const requestedit = (state, { dataedit }) =>
    state.merge({ fetchingedit: true, dataedit, payloadedit: null })

// successful api lookup
export const successedit = (state, action) => {
    const { payloadedit } = action
    return state.merge({ fetchingedit: false, erroredit: null, payloadedit/*, payload:[...state.payload, payloadedit]*/ })
}

// Something went wrong somewhere.
export const failureedit = state =>
    state.merge({ fetchingedit: false, erroredit: true, payloadedit: null })


//DELETE
export const requestdelete = (state, { datadelete }) =>
    state.merge({ fetchingdelete: true, datadelete, payloaddelete: null })

// successful api lookup
export const successdelete = (state, action) => {
    const { payloaddelete } = action
    return state.merge({ fetchingdelete: false, errordelete: null, payloaddelete, payload:[...state.payload.filter( x => x.m_user_id !== payloaddelete )] })
}

// Something went wrong somewhere.
export const failuredelete = state =>
    state.merge({ fetchingdelete: false, errordelete: true, payloaddelete: null }) 

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.USER_REQUEST]: request,
    [Types.USER_SUCCESS]: success,
    [Types.USER_FAILURE]: failure,

    [Types.USER_ADD_REQUEST]: requestadd,
    [Types.USER_ADD_SUCCESS]: successadd,
    [Types.USER_ADD_FAILURE]: failureadd,

    [Types.USER_EDIT_REQUEST]: requestedit,
    [Types.USER_EDIT_SUCCESS]: successedit,
    [Types.USER_EDIT_FAILURE]: failureedit,

    [Types.USER_DELETE_REQUEST]: requestdelete,
    [Types.USER_DELETE_SUCCESS]: successdelete,
    [Types.USER_DELETE_FAILURE]: failuredelete,

    [Types.USER_DETAIL_REQUEST]: requestdetail,
    [Types.USER_DETAIL_SUCCESS]: successdetail,
    [Types.USER_DETAIL_FAILURE]: failuredetail,

    [Types.USER_CHANGE_NAME_REQUEST]: requestchangename,
    [Types.USER_CHANGE_NAME_SUCCESS]: successchangename,
    [Types.USER_CHANGE_NAME_FAILURE]: failurechangename,

    [Types.USER_CHANGE_PASSWORD_REQUEST]: requestchangepassword,
    [Types.USER_CHANGE_PASSWORD_SUCCESS]: successchangepassword,
    [Types.USER_CHANGE_PASSWORD_FAILURE]: failurechangepassword,
    
    [Types.USER_GET_PLANNER_REQUEST]: requestgetplanner,
    [Types.USER_GET_PLANNER_SUCCESS]: successgetplanner,
    [Types.USER_GET_PLANNER_FAILURE]: failuregetplanner,
})
