/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This Organisasi uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, select } from 'redux-saga/effects'
import OrganisasiActions, { OrganisasiSelectors } from '../appRedux/reducers/OrganisasiRedux'
import { notification } from 'antd';
import _ from 'lodash' 
export function * organisasiRequest (api, action) {
    const { data, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.organisasiRequest, data)

    // success?
    if (response.ok) {
        var dataresult = response.data.results

        yield put(OrganisasiActions.organisasiSuccess(dataresult, response.data.meta))
        if (cb) cb(true, response.data.results)
    } else {
        yield put(OrganisasiActions.organisasiFailure())
        if (cb) cb(false)
    }
}

export function * organisasiDetailRequest (api, action) {
    const { datadetail, id } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.organisasiRequest, datadetail, id) 

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
        yield put(OrganisasiActions.organisasiDetailSuccess(response.data.result))
    } else {
        yield put(OrganisasiActions.organisasiDetailFailure())
    }
}

export function * organisasiAddRequest (api, action) {
    const { dataadd, cb } = action
    // get current data from Store
    
    // make the call to the api
    const response = yield call(api.organisasiAddRequest, dataadd) 

    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Data Organisasi Added',
            placement: 'topRight',
        }); 
 
        yield put(OrganisasiActions.organisasiAddSuccess(response.data.result))  
        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when creating Data Organisasi, Please try again',
            placement: 'topRight',
        });
        yield put(OrganisasiActions.organisasiAddFailure())
        if (cb) cb(false)
    }
}

export function * organisasiEditRequest (api, action) {
    const { dataedit, cb } = action
    // get current data from Store
    // make the call to the api
    const response = yield call(api.organisasiEditRequest, dataedit) 
  
    // success?
    if (response.ok) {
        let currentData = _.cloneDeep(yield select(OrganisasiSelectors.getPayload))
        currentData = _.keyBy(currentData, 'r_organisasi_id')

        Object.assign(currentData[dataedit.id], dataedit);



        notification.success({
            message: 'Success',
            description: 'Data Organisasi Modified',
            placement: 'topRight',
        });

        console.log('Organisasi Edit response.data', response.data)
        yield put(OrganisasiActions.organisasiEditSuccess(response.data.result))
        yield put(OrganisasiActions.organisasiSuccess(_.values(currentData)))

        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when Modifying Data Organisasi, Please try again',
            placement: 'topRight',
        });
        yield put(OrganisasiActions.organisasiEditFailure())
        if (cb) cb(false)
    }
}

export function * organisasiDeleteRequest (api, action) {
    const { datadelete, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.organisasiDeleteRequest, datadelete) 

    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Data Organisasi Deleted',
            placement: 'topRight',
        });

        console.log('OrganisasiDelete response.data', response.data)
        yield put(OrganisasiActions.organisasiDeleteSuccess(datadelete))
        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when Deleting Data Organisasi, Please try again',
            placement: 'topRight',
        });
        yield put(OrganisasiActions.organisasiDeleteFailure())
        if (cb) cb(false)
    }
}