import { combineReducers } from 'redux';
import Settings from './Settings';
import Auth from './Auth';
import Common from './Common';

import { cmo_data, cmo_form } from '../../routes/CMO/store/reducers';
import { rpo_data, rpo_form } from '../../routes/RPO/store/reducers';
import { sales_data, sales_form } from '../../routes/Sales/store/reducers';
import { distributorsales_data } from '../../routes/DistributorSales/store/reducers';
import { distributoraccounting_data } from '../../routes/DistributorAccounting/store/reducers';
import { distributorlogistik_data } from '../../routes/DistributorLogistik/store/reducers';
// import { bidding_data, bidding_form } from "../../routes/BiddingInformation/store/reducers";
import { data } from '../../routes/SampleFilter/store/reducers';
import { connectRouter } from 'connected-react-router';


export default history =>
    combineReducers({
        router: connectRouter(history),
        settings: Settings,
        cmo: combineReducers({
            data: cmo_data,
            form: cmo_form
        }),
        rpo: combineReducers({
            data: rpo_data,
            form: rpo_form
        }),
        sales: combineReducers({
            data: sales_data,
            form: sales_form
        }),
        // bidding_information: combineReducers({
        //   data: bidding_data,
        //   form: bidding_form
        // }),
        distributorsales: combineReducers({
            data: distributorsales_data,
        }),
        distributoraccounting: combineReducers({
            data: distributoraccounting_data,
        }),
        distributorlogistik: combineReducers({
            data: distributorlogistik_data,
        }),
        sample: combineReducers({
            data: data,
        }),
        auth: Auth,
        commonData: Common,
        //versi ignite
        startup: require('./StartupRedux').reducer,
        template: require('./TemplateRedux').reducer,
        shipment: require('./ShipmentRedux').reducer,
        transporter: require('./TransporterRedux').reducer,
        order: require('./OrderRedux').reducer,
        createshipment: require('./CreateShipmentRedux').reducer,
        loadingoverlay: require('./LoadingOverlayRedux').reducer,
        bidding: require('./BiddingRedux').reducer,
        biddinginformation: require('./BiddingInformationRedux').reducer,
        trackingorder: require('./TrackingOrderRedux').reducer,
        deliveryorder: require('./DeliveryOrderRedux').reducer,
        user: require('./UserRedux').reducer,
        trackingplafond: require('./TrackingPlafondRedux').reducer,
        listsoldto: require('./ListSoldToRedux').reducer,
        termofpayment: require('./TermOfPaymentRedux').reducer,
        topapproval: require('./TopApprovalRedux').reducer,
        contract: require('./ContractRedux').reducer,
        driver: require('./DriverRedux').reducer,
        menu: require('./MenuRedux').reducer,
        faktur: require('./FakturRedux').reducer,
        fkrbarang: require('./FkrBarangRedux').reducer,
        fkrexecution: require('./FkrExecutionRedux').reducer,
        klaimproposal: require('./KlaimProposalRedux').reducer,
        distributionchannel: require('./DistributionChannelRedux').reducer,
        pajak: require('./PajakRedux').reducer,
        dropdown: require('./DropdownRedux').reducer,
        partner: require('./PartnerRedux').reducer,
        paymentterm: require('./PaymentTermRedux').reducer,
        fkrforexport: require('./FkrForExportRedux').reducer,
        resetpassword: require('./ResetPasswordRedux').reducer,
        jenisorganisasi: require('./JenisOrganisasiRedux').reducer,
        organisasi: require('./OrganisasiRedux').reducer,
        userorganisasi: require('./UserOrganisasiRedux').reducer,
        role: require('./RoleRedux').reducer,
        rolemenu: require('./RoleMenuRedux').reducer,
        getdocumentno: require('./GetDocumentNoRedux').reducer,
        getconsoleno: require('./GetConsoleNoRedux').reducer,
        cmoreport: require('./CmoReportRedux').reducer,
        cmoapprove: require('./CmoRedux').reducer,
        edititemcmo: require('./EditItemCMORedux').reducer,
        budgetbrand: require('./BudgetBrandRedux').reducer,
        budgeting: require('./BudgetingRedux').reducer,
        budgetactivity: require('./BudgetActivityRedux').reducer,
        budgetsummary: require('./BudgetSummaryRedux').reducer,
        proposal: require('./ProposalRedux').reducer,
        budgetyear: require('./BudgetYearRedux').reducer,
        division: require('./DivisionRedux').reducer,
        region: require('./RegionRedux').reducer,
        executor: require('./ExecutorRedux').reducer,
        markettype: require('./MarketTypeRedux').reducer,
        distributormt: require('./DistributorMtRedux').reducer,
        branch: require('./BranchRedux').reducer,
        branchmultiple: require('./BranchMultipleRedux').reducer,
        brand: require('./BrandRedux').reducer,
        sku: require('./SkuRedux').reducer,
        activity: require('./ActivityRedux').reducer,
        cekbudget: require('./CekBudgetRedux').reducer,
        approvalproposal: require('./ApprovalProposalRedux').reducer,
        approvalbudget: require('./ApprovalBudgetRedux').reducer,
        masteractivity: require('./MasterData/ActivityRedux').reducer,
        masterbrand: require('./MasterData/BrandRedux').reducer,
        mastermarkettype: require('./MasterData/MarketTypeRedux').reducer,
        masterdivision: require('./MasterData/DivisionRedux').reducer,
        mastercompany: require('./MasterData/CompanyRedux').reducer,
        masterdistributor: require('./MasterData/DistributorRedux').reducer,
        masteremployee: require('./MasterData/EmployeeRedux').reducer,
        masterregion: require('./MasterData/RegionRedux').reducer,
        mastervariant: require('./MasterData/VariantRedux').reducer,
        masterbudget: require('./MasterData/BudgetRedux').reducer,
        masterposition: require('./MasterData/PositionRedux').reducer,
        masterworkflow: require('./MasterData/WorkflowRedux').reducer,
        mastergroupactivity: require('./MasterData/GroupActivityRedux').reducer,

        quarter: require('./QuarterRedux').reducer,

        reportlogbook: require('./Report/LogbookRedux').reducer,
        reportpayment: require('./Report/PaymentRedux').reducer,
        status: require('./MasterData/StatusRedux').reducer,
        shipto: require('./ShiptoRedux').reducer,
        shiptoall: require('./ShiptoAllRedux').reducer,
        cmodatevalidator: require('./CmoDateValidatorRedux').reducer,
    });
