/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This driver uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, select } from 'redux-saga/effects'
import DriverActions, { DriverSelectors } from '../appRedux/reducers/DriverRedux'
import { notification } from 'antd';
import _ from 'lodash' 
export function * driverRequest (api, action) {
    const { data, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.driverRequest, data)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        console.log('driver response.data', response.data)
        yield put(DriverActions.driverSuccess(response.data.results, response.data.meta))
        if (cb) cb(true, response.data.results)
    } else {
        yield put(DriverActions.driverFailure())
        if (cb) cb(false)
    }
}

export function * driverDetailRequest (api, action) {
    const { datadetail, id } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.driverRequest, datadetail, id) 

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        console.log('driverDetail response.data', response.data.result)
        yield put(DriverActions.driverDetailSuccess(response.data.result))
    } else {
        yield put(DriverActions.driverDetailFailure())
    }
}

export function * driverAddRequest (api, action) {
    const { dataadd, cb } = action
    // get current data from Store
    
    // make the call to the api
    const response = yield call(api.driverAddRequest, dataadd) 

    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Data Driver Added',
            placement: 'topRight',
        }); 
 
        yield put(DriverActions.driverAddSuccess(response.data.data))  
        if (cb) cb(true)
    } else {
        if (response.data && response.data.meta && response.data.meta.message) {
            notification.error({
                message: 'Failed',
                description: response.data.meta.message,
                placement: 'topRight',
            })
        } else {
            notification.error({
                message: 'Failed',
                description: 'Unknown Error when creating Data Driver, Please try again',
                placement: 'topRight',
            });
        } 
        yield put(DriverActions.driverAddFailure())
        if (cb) cb(false)
    }
}

export function * driverEditRequest (api, action) {
    const { dataedit, cb } = action
    // get current data from Store
    // make the call to the api
    const response = yield call(api.driverEditRequest, dataedit) 
  
    // success?
    if (response.ok) {
        let currentData = _.cloneDeep(yield select(DriverSelectors.getPayload))
        currentData = _.keyBy(currentData, 'm_driver_id')

        Object.assign(currentData[dataedit.m_driver_id], dataedit);



        notification.success({
            message: 'Success',
            description: 'Data Driver Modified',
            placement: 'topRight',
        });

        console.log('driverEdit response.data', response.data)
        yield put(DriverActions.driverEditSuccess(response.data))
        yield put(DriverActions.driverSuccess(_.values(currentData)))

        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when Modifying Data Driver, Please try again',
            placement: 'topRight',
        });
        yield put(DriverActions.driverEditFailure())
        if (cb) cb(false)
    }
}

export function * driverDeleteRequest (api, action) {
    const { datadelete, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.driverDeleteRequest, datadelete) 

    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Data Driver Deleted',
            placement: 'topRight',
        });

        console.log('driverDelete response.data', response.data)
        yield put(DriverActions.driverDeleteSuccess(datadelete.m_driver_id))
        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when Deleting Data Driver, Please try again',
            placement: 'topRight',
        });
        yield put(DriverActions.driverDeleteFailure())
        if (cb) cb(false)
    }
}