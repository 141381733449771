import {
    GET_DATA,
    SET_LOADING,
    SET_PAGINATION,
    GET_SINGLE_DATA
} from "../constants/sales_data";

const initialState = {
    data: [],
    pagination: {},
    loading: false,
    oneData: {}
};

const sales_data = function(state = initialState, action) {
    switch (action.type) {
    case GET_DATA: {
        return {
            ...state,
            data: action.payload
        };
    }
    case SET_LOADING: {
        return {
            ...state,
            loading: action.payload
        };
    }
    case SET_PAGINATION: {
        return {
            ...state,
            pagination: { ...action.payload }
        };
    }
    case GET_SINGLE_DATA: {
        return {
            ...state,
            oneData: action.payload
        };
    }
    default: {
        return state;
    }
    }
};

export default sales_data;
