/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This fkrExecution uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, select } from 'redux-saga/effects'
import FkrExecutionActions, { FkrExecutionSelectors } from '../appRedux/reducers/FkrExecutionRedux'
import { notification } from 'antd';
import _ from 'lodash'

export function * fkrExecutionRequest (api, action) {
    const { data, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.fkrExecutionRequest, data)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        console.log('fkrExecution response.data', response.data)
        yield put(FkrExecutionActions.fkrExecutionSuccess(response.data.results, response.data.meta))
        if (cb) cb(true, response.data.results)
    } else {
        yield put(FkrExecutionActions.fkrExecutionFailure())
        if (cb) cb(false)
    }
}

export function * fkrExecutionDetailRequest (api, action) {
    const { datadetail, id } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.fkrExecutionRequest, datadetail, id) 

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        console.log('fkrExecutionDetail response.data', response.data.result)
        yield put(FkrExecutionActions.fkrExecutionDetailSuccess(response.data.result))
    } else {
        yield put(FkrExecutionActions.fkrExecutionDetailFailure())
    }
} 