import { takeLatest, all } from 'redux-saga/effects'
import API from '../Services/Api'
import API_TEMPLATE from '../Services/Api_Template'
// import FixtureAPI from '../Services/FixtureApi'
// import DebugConfig from '../Config/DebugConfig'

/* ------------- Types ------------- */

import { StartupTypes } from '../appRedux/reducers/StartupRedux'
import { TemplateTypes } from '../appRedux/reducers/TemplateRedux'
import { ShipmentTypes } from '../appRedux/reducers/ShipmentRedux'
import { TransporterTypes }  from '../appRedux/reducers/TransporterRedux'
import { OrderTypes }  from '../appRedux/reducers/OrderRedux'
import { CreateShipmentTypes } from '../appRedux/reducers/CreateShipmentRedux'
import { BiddingTypes } from '../appRedux/reducers/BiddingRedux'
import { BiddingInformationTypes } from '../appRedux/reducers/BiddingInformationRedux'
import { TrackingOrderTypes } from '../appRedux/reducers/TrackingOrderRedux'
import { UserTypes } from '../appRedux/reducers/UserRedux'
import { DeliveryOrderTypes } from '../appRedux/reducers/DeliveryOrderRedux'
import { TrackingPlafondTypes } from '../appRedux/reducers/TrackingPlafondRedux'
import { ListSoldToTypes } from '../appRedux/reducers/ListSoldToRedux'
import { TermOfPaymentTypes } from '../appRedux/reducers/TermOfPaymentRedux'
import { TopApprovalTypes } from '../appRedux/reducers/TopApprovalRedux'
import { ContractTypes } from '../appRedux/reducers/ContractRedux'
import { DriverTypes } from '../appRedux/reducers/DriverRedux'
import { MenuTypes } from '../appRedux/reducers/MenuRedux'
import { JenisOrganisasiTypes } from '../appRedux/reducers/JenisOrganisasiRedux'
import { OrganisasiTypes } from '../appRedux/reducers/OrganisasiRedux'
import { UserOrganisasiTypes } from '../appRedux/reducers/UserOrganisasiRedux'
import { RoleTypes } from '../appRedux/reducers/RoleRedux'
import { RoleMenuTypes } from '../appRedux/reducers/RoleMenuRedux'
import { FakturTypes } from '../appRedux/reducers/FakturRedux'
import { FkrBarangTypes } from '../appRedux/reducers/FkrBarangRedux'
import { FkrExecutionTypes } from '../appRedux/reducers/FkrExecutionRedux'
import { KlaimProposalTypes } from '../appRedux/reducers/KlaimProposalRedux'
import { PajakTypes } from '../appRedux/reducers/PajakRedux'
import { DropdownTypes } from '../appRedux/reducers/DropdownRedux'
import { PartnerTypes } from '../appRedux/reducers/PartnerRedux'
import { PaymentTermTypes } from '../appRedux/reducers/PaymentTermRedux'
import { FkrForExportTypes } from '../appRedux/reducers/FkrForExportRedux'
import { ResetPasswordTypes } from '../appRedux/reducers/ResetPasswordRedux'
import { DistributionChannelTypes } from '../appRedux/reducers/DistributionChannelRedux'
import { GetDocumentNoTypes } from '../appRedux/reducers/GetDocumentNoRedux'
import { GetConsoleNoTypes } from '../appRedux/reducers/GetConsoleNoRedux'
import { CmoReportTypes } from '../appRedux/reducers/CmoReportRedux'
import { ApproveCMOTypes } from '../appRedux/reducers/CmoRedux'
import { EditItemCMOTypes } from '../appRedux/reducers/EditItemCMORedux'
import { BudgetingTypes } from '../appRedux/reducers/BudgetingRedux'
import { BudgetBrandTypes } from '../appRedux/reducers/BudgetBrandRedux'
import { BudgetActivityTypes } from '../appRedux/reducers/BudgetActivityRedux'
import { BudgetSummaryTypes } from '../appRedux/reducers/BudgetSummaryRedux'
import { ProposalTypes } from '../appRedux/reducers/ProposalRedux'
import { BudgetYearTypes } from '../appRedux/reducers/BudgetYearRedux'
import { DivisionTypes } from '../appRedux/reducers/DivisionRedux'
import { RegionTypes } from '../appRedux/reducers/RegionRedux'
import { ExecutorTypes } from '../appRedux/reducers/ExecutorRedux'
import { MarketTypeTypes } from '../appRedux/reducers/MarketTypeRedux'
import { DistributorMtTypes } from '../appRedux/reducers/DistributorMtRedux'
import { BranchTypes } from '../appRedux/reducers/BranchRedux'
import { BrandTypes } from '../appRedux/reducers/BrandRedux'
import { SkuTypes } from '../appRedux/reducers/SkuRedux'
import { ActivityTypes } from '../appRedux/reducers/ActivityRedux'
import { CekBudgetTypes } from '../appRedux/reducers/CekBudgetRedux'
import { ApprovalProposalTypes } from '../appRedux/reducers/ApprovalProposalRedux'
import { ApprovalBudgetTypes } from '../appRedux/reducers/ApprovalBudgetRedux'
import { MasterActivityTypes } from '../appRedux/reducers/MasterData/ActivityRedux'
import { MasterBrandTypes } from '../appRedux/reducers/MasterData/BrandRedux'
import { MasterMarketTypeTypes } from '../appRedux/reducers/MasterData/MarketTypeRedux'
import { MasterDivisionTypes } from '../appRedux/reducers/MasterData/DivisionRedux'
import { MasterCompanyTypes } from '../appRedux/reducers/MasterData/CompanyRedux'
import { MasterDistributorTypes } from '../appRedux/reducers/MasterData/DistributorRedux'
import { MasterEmployeeTypes } from '../appRedux/reducers/MasterData/EmployeeRedux'
import { MasterRegionTypes } from '../appRedux/reducers/MasterData/RegionRedux'
import { MasterVariantTypes } from '../appRedux/reducers/MasterData/VariantRedux'
import { MasterPositionTypes } from '../appRedux/reducers/MasterData/PositionRedux'
import { MasterWorkflowTypes } from '../appRedux/reducers/MasterData/WorkflowRedux'
import { MasterGroupActivityTypes } from '../appRedux/reducers/MasterData/GroupActivityRedux'
import { QuarterTypes } from '../appRedux/reducers/QuarterRedux'
import { LogbookTypes } from '../appRedux/reducers/Report/LogbookRedux'
import { PaymentTypes } from '../appRedux/reducers/Report/PaymentRedux'
import { StatusTypes } from '../appRedux/reducers/MasterData/StatusRedux'
import { ShiptoTypes } from '../appRedux/reducers/ShiptoRedux'
import { BranchMultipleTypes } from '../appRedux/reducers/BranchMultipleRedux'
import { ShiptoAllTypes } from '../appRedux/reducers/ShiptoAllRedux'
import {CmoDateValidatorTypes} from '../appRedux/reducers/CmoDateValidatorRedux'

/* ------------- Sagas ------------- */
import { dataItemCMORequest, dataEditItemCmoRequest } from './EditItemCMOSagas'
import { DataCmoDPDRequest } from './CmoSagas'
import { startup } from './StartupSagas'
import { templateRequest } from './TemplateSagas'
import { shipmentRequest } from './ShipmentSagas'
import { transporterRequest } from './TransporterSagas'
import { orderRequest, orderToSapDpdRequest } from './OrderSagas'
import { createShipmentRequest } from './CreateShipmentSagas'
import { biddingRequest } from './BiddingSagas'
import { userChangePasswordRequest,userDetailRequest, userGetPlannerRequest , userRequest, userAddRequest, userDeleteRequest, userEditRequest } from './UserSagas'
import { biddingInformationRequest, biddingInformationProcessRequest } from './BiddingInformationSagas'
import { trackingOrderRequest } from './TrackingOrderSagas'
import { deliveryOrderRequest, deliveryOrderDetailRequest, deliveryOrderSearchSapRequest, deliveryOrderAssignRequest, deliveryOrderSaveRequest, deliveryOrderProveRequest, deliveryOrderUploadPodRequest, deliveryOrderChangeDriverRequest, deliveryOrderReadyInvoiceRequest, deliveryOrderMultiAssignRequest, deliveryOrderEditRequest, deliveryOrderDeleteRequest, deliveryOrderBundleNotAssignedRequest } from './DeliveryOrderSagas'
import { trackingPlafondRequest, trackingPlafondDetailRequest } from './TrackingPlafondSagas'
import { listSoldToRequest } from './ListSoldToSagas'
import { termOfPaymentRequest, termOfPaymentCalcRequest, termOfPaymentTopRequest } from './TermOfPaymentSagas'
import { topApprovalRequest, topApprovalProcessRequest } from './TopApprovalSagas'
import { contractRequest, contractDetailRequest, contractAssignDriverRequest, contractMultipleAssignRequest } from './ContractSagas'
import { driverRequest, driverDetailRequest, driverAddRequest, driverDeleteRequest, driverEditRequest } from './DriverSagas'
import { menuRequest, menuDetailRequest, menuAddRequest, menuDeleteRequest, menuEditRequest } from './MenuSagas'
import { jenisOrganisasiRequest, jenisOrganisasiDetailRequest, jenisOrganisasiAddRequest, jenisOrganisasiDeleteRequest, jenisOrganisasiEditRequest } from './JenisOrganisasiSagas'
import { organisasiRequest, organisasiDetailRequest, organisasiAddRequest, organisasiDeleteRequest, organisasiEditRequest } from './OrganisasiSagas'
import { userOrganisasiRequest, userOrganisasiDetailRequest, userOrganisasiAddRequest, userOrganisasiDeleteRequest, userOrganisasiEditRequest } from './UserOrganisasiSagas'
import { roleRequest, roleDetailRequest, roleAddRequest, roleDeleteRequest, roleEditRequest } from './RoleSagas'
import { roleMenuRequest, roleMenuDetailRequest, roleMenuAddRequest, roleMenuDeleteRequest, roleMenuEditRequest } from './RoleMenuSagas'
import { fakturRequest, fakturDetailRequest, fakturAddRequest, fakturDeleteRequest, fakturEditRequest, fakturApproveRejectRequest, fakturCekBastRequest } from './FakturSagas'
import { fkrBarangRequest, fkrBarangDetailRequest, fkrBarangUploadRequest, fkrBarangAddRequest, fkrBarangApproveRejectRequest, fkrBarangOnLoadRequest,fkrBarangPrintRequest } from './FkrBarangSagas'
import { fkrExecutionRequest, fkrExecutionAddRequest, fkrExecutionDetailRequest } from './FkrExecutionSagas'
import { klaimProposalAddRequest, klaimProposalDetailRequest, klaimProposalRequest, klaimProposalArsRequest, klaimProposalEditRequest, klaimProposalPayRequest, klaimProposalApproveStatusRequest,klaimProposalDeleteRequest } from './KlaimProposalSagas'
import { pajakRequest } from './PajakSagas'
import { dropdownRequest } from './DropdownSagas'
import { partnerRequest } from './PartnerSagas'
import { paymentTermRequest } from './PaymentTermSagas'
import { fkrForExportRequest } from './FkrForExportSagas'
import { resetPasswordRequest } from './ResetPasswordSagas'
import { distributionChannelRequest } from './DistributionChannelSagas'
import { getDocumentNoRequest } from './GetDocumentNoSagas'
import { getConsoleNoRequest } from './GetConsoleNoSagas'
import { cmoReportRequest } from './CmoReportSagas'
import { budgetingRequest, createBudgeting, createPemindahanBudgetBudgeting } from './BudgetingSagas'
import { budgetBrandRequest } from './BudgetBrandSagas'
import { budgetActivityRequest } from './BudgetActivitySagas'
import { budgetSummaryRequest } from './BudgetSummarySagas'
import { proposalReversalRequest,proposalCrProcessRequest ,proposalRequest, proposalCrRequest, proposalDetailRequest, proposalCrDetailRequest, proposalCreateRequest, proposalDeleteFileRequest, proposalKirimRequest, proposalCopyRequest, proposalDeleteRequest, proposalApRjRequest, proposalDasboardRequest, proposalCancelRequest } from './ProposalSagas'
import { getBudgetYear } from './BudgetYearSagas'
import { getDivision } from './DivisionSagas'
import { getRegion } from './RegionSagas'
import { getExecutor } from './ExecutorSagas'
import { getMarketType } from './MarketTypeSagas'
import { getDistributorMt } from './DistributorMtSagas'
import { getBranch } from './BranchSagas'
import { getBranchMultiple } from './BranchMultipleSagas'

import { getBrand } from './BrandSagas'
import { getSku } from './SkuSagas'
import { getActivity } from './ActivitySagas'
import { cekBudgetRequest } from './CekBudgetSagas'
import { getApprovalProposal } from './ApprovalProposalSagas'
import { getApprovalBudget } from './ApprovalBudgetSagas'
import { getMasterActivity, getDetailMasterActivity, createMasterActivity, editMasterActivity, deleteMasterActivity } from './MasterData/ActivitySagas'
import { getMasterBrand, getDetailMasterBrand, createMasterBrand, editMasterBrand, deleteMasterBrand } from './MasterData/BrandSagas'
import { getMasterMarketType, getDetailMasterMarketType, createMasterMarketType, editMasterMarketType, deleteMasterMarketType } from './MasterData/MarketTypeSagas'
import { getMasterDivision, getDetailMasterDivision, createMasterDivision, editMasterDivision, deleteMasterDivision } from './MasterData/DivisionSagas'
import { getMasterCompany, getDetailMasterCompany, createMasterCompany, editMasterCompany, deleteMasterCompany } from './MasterData/CompanySagas'
import { getMasterDistributor, getDetailMasterDistributor, createMasterDistributor, editMasterDistributor, deleteMasterDistributor } from './MasterData/DistributorSagas'
import { getMasterEmployee, getDetailMasterEmployee, createMasterEmployee, editMasterEmployee, deleteMasterEmployee } from './MasterData/EmployeeSagas'
import { getMasterRegion, getDetailMasterRegion, createMasterRegion, editMasterRegion, deleteMasterRegion } from './MasterData/RegionSagas'
import { getMasterVariant, getDetailMasterVariant, createMasterVariant, editMasterVariant, deleteMasterVariant } from './MasterData/VariantSagas'
import { getMasterPosition, getDetailMasterPosition, createMasterPosition, editMasterPosition, deleteMasterPosition } from './MasterData/PositionSagas'
import { getMasterWorkflow, getDetailMasterWorkflow, createMasterWorkflow, editMasterWorkflow, deleteMasterWorkflow, deleteMasterWorkflowDetail } from './MasterData/WorkflowSagas'
import { getMasterGroupActivity, getDetailMasterGroupActivity, createMasterGroupActivity, editMasterGroupActivity, deleteMasterGroupActivity } from './MasterData/GroupActivitySagas'
import { getQuarter } from './QuarterSagas'
import { getReportGenerateLogbook, resetLogbook } from './Report/LogbookSagas'
import { getReportGeneratePayment, resetPayment } from './Report/PaymentSagas'
import {statusRequest} from './MasterData/StatusSagas'
import { getShipto } from './ShiptoSagas'
import { getShiptoAll } from './ShiptoAllSagas'

import {cmoDateValidatorRequest} from './CmoDateValidatorSagas'
/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api = { ...API.create(), ...API_TEMPLATE.create()}

/* ------------- Connect Types To Sagas ------------- */

export default function * root () {
    console.log('ROOT sagas JALAN !', FakturTypes)
    yield all([
        takeLatest(ApproveCMOTypes.DATA_CMO_DPD_REQUEST,DataCmoDPDRequest,api),
        // takeLatest(EditItemCMOTypes.DATA_EDIT_ITEM_CMO_REQUEST,dataItemCMORequest,api),
        takeLatest(EditItemCMOTypes.DATA_EDIT_ITEM_CMO_REQUEST,dataEditItemCmoRequest,api),

        takeLatest(TemplateTypes.TEMPLATE_REQUEST, templateRequest, api),
        takeLatest(ShipmentTypes.SHIPMENT_REQUEST, shipmentRequest, api),
        takeLatest(TransporterTypes.TRANSPORTER_REQUEST, transporterRequest, api),
        takeLatest(OrderTypes.ORDER_REQUEST, orderRequest, api),
        takeLatest(OrderTypes.ORDER_TO_SAP_DPD_REQUEST, orderToSapDpdRequest, api),
        takeLatest(CreateShipmentTypes.CREATE_SHIPMENT_REQUEST, createShipmentRequest, api),
        takeLatest(BiddingTypes.BIDDING_REQUEST, biddingRequest, api),

        takeLatest(BiddingInformationTypes.BIDDING_INFORMATION_REQUEST, biddingInformationRequest, api),
        takeLatest(BiddingInformationTypes.BIDDING_INFORMATION_PROCESS_REQUEST, biddingInformationProcessRequest, api),


        takeLatest(TrackingOrderTypes.TRACKING_ORDER_REQUEST, trackingOrderRequest, api),

        takeLatest(DeliveryOrderTypes.DELIVERY_ORDER_REQUEST, deliveryOrderRequest, api),
        takeLatest(DeliveryOrderTypes.DELIVERY_ORDER_DETAIL_REQUEST, deliveryOrderDetailRequest, api),
        takeLatest(DeliveryOrderTypes.DELIVERY_ORDER_SEARCH_SAP_REQUEST, deliveryOrderSearchSapRequest, api),
        takeLatest(DeliveryOrderTypes.DELIVERY_ORDER_SAVE_REQUEST, deliveryOrderSaveRequest, api),
        takeLatest(DeliveryOrderTypes.DELIVERY_ORDER_PROVE_REQUEST, deliveryOrderProveRequest, api),
        takeLatest(DeliveryOrderTypes.DELIVERY_ORDER_UPLOAD_POD_REQUEST, deliveryOrderUploadPodRequest, api),
        takeLatest(DeliveryOrderTypes.DELIVERY_ORDER_CHANGE_DRIVER_REQUEST, deliveryOrderChangeDriverRequest, api),
        takeLatest(DeliveryOrderTypes.DELIVERY_ORDER_READY_INVOICE_REQUEST, deliveryOrderReadyInvoiceRequest, api),
        takeLatest(DeliveryOrderTypes.DELIVERY_ORDER_ASSIGN_REQUEST, deliveryOrderAssignRequest, api),
        takeLatest(DeliveryOrderTypes.DELIVERY_ORDER_MULTI_ASSIGN_REQUEST, deliveryOrderMultiAssignRequest, api),
        takeLatest(DeliveryOrderTypes.DELIVERY_ORDER_EDIT_REQUEST, deliveryOrderEditRequest, api),
        takeLatest(DeliveryOrderTypes.DELIVERY_ORDER_DELETE_REQUEST, deliveryOrderDeleteRequest, api),
        takeLatest(DeliveryOrderTypes.DELIVERY_ORDER_BUNDLE_NOT_ASSIGNED_REQUEST, deliveryOrderBundleNotAssignedRequest, api),

        takeLatest(TrackingPlafondTypes.TRACKING_PLAFOND_REQUEST, trackingPlafondRequest, api),
        takeLatest(TrackingPlafondTypes.TRACKING_PLAFOND_DETAIL_REQUEST, trackingPlafondDetailRequest, api),

        takeLatest(ListSoldToTypes.LIST_SOLD_TO_REQUEST, listSoldToRequest, api),

        takeLatest(TermOfPaymentTypes.TERM_OF_PAYMENT_REQUEST, termOfPaymentRequest, api),
        takeLatest(TermOfPaymentTypes.TERM_OF_PAYMENT_CALC_REQUEST, termOfPaymentCalcRequest, api),
        takeLatest(TermOfPaymentTypes.TERM_OF_PAYMENT_TOP_REQUEST, termOfPaymentTopRequest, api),

        takeLatest(TopApprovalTypes.TOP_APPROVAL_REQUEST, topApprovalRequest, api),
        takeLatest(TopApprovalTypes.TOP_APPROVAL_PROCESS_REQUEST, topApprovalProcessRequest, api),

        takeLatest(ContractTypes.CONTRACT_REQUEST, contractRequest, api),
        takeLatest(ContractTypes.CONTRACT_DETAIL_REQUEST, contractDetailRequest, api),
        takeLatest(ContractTypes.CONTRACT_ASSIGN_DRIVER_REQUEST, contractAssignDriverRequest, api),
        takeLatest(ContractTypes.CONTRACT_MULTIPLE_ASSIGN_REQUEST, contractMultipleAssignRequest, api),

        takeLatest(DriverTypes.DRIVER_REQUEST, driverRequest, api),
        takeLatest(DriverTypes.DRIVER_DETAIL_REQUEST, driverDetailRequest, api),
        takeLatest(DriverTypes.DRIVER_ADD_REQUEST, driverAddRequest, api),
        takeLatest(DriverTypes.DRIVER_DELETE_REQUEST, driverDeleteRequest, api),
        takeLatest(DriverTypes.DRIVER_EDIT_REQUEST, driverEditRequest, api),

        takeLatest(MenuTypes.MENU_REQUEST, menuRequest, api),
        takeLatest(MenuTypes.MENU_DETAIL_REQUEST, menuDetailRequest, api),
        takeLatest(MenuTypes.MENU_ADD_REQUEST, menuAddRequest, api),
        takeLatest(MenuTypes.MENU_DELETE_REQUEST, menuDeleteRequest, api),
        takeLatest(MenuTypes.MENU_EDIT_REQUEST, menuEditRequest, api),

        takeLatest(JenisOrganisasiTypes.JENIS_ORGANISASI_REQUEST, jenisOrganisasiRequest, api),
        takeLatest(JenisOrganisasiTypes.JENIS_ORGANISASI_DETAIL_REQUEST, jenisOrganisasiDetailRequest, api),
        takeLatest(JenisOrganisasiTypes.JENIS_ORGANISASI_ADD_REQUEST, jenisOrganisasiAddRequest, api),
        takeLatest(JenisOrganisasiTypes.JENIS_ORGANISASI_EDIT_REQUEST, jenisOrganisasiEditRequest, api),
        takeLatest(JenisOrganisasiTypes.JENIS_ORGANISASI_DELETE_REQUEST, jenisOrganisasiDeleteRequest, api),

        takeLatest(OrganisasiTypes.ORGANISASI_REQUEST, organisasiRequest, api),
        takeLatest(OrganisasiTypes.ORGANISASI_DETAIL_REQUEST, organisasiDetailRequest, api),
        takeLatest(OrganisasiTypes.ORGANISASI_ADD_REQUEST, organisasiAddRequest, api),
        takeLatest(OrganisasiTypes.ORGANISASI_EDIT_REQUEST, organisasiEditRequest, api),
        takeLatest(OrganisasiTypes.ORGANISASI_DELETE_REQUEST, organisasiDeleteRequest, api),

        takeLatest(UserOrganisasiTypes.USER_ORGANISASI_REQUEST, userOrganisasiRequest, api),
        takeLatest(UserOrganisasiTypes.USER_ORGANISASI_DETAIL_REQUEST, userOrganisasiDetailRequest, api),
        takeLatest(UserOrganisasiTypes.USER_ORGANISASI_ADD_REQUEST, userOrganisasiAddRequest, api),
        takeLatest(UserOrganisasiTypes.USER_ORGANISASI_EDIT_REQUEST, userOrganisasiEditRequest, api),
        takeLatest(UserOrganisasiTypes.USER_ORGANISASI_DELETE_REQUEST, userOrganisasiDeleteRequest, api),

        takeLatest(RoleTypes.ROLE_REQUEST, roleRequest, api),
        takeLatest(RoleTypes.ROLE_DETAIL_REQUEST, roleDetailRequest, api),
        takeLatest(RoleTypes.ROLE_ADD_REQUEST, roleAddRequest, api),
        takeLatest(RoleTypes.ROLE_EDIT_REQUEST, roleEditRequest, api),
        takeLatest(RoleTypes.ROLE_DELETE_REQUEST, roleDeleteRequest, api),

        takeLatest(RoleMenuTypes.ROLE_MENU_REQUEST, roleMenuRequest, api),
        takeLatest(RoleMenuTypes.ROLE_MENU_DETAIL_REQUEST, roleMenuDetailRequest, api),
        takeLatest(RoleMenuTypes.ROLE_MENU_ADD_REQUEST, roleMenuAddRequest, api),
        takeLatest(RoleMenuTypes.ROLE_MENU_EDIT_REQUEST, roleMenuEditRequest, api),
        takeLatest(RoleMenuTypes.ROLE_MENU_DELETE_REQUEST, roleMenuDeleteRequest, api),

        takeLatest(FakturTypes.FAKTUR_REQUEST, fakturRequest, api),
        takeLatest(FakturTypes.FAKTUR_DETAIL_REQUEST, fakturDetailRequest, api),
        takeLatest(FakturTypes.FAKTUR_ADD_REQUEST, fakturAddRequest, api),
        takeLatest(FakturTypes.FAKTUR_DELETE_REQUEST, fakturDeleteRequest, api),
        takeLatest(FakturTypes.FAKTUR_EDIT_REQUEST, fakturEditRequest, api),
        takeLatest(FakturTypes.FAKTUR_APPROVE_REJECT_REQUEST, fakturApproveRejectRequest, api),
        takeLatest(FakturTypes.FAKTUR_CEK_BAST_REQUEST, fakturCekBastRequest, api),

        takeLatest(FkrBarangTypes.FKR_BARANG_REQUEST, fkrBarangRequest, api),
        takeLatest(FkrBarangTypes.FKR_BARANG_DETAIL_REQUEST, fkrBarangDetailRequest, api),
        takeLatest(FkrBarangTypes.FKR_BARANG_UPLOAD_REQUEST, fkrBarangUploadRequest, api),
        takeLatest(FkrBarangTypes.FKR_BARANG_ADD_REQUEST, fkrBarangAddRequest, api),
        takeLatest(FkrBarangTypes.FKR_BARANG_APPROVE_REJECT_REQUEST, fkrBarangApproveRejectRequest, api),
        takeLatest(FkrBarangTypes.FKR_BARANG_ON_LOAD_REQUEST, fkrBarangOnLoadRequest, api),
        takeLatest(FkrBarangTypes.FKR_BARANG_PRINT_REQUEST, fkrBarangPrintRequest, api),

        takeLatest(FkrExecutionTypes.FKR_EXECUTION_REQUEST, fkrExecutionRequest, api),
        takeLatest(FkrExecutionTypes.FKR_EXECUTION_DETAIL_REQUEST, fkrExecutionDetailRequest, api),
        // takeLatest(FkrExecutionTypes.FKR_EXECUTION_ADD_REQUEST, fkrExecutionAddRequest, api),

        takeLatest(KlaimProposalTypes.KLAIM_PROPOSAL_REQUEST, klaimProposalRequest, api),
        takeLatest(KlaimProposalTypes.KLAIM_PROPOSAL_DETAIL_REQUEST, klaimProposalDetailRequest, api),
        takeLatest(KlaimProposalTypes.KLAIM_PROPOSAL_ADD_REQUEST, klaimProposalAddRequest, api),
        takeLatest(KlaimProposalTypes.KLAIM_PROPOSAL_ARS_REQUEST, klaimProposalArsRequest, api),
        takeLatest(KlaimProposalTypes.KLAIM_PROPOSAL_EDIT_REQUEST, klaimProposalEditRequest, api),
        takeLatest(KlaimProposalTypes.KLAIM_PROPOSAL_PAY_REQUEST, klaimProposalPayRequest, api),
        takeLatest(KlaimProposalTypes.KLAIM_PROPOSAL_DELETE_REQUEST, klaimProposalDeleteRequest, api),
        takeLatest(KlaimProposalTypes.KLAIM_PROPOSAL_APPROVE_STATUS_REQUEST, klaimProposalApproveStatusRequest, api),

        takeLatest(PajakTypes.PAJAK_REQUEST, pajakRequest, api),
        takeLatest(DropdownTypes.DROPDOWN_REQUEST, dropdownRequest, api),

        takeLatest(UserTypes.USER_REQUEST, userRequest, api),
        takeLatest(UserTypes.USER_DETAIL_REQUEST, userDetailRequest, api),
        takeLatest(UserTypes.USER_CHANGE_PASSWORD_REQUEST, userChangePasswordRequest, api),
        takeLatest(UserTypes.USER_GET_PLANNER_REQUEST, userGetPlannerRequest, api),
        takeLatest(UserTypes.USER_ADD_REQUEST, userAddRequest, api),
        takeLatest(UserTypes.USER_EDIT_REQUEST, userEditRequest, api),
        takeLatest(UserTypes.USER_DELETE_REQUEST, userDeleteRequest, api),

        takeLatest(PartnerTypes.PARTNER_REQUEST, partnerRequest, api),
        takeLatest(PaymentTermTypes.PAYMENT_TERM_REQUEST, paymentTermRequest, api),

        takeLatest(FkrForExportTypes.FKR_FOR_EXPORT_REQUEST, fkrForExportRequest, api),

        takeLatest(ResetPasswordTypes.RESET_PASSWORD_REQUEST, resetPasswordRequest, api),
        takeLatest(DistributionChannelTypes.DISTRIBUTION_CHANNEL_REQUEST, distributionChannelRequest, api),

        takeLatest(GetDocumentNoTypes.GET_DOCUMENT_NO_REQUEST, getDocumentNoRequest, api),

        takeLatest(GetConsoleNoTypes.GET_CONSOLE_NO_REQUEST, getConsoleNoRequest, api),

        takeLatest(CmoReportTypes.CMO_REPORT_REQUEST, cmoReportRequest, api),

        takeLatest(BudgetingTypes.BUDGETING_REQUEST, budgetingRequest, api),
        takeLatest(BudgetingTypes.BUDGETING_CREATE_REQUEST, createBudgeting, api),
        takeLatest(BudgetingTypes.BUDGETING_CREATE_PEMINDAHAN_BUDGET_REQUEST, createPemindahanBudgetBudgeting, api),

        takeLatest(BudgetBrandTypes.BUDGET_BRAND_REQUEST, budgetBrandRequest, api),
        takeLatest(BudgetActivityTypes.BUDGET_ACTIVITY_REQUEST, budgetActivityRequest, api),
        takeLatest(BudgetSummaryTypes.BUDGET_SUMMARY_REQUEST, budgetSummaryRequest, api),

        takeLatest(ProposalTypes.PROPOSAL_REQUEST, proposalRequest, api),
        takeLatest(ProposalTypes.PROPOSAL_CR_REQUEST, proposalCrRequest, api),
        takeLatest(ProposalTypes.PROPOSAL_DETAIL_REQUEST, proposalDetailRequest, api),
        takeLatest(ProposalTypes.PROPOSAL_CR_DETAIL_REQUEST, proposalCrDetailRequest, api),
        takeLatest(ProposalTypes.PROPOSAL_CREATE_REQUEST, proposalCreateRequest, api),
        takeLatest(ProposalTypes.PROPOSAL_DELETE_FILE_REQUEST, proposalDeleteFileRequest, api),
        takeLatest(ProposalTypes.PROPOSAL_KIRIM_REQUEST, proposalKirimRequest, api),
        takeLatest(ProposalTypes.PROPOSAL_COPY_REQUEST, proposalCopyRequest, api),
        takeLatest(ProposalTypes.PROPOSAL_DELETE_REQUEST, proposalDeleteRequest, api),
        takeLatest(ProposalTypes.PROPOSAL_AP_RJ_REQUEST, proposalApRjRequest, api),
        takeLatest(ProposalTypes.PROPOSAL_DASBOARD_REQUEST, proposalDasboardRequest, api),
        takeLatest(ProposalTypes.PROPOSAL_CANCEL_REQUEST, proposalCancelRequest, api),
        takeLatest(ProposalTypes.PROPOSAL_REVERSAL_REQUEST, proposalReversalRequest, api),
        takeLatest(ProposalTypes.PROPOSAL_CR_PROCESS_REQUEST, proposalCrProcessRequest, api),

        takeLatest(BudgetYearTypes.BUDGET_YEAR_REQUEST, getBudgetYear, api),

        takeLatest(DivisionTypes.DIVISION_REQUEST, getDivision, api),

        takeLatest(RegionTypes.REGION_REQUEST, getRegion, api),
        

        takeLatest(ExecutorTypes.EXECUTOR_REQUEST, getExecutor, api),

        takeLatest(MarketTypeTypes.MARKET_TYPE_REQUEST, getMarketType, api),

        takeLatest(DistributorMtTypes.DISTRIBUTOR_MT_REQUEST, getDistributorMt, api),

        takeLatest(BranchTypes.BRANCH_REQUEST, getBranch, api),

        takeLatest(BranchMultipleTypes.BRANCH_MULTIPLE_REQUEST, getBranchMultiple, api),

    
        takeLatest(BrandTypes.BRAND_REQUEST, getBrand, api),

        takeLatest(SkuTypes.SKU_REQUEST, getSku, api),

        takeLatest(ActivityTypes.ACTIVITY_REQUEST, getActivity, api),

        takeLatest(CekBudgetTypes.CEK_BUDGET_REQUEST, cekBudgetRequest, api),

        takeLatest(ApprovalProposalTypes.APPROVAL_PROPOSAL_REQUEST, getApprovalProposal, api),

        takeLatest(ApprovalBudgetTypes.APPROVAL_BUDGET_REQUEST, getApprovalBudget, api),

        takeLatest(MasterActivityTypes.MASTER_ACTIVITY_REQUEST, getMasterActivity, api),
        takeLatest(MasterActivityTypes.MASTER_ACTIVITY_DETAIL_REQUEST, getDetailMasterActivity, api),
        takeLatest(MasterActivityTypes.MASTER_ACTIVITY_CREATE_REQUEST, createMasterActivity, api),
        takeLatest(MasterActivityTypes.MASTER_ACTIVITY_EDIT_REQUEST, editMasterActivity, api),
        takeLatest(MasterActivityTypes.MASTER_ACTIVITY_DELETE_REQUEST, deleteMasterActivity, api),

        takeLatest(MasterBrandTypes.MASTER_BRAND_REQUEST, getMasterBrand, api),
        takeLatest(MasterBrandTypes.MASTER_BRAND_DETAIL_REQUEST, getDetailMasterBrand, api),
        takeLatest(MasterBrandTypes.MASTER_BRAND_CREATE_REQUEST, createMasterBrand, api),
        takeLatest(MasterBrandTypes.MASTER_BRAND_EDIT_REQUEST, editMasterBrand, api),
        takeLatest(MasterBrandTypes.MASTER_BRAND_DELETE_REQUEST, deleteMasterBrand, api),

        takeLatest(MasterMarketTypeTypes.MASTER_MARKET_TYPE_REQUEST, getMasterMarketType, api),
        takeLatest(MasterMarketTypeTypes.MASTER_MARKET_TYPE_DETAIL_REQUEST, getDetailMasterMarketType, api),
        takeLatest(MasterMarketTypeTypes.MASTER_MARKET_TYPE_CREATE_REQUEST, createMasterMarketType, api),
        takeLatest(MasterMarketTypeTypes.MASTER_MARKET_TYPE_EDIT_REQUEST, editMasterMarketType, api),
        takeLatest(MasterMarketTypeTypes.MASTER_MARKET_TYPE_DELETE_REQUEST, deleteMasterMarketType, api),

        takeLatest(MasterDivisionTypes.MASTER_DIVISION_REQUEST, getMasterDivision, api),
        takeLatest(MasterDivisionTypes.MASTER_DIVISION_DETAIL_REQUEST, getDetailMasterDivision, api),
        takeLatest(MasterDivisionTypes.MASTER_DIVISION_CREATE_REQUEST, createMasterDivision, api),
        takeLatest(MasterDivisionTypes.MASTER_DIVISION_EDIT_REQUEST, editMasterDivision, api),
        takeLatest(MasterDivisionTypes.MASTER_DIVISION_DELETE_REQUEST, deleteMasterDivision, api),

        takeLatest(MasterCompanyTypes.MASTER_COMPANY_REQUEST, getMasterCompany, api),
        takeLatest(MasterCompanyTypes.MASTER_COMPANY_DETAIL_REQUEST, getDetailMasterCompany, api),
        takeLatest(MasterCompanyTypes.MASTER_COMPANY_CREATE_REQUEST, createMasterCompany, api),
        takeLatest(MasterCompanyTypes.MASTER_COMPANY_EDIT_REQUEST, editMasterCompany, api),
        takeLatest(MasterCompanyTypes.MASTER_COMPANY_DELETE_REQUEST, deleteMasterCompany, api),

        takeLatest(MasterDistributorTypes.MASTER_DISTRIBUTOR_REQUEST, getMasterDistributor, api),
        takeLatest(MasterDistributorTypes.MASTER_DISTRIBUTOR_DETAIL_REQUEST, getDetailMasterDistributor, api),
        takeLatest(MasterDistributorTypes.MASTER_DISTRIBUTOR_CREATE_REQUEST, createMasterDistributor, api),
        takeLatest(MasterDistributorTypes.MASTER_DISTRIBUTOR_EDIT_REQUEST, editMasterDistributor, api),
        takeLatest(MasterDistributorTypes.MASTER_DISTRIBUTOR_DELETE_REQUEST, deleteMasterDistributor, api),

        takeLatest(MasterEmployeeTypes.MASTER_EMPLOYEE_REQUEST, getMasterEmployee, api),
        takeLatest(MasterEmployeeTypes.MASTER_EMPLOYEE_DETAIL_REQUEST, getDetailMasterEmployee, api),
        takeLatest(MasterEmployeeTypes.MASTER_EMPLOYEE_CREATE_REQUEST, createMasterEmployee, api),
        takeLatest(MasterEmployeeTypes.MASTER_EMPLOYEE_EDIT_REQUEST, editMasterEmployee, api),
        takeLatest(MasterEmployeeTypes.MASTER_EMPLOYEE_DELETE_REQUEST, deleteMasterEmployee, api),

        takeLatest(MasterRegionTypes.MASTER_REGION_REQUEST, getMasterRegion, api),
        takeLatest(MasterRegionTypes.MASTER_REGION_DETAIL_REQUEST, getDetailMasterRegion, api),
        takeLatest(MasterRegionTypes.MASTER_REGION_CREATE_REQUEST, createMasterRegion, api),
        takeLatest(MasterRegionTypes.MASTER_REGION_EDIT_REQUEST, editMasterRegion, api),
        takeLatest(MasterRegionTypes.MASTER_REGION_DELETE_REQUEST, deleteMasterRegion, api),

        takeLatest(MasterVariantTypes.MASTER_VARIANT_REQUEST, getMasterVariant, api),
        takeLatest(MasterVariantTypes.MASTER_VARIANT_DETAIL_REQUEST, getDetailMasterVariant, api),
        takeLatest(MasterVariantTypes.MASTER_VARIANT_CREATE_REQUEST, createMasterVariant, api),
        takeLatest(MasterVariantTypes.MASTER_VARIANT_EDIT_REQUEST, editMasterVariant, api),
        takeLatest(MasterVariantTypes.MASTER_VARIANT_DELETE_REQUEST, deleteMasterVariant, api),

        takeLatest(MasterPositionTypes.MASTER_POSITION_REQUEST, getMasterPosition, api),
        takeLatest(MasterPositionTypes.MASTER_POSITION_DETAIL_REQUEST, getDetailMasterPosition, api),
        takeLatest(MasterPositionTypes.MASTER_POSITION_CREATE_REQUEST, createMasterPosition, api),
        takeLatest(MasterPositionTypes.MASTER_POSITION_EDIT_REQUEST, editMasterPosition, api),
        takeLatest(MasterPositionTypes.MASTER_POSITION_DELETE_REQUEST, deleteMasterPosition, api),

        takeLatest(MasterWorkflowTypes.MASTER_WORKFLOW_REQUEST, getMasterWorkflow, api),
        takeLatest(MasterWorkflowTypes.MASTER_WORKFLOW_DETAIL_REQUEST, getDetailMasterWorkflow, api),
        takeLatest(MasterWorkflowTypes.MASTER_WORKFLOW_CREATE_REQUEST, createMasterWorkflow, api),
        takeLatest(MasterWorkflowTypes.MASTER_WORKFLOW_EDIT_REQUEST, editMasterWorkflow, api),
        takeLatest(MasterWorkflowTypes.MASTER_WORKFLOW_DELETE_REQUEST, deleteMasterWorkflow, api),
        takeLatest(MasterWorkflowTypes.MASTER_WORKFLOW_DELETE_DETAIL_REQUEST, deleteMasterWorkflowDetail, api),

        takeLatest(MasterGroupActivityTypes.MASTER_GROUP_ACTIVITY_REQUEST, getMasterGroupActivity, api),
        takeLatest(MasterGroupActivityTypes.MASTER_GROUP_ACTIVITY_DETAIL_REQUEST, getDetailMasterGroupActivity, api),
        takeLatest(MasterGroupActivityTypes.MASTER_GROUP_ACTIVITY_CREATE_REQUEST, createMasterGroupActivity, api),
        takeLatest(MasterGroupActivityTypes.MASTER_GROUP_ACTIVITY_EDIT_REQUEST, editMasterGroupActivity, api),
        takeLatest(MasterGroupActivityTypes.MASTER_GROUP_ACTIVITY_DELETE_REQUEST, deleteMasterGroupActivity, api),

        takeLatest(QuarterTypes.QUARTER_REQUEST, getQuarter, api),

        takeLatest(LogbookTypes.LOGBOOK_GENERATE_REQUEST, getReportGenerateLogbook, api),
        takeLatest(LogbookTypes.LOGBOOK_RESET_REQUEST, resetLogbook, api),

        takeLatest(PaymentTypes.PAYMENT_GENERATE_REQUEST, getReportGeneratePayment, api),
        takeLatest(PaymentTypes.PAYMENT_RESET_REQUEST, resetPayment, api),

        takeLatest(StatusTypes.STATUS_REQUEST, statusRequest, api),
        takeLatest(ShiptoTypes.SHIPTO_REQUEST, getShipto, api),
        takeLatest(ShiptoAllTypes.SHIPTO_ALL_REQUEST, getShiptoAll, api),
        takeLatest(CmoDateValidatorTypes.CMO_DATE_VALIDATOR_REQUEST, cmoDateValidatorRequest, api),
        // some sagas only receive an action
        takeLatest(StartupTypes.STARTUP, startup)
    ])
}
