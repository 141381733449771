/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This cekBudget uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/
import React from 'react'; 
import { call, put } from 'redux-saga/effects'
import CekBudgetActions from '../appRedux/reducers/CekBudgetRedux'
import { notification } from 'antd' 
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux' 
 

export function * cekBudgetRequest (api, action) {
    const { data, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.cekBudgetRequest, data)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        console.log('cekBudget response.data', response.data)
        if (cb) cb(response.data.results)
        yield put(CekBudgetActions.cekBudgetSuccess(response.data.results))
    } else {
        if (response.data && response.data.meta && response.data.meta.message) {
            let err = (response.data && response.data.meta && response.data.meta.message) ? response.data.meta.message : 'Error Cek Budget'
            let modifiedErr = err
            const errToArr = err.split(',')
            if (errToArr.length > 1) {
                let temp=[]
                for (let i = 0; i < errToArr.length; i++) {
                    temp.push(<span>{errToArr[i]}</span>)
                    temp.push(<br />)
                }
                modifiedErr = (
                    <div>
                        {temp}
                    </div>
                )
            } 
            notification.error({
                message: 'Perhatian !',
                description: modifiedErr,//err,
                placement: 'topRight',
            });
        }
        if (cb) cb(null)
        yield put(CekBudgetActions.cekBudgetFailure())
    }
}
