/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This contract uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, select } from 'redux-saga/effects'
import ContractActions, {ContractSelectors} from '../appRedux/reducers/ContractRedux'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'
import { notification } from 'antd'
import _ from 'lodash'

export function * contractRequest (api, action) {
    const { data } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.contractRequest, data)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        console.log('contract response.data', response.data)
        yield put(ContractActions.contractSuccess(response.data.results, response.data.meta))
    } else {
        yield put(ContractActions.contractFailure())
    }
}

export function * contractDetailRequest (api, action) {
    const { datadetail, id } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.contractRequest, datadetail, id) 

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        console.log('contractDetail response.data', response.data.result)
        yield put(ContractActions.contractDetailSuccess(response.data.result))
    } else {
        yield put(ContractActions.contractDetailFailure())
    }
}

export function * contractAssignDriverRequest (api, action) {
    const { dataassigndriver, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.contractAssignDriverRequest, dataassigndriver) 

    // success?
    if (response.ok) {
        const currentData = yield select(ContractSelectors.getPayload)
        const selectedData = currentData.find(x => x.c_shipment_id === dataassigndriver.c_shipment_id)

        const modifSelectedData = {
            ...selectedData,
            m_driver_id: Math.round(Math.random()*10000)+''
         
        }

        const mergeNewData = {
            ..._.keyBy(currentData, 'c_shipment_id'),
            ..._.keyBy([modifSelectedData], 'c_shipment_id')
        }

        console.log('contractAssignDriver response.data', response.data.result)
        notification.success({
            message: 'Success',
            description: 'Assign Driver Success',
            placement: 'topRight',
        })
        
        yield put(ContractActions.contractSuccess(_.values(mergeNewData)))

        yield put(ContractActions.contractAssignDriverSuccess(response.data.result))
        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Assign Driver Failed ('+ (response.data)+')',
            placement: 'topRight',
        })
        yield put(ContractActions.contractAssignDriverFailure())
        if (cb) cb(false)
    }
  
}

export function * contractMultipleAssignRequest (api, action) {
    const { datamar, cb } = action 
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.contractMultipleAssignRequest, datamar)   
    // success?
    if (response.ok) { 
        yield put(ContractActions.contractMultipleAssignSuccess(response.data))
        if (cb) cb(true, response.data)
    } else {
        if (response.data && response.data.meta && response.data.meta.message) {
            notification.error({
                message: 'Error',
                description: response.data.meta.message //getRowMessage(response.data.meta.message), 
            });
        } 
        yield put(ContractActions.contractMultipleAssignFailure())
        if (cb) cb(false)
    }
}