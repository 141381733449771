import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    cmoDateValidatorRequest: ['data', 'cb'],
    cmoDateValidatorSuccess: ['property', 'payload'],
    cmoDateValidatorFailure: ['property']
})

export const CmoDateValidatorTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: null,
    fetching: null,
    payload: null,
    error: null
})

/* ------------- Selectors ------------- */

export const CmoDateValidatorSelectors = {
    getData: state => state.data
}

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) => {
    const property = data.week_number
    console.log('REQUEST', property)
    return state.merge({ 
        fetching: {...state.fetching, [property]: true}, 
        data: {...state.data,[property]: data}, 
        payload: {...state.payload, [property]: []}, 
        error: {...state.error,[property]: null}   
    })
    // return state.merge({ fetching: true, data })
}

// successful api lookup
export const success = (state, action) => {
    const { property, payload } = action
    return state.merge({ 
        fetching: {...state.fetching,[property]: false}, 
        error: {...state.error,[property]: null}, 
        payload: {...state.payload, [property]: payload} 
    })
    // return state.merge({ fetching: false, error: null, payload })
}

// Something went wrong somewhere.
export const failure = (state, {property}) =>
    state.merge({ fetching: {...state.fetching,[property]: false}, error: {...state.error,[property]: true } })
    // state.merge({ fetching: false, error: true })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.CMO_DATE_VALIDATOR_REQUEST]: request,
    [Types.CMO_DATE_VALIDATOR_SUCCESS]: success,
    [Types.CMO_DATE_VALIDATOR_FAILURE]: failure
})
