/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This budgetGroupActivity uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put } from 'redux-saga/effects'
import GroupActivityActions from '../../appRedux/reducers/MasterData/GroupActivityRedux'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * getMasterGroupActivity (api, action) {
  const { data } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.getMasterGroupActivity, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetGroupActivity response.data', response.data)
    yield put(GroupActivityActions.masterGroupActivitySuccess(response.data.results, response.data.meta))
  } else {
    yield put(GroupActivityActions.masterGroupActivityFailure())
  }
}

export function * getDetailMasterGroupActivity (api, action) {
  const { data, id } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.getMasterGroupActivity, data, id)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetGroupActivity response.data', response.data)
    yield put(GroupActivityActions.masterGroupActivityDetailSuccess(response.data.result))
  } else {
    yield put(GroupActivityActions.masterGroupActivityDetailFailure())
  }
}

export function * createMasterGroupActivity (api, action) {
  const { data, util: { info, callback } } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.postMasterGroupActivity, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetGroupActivity response.data', response.data)
    info.success(response.data.message)
    callback()
    yield put(GroupActivityActions.masterGroupActivityCreateSuccess())
  } else {
    info.error(response.data.message)
    yield put(GroupActivityActions.masterGroupActivityCreateFailure())
  }
}

export function * editMasterGroupActivity (api, action) {
  const { data, util: { info, callback } } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.putMasterGroupActivity, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetGroupActivity response.data', response.data)
    info.success(response.data.message)
    callback()
    yield put(GroupActivityActions.masterGroupActivityEditSuccess())
  } else {
    info.error(response.data.message)
    yield put(GroupActivityActions.masterGroupActivityEditFailure())
  }
}

export function * deleteMasterGroupActivity (api, action) {
  const { data, id, info } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.deleteMasterGroupActivity, data, id)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetGroupActivity response.data', response.data)
    info.success(response.data.message)
    yield put(GroupActivityActions.masterGroupActivityDeleteSuccess())
    yield put(GroupActivityActions.masterGroupActivityRequest())
  } else {
    info.error(response.data.message)
    yield put(GroupActivityActions.masterGroupActivityDeleteFailure())
  }
}
