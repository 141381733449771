import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
    dataItemCmoRequest: ['data'],
    dataItemCmoSuccess: ['payload'],
    dataItemCmoFailure: null,

    dataEditItemCmoRequest: ['dataItemCMO', 'cb'],
    dataEditItemCmoSuccess: ['payloadordertosapdpd'],
    dataEditItemCmoFailure: null
})

export const EditItemCMOTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    data: null,
    fetching: null,
    payload: [],
    error: null,
    dataItemCMO : null,
    fetchingitemcmo: null,

})

export const OrderSelectors = {
    getPayload: state => state.order.payload
}

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
    state.merge({ fetching: true, data })

// successful api lookup
export const success = (state, action) => {
    const { payload } = action
    return state.merge({ fetching: false, error: null, payload })
}

// Something went wrong somewhere.
export const failure = state =>
    state.merge({ fetching: false, error: true })

/* ------------- Hookup Reducers To Types ------------- */
export const requestedititemcmo = (state, { dataItemCMORequest }) =>
    state.merge({ fetchingitemcmo: true, dataItemCMORequest })

export const suksesedititemcmo = (state, { payloadordertosapdpd }) =>
    state.merge({ fetchingitemcmo: false })

export const erroredititemcmo = (state) =>
    state.merge({ fetchingitemcmo: false })

// successful api lookup
// export const successordertosapdpd = (state, action) => {
//     const { payloadordertosapdpd } = action
//     return state.merge({ fetchingordertosapdpd: false, errorordertosapdpd: null, payloadordertosapdpd })
// }

// // Something went wrong somewhere.
// export const failureordertosapdpd = state =>
//     state.merge({ fetchingordertosapdpd: false, errorordertosapdpd: true })




export const reducer = createReducer(INITIAL_STATE, {
    [Types.DATA_ITEM_CMO_REQUEST]: request,
    [Types.DATA_ITEM_CMO_SUCCESS]: success,
    [Types.DATA_ITEM_CMO_FAILURE]: failure,

    [Types.DATA_EDIT_ITEM_CMO_REQUEST]: requestedititemcmo,
    [Types.DATA_EDIT_ITEM_CMO_SUCCESS]: suksesedititemcmo,
    [Types.DATA_EDIT_ITEM_CMO_FAILURE]: erroredititemcmo,
})
