export const GET_DETAIL_CMO = "@@CMO/Get detail cmo from server";
export const SET_HEADER_WEEK1 = "@@CMO/set header week 1";
export const SET_HEADER_WEEK2 = "@@CMO/set header week 2";
export const SET_HEADER_WEEK3 = "@@CMO/set header week 3";
export const SET_HEADER_WEEK4 = "@@CMO/set header week 4";
export const SET_DISTRIBUTOR_CODE = "@@CMO/set distributor code";
export const SET_DISTRIBUTOR_ID = "@@CMO/set distributor id";
export const SET_PRODUCT_TONASE_KUBIKASI = "@@CMO/set tonasi & kubikasi produk";

export const SET_HEADER_TONASE1 = "@@CMO/set header tonase 1";
export const SET_HEADER_TONASE2 = "@@CMO/set header tonase 2";
export const SET_HEADER_TONASE3 = "@@CMO/set header tonase 3";
export const SET_HEADER_TONASE4 = "@@CMO/set header tonase 4";

export const SET_HEADER_KUBIKASI1 = "@@CMO/set header kubikasi 1";
export const SET_HEADER_KUBIKASI2 = "@@CMO/set header kubikasi 2";
export const SET_HEADER_KUBIKASI3 = "@@CMO/set header kubikasi 3";
export const SET_HEADER_KUBIKASI4 = "@@CMO/set header kubikasi 4";

export const SET_HEADER_KENDARAAN1 = "@@CMO/set header kendaraan 1";
export const SET_HEADER_KENDARAAN2 = "@@CMO/set header kendaraan 2";
export const SET_HEADER_KENDARAAN3 = "@@CMO/set header kendaraan 3";
export const SET_HEADER_KENDARAAN4 = "@@CMO/set header kendaraan 4";

export const SET_SCHEDULE1 = "@@CMO/set schedule 1";
export const SET_SCHEDULE2 = "@@CMO/set schedule 2";
export const SET_SCHEDULE3 = "@@CMO/set schedule 3";
export const SET_SCHEDULE4 = "@@CMO/set schedule 4";

export const RESET = "@@CMO/reset data";
export const RESET_DETAIL = "@@CMO/reset data detail";

export const SET_TOTAL_ERROR = "@@CMO/total error data";
