/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This budgeting uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put } from 'redux-saga/effects'
import BudgetingActions from '../appRedux/reducers/BudgetingRedux'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * budgetingRequest (api, action) {
    const { data } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.budgetingRequest, data)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        // console.log('budgeting response.data', response.data)
        yield put(BudgetingActions.budgetingSuccess(response.data.results, response.data.meta))
    } else {
        yield put(BudgetingActions.budgetingFailure())
    }
}

export function * createBudgeting (api, action) {
  const { data, util: { info, callback } } = action

  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.postBudgeting, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetBudgeting response.data', response.data)
    info.success(response.data.message)
    callback()
    yield put(BudgetingActions.budgetingCreateSuccess())
  } else {
    info.error(response.data.message)
    yield put(BudgetingActions.budgetingCreateFailure())
  }
}

export function * createPemindahanBudgetBudgeting (api, action) {
  const { data, util: { info, callback } } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.postPemindahanBudgetBudgeting, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetBudgeting response.data', response.data)
    info.success(response.data.message)
    callback()
    yield put(BudgetingActions.budgetingCreatePemindahanBudgetSuccess())
  } else {
    info.error(response.data.message)
    yield put(BudgetingActions.budgetingCreatePemindahanBudgetFailure())
  }
}
