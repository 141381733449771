/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This role uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, select } from 'redux-saga/effects'
import RoleActions, { RoleSelectors } from '../appRedux/reducers/RoleRedux'
import { notification } from 'antd';
import _ from 'lodash' 
export function * roleRequest (api, action) {
    const { data, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.roleRequest, data)

    // success?
    if (response.ok) {
        var dataresult = response.data.results

        yield put(RoleActions.roleSuccess(dataresult, response.data.meta))
        if (cb) cb(true, response.data.results)
    } else {
        yield put(RoleActions.roleFailure())
        if (cb) cb(false)
    }
}

export function * roleDetailRequest (api, action) {
    const { datadetail, id } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.roleRequest, datadetail, id) 

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        yield put(RoleActions.roleDetailSuccess(response.data.result))
    } else {
        yield put(RoleActions.roleDetailFailure())
    }
}

export function * roleAddRequest (api, action) {
    const { dataadd, cb } = action
    // get current data from Store
    
    // make the call to the api
    const response = yield call(api.roleAddRequest, dataadd) 

    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Data role Added',
            placement: 'topRight',
        }); 
 
        yield put(RoleActions.roleAddSuccess(response.data.result))  
        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when creating Data role, Please try again',
            placement: 'topRight',
        });
        yield put(RoleActions.roleAddFailure())
        if (cb) cb(false)
    }
}

export function * roleEditRequest (api, action) {
    const { dataedit, cb } = action
    // get current data from Store
    // make the call to the api
    const response = yield call(api.roleEditRequest, dataedit) 
  
    // success?
    if (response.ok) {
        let currentData = _.cloneDeep(yield select(RoleSelectors.getPayload))
        currentData = _.keyBy(currentData, 'm_role_id')

        Object.assign(currentData[dataedit.id], dataedit);

        notification.success({
            message: 'Success',
            description: 'Data role Modified',
            placement: 'topRight',
        });

        yield put(RoleActions.roleEditSuccess(response.data.result))
        yield put(RoleActions.roleSuccess(_.values(currentData)))

        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when Modifying Data role, Please try again',
            placement: 'topRight',
        });
        yield put(RoleActions.roleEditFailure())
        if (cb) cb(false)
    }
}

export function * roleDeleteRequest (api, action) {
    const { datadelete, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.roleDeleteRequest, datadelete) 

    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Data role Deleted',
            placement: 'topRight',
        });

        yield put(RoleActions.roleDeleteSuccess(datadelete))
        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when Deleting Data role, Please try again',
            placement: 'topRight',
        });
        yield put(RoleActions.roleDeleteFailure())
        if (cb) cb(false)
    }
}