/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This budgetRegion uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put } from 'redux-saga/effects'
import RegionActions from '../../appRedux/reducers/MasterData/RegionRedux'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * getMasterRegion (api, action) {
  const { data } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.getMasterRegion, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetRegion response.data', response.data)
    yield put(RegionActions.masterRegionSuccess(response.data.results, response.data.meta))
  } else {
    yield put(RegionActions.masterRegionFailure())
  }
}

export function * getDetailMasterRegion (api, action) {
  const { data, id } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.getMasterRegion, data, id)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetRegion response.data', response.data)
    yield put(RegionActions.masterRegionDetailSuccess(response.data.result))
  } else {
    yield put(RegionActions.masterRegionDetailFailure())
  }
}

export function * createMasterRegion (api, action) {
  const { data, util: { info, callback } } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.postMasterRegion, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetRegion response.data', response.data)
    info.success(response.data.message)
    callback()
    yield put(RegionActions.masterRegionCreateSuccess())
  } else {
    info.error(response.data.message)
    yield put(RegionActions.masterRegionCreateFailure())
  }
}

export function * editMasterRegion (api, action) {
  const { data, util: { info, callback } } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.putMasterRegion, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetRegion response.data', response.data)
    info.success(response.data.message)
    callback()
    yield put(RegionActions.masterRegionEditSuccess())
  } else {
    info.error(response.data.message)
    yield put(RegionActions.masterRegionEditFailure())
  }
}

export function * deleteMasterRegion (api, action) {
  const { data, id, info } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.deleteMasterRegion, data, id)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetRegion response.data', response.data)
    info.success(response.data.message)
    yield put(RegionActions.masterRegionDeleteSuccess())
    yield put(RegionActions.masterRegionRequest())
  } else {
    info.error(response.data.message)
    yield put(RegionActions.masterRegionDeleteFailure())
  }
}
