/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This Organisasi uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, select } from 'redux-saga/effects'
import RoleMenuActions, { RoleMenuSelectors } from '../appRedux/reducers/RoleMenuRedux'
import { notification } from 'antd';
import _ from 'lodash' 
export function * roleMenuRequest (api, action) {
    const { data, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.roleMenuRequest, data)

    // success?
    if (response.ok) {
        var dataresult = response.data.results

        yield put(RoleMenuActions.roleMenuSuccess(dataresult, response.data.meta))
        if (cb) cb(true, response.data.results)
    } else {
        yield put(RoleMenuActions.roleMenuFailure())
        if (cb) cb(false)
    }
}

export function * roleMenuDetailRequest (api, action) {
    const { datadetail, id } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.roleMenuRequest, datadetail, id) 

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
        yield put(RoleMenuActions.roleMenuDetailSuccess(response.data.result))
    } else {
        yield put(RoleMenuActions.roleMenuDetailFailure())
    }
}

export function * roleMenuAddRequest (api, action) {
    const { dataadd, cb } = action
    // get current data from Store
    
    // make the call to the api
    const response = yield call(api.roleMenuAddRequest, dataadd) 

    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Data Role Menu Added',
            placement: 'topRight',
        }); 
 
        yield put(RoleMenuActions.roleMenuAddSuccess(response.data.result))  
        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when creating Data Role Menu, Please try again',
            placement: 'topRight',
        });
        yield put(RoleMenuActions.roleMenuAddFailure())
        if (cb) cb(false)
    }
}

export function * roleMenuEditRequest (api, action) {
    const { dataedit, cb } = action
    // get current data from Store
    // make the call to the api
    const response = yield call(api.roleMenuEditRequest, dataedit) 
  
    // success?
    if (response.ok) {
        let currentData = _.cloneDeep(yield select(RoleMenuSelectors.getPayload))
        currentData = _.keyBy(currentData, 'm_role_menu_id')

        Object.assign(currentData[dataedit.id], dataedit);



        notification.success({
            message: 'Success',
            description: 'Data Role Menu Modified',
            placement: 'topRight',
        });

        console.log('Organisasi Edit response.data', response.data)
        yield put(RoleMenuActions.roleMenuEditSuccess(response.data.result))
        yield put(RoleMenuActions.roleMenuSuccess(_.values(currentData)))

        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when Modifying Data Role Menu, Please try again',
            placement: 'topRight',
        });
        yield put(RoleMenuActions.roleMenuEditFailure())
        if (cb) cb(false)
    }
}

export function * roleMenuDeleteRequest (api, action) {
    const { datadelete, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.roleMenuDeleteRequest, datadelete) 

    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Data Role Menu Deleted',
            placement: 'topRight',
        });

        console.log('OrganisasiDelete response.data', response.data)
        yield put(RoleMenuActions.roleMenuDeleteSuccess(datadelete))
        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when Deleting Data Role Menu, Please try again',
            placement: 'topRight',
        });
        yield put(RoleMenuActions.roleMenuDeleteFailure())
        if (cb) cb(false)
    }
}