import {
  GET_DETAIL_CMO,
  SET_HEADER_WEEK1,
  SET_HEADER_WEEK2,
  SET_HEADER_WEEK3,
  SET_HEADER_WEEK4,
  SET_DISTRIBUTOR_CODE,
  SET_PRODUCT_TONASE_KUBIKASI,
  SET_HEADER_TONASE1,
  SET_HEADER_TONASE2,
  SET_HEADER_TONASE3,
  SET_HEADER_TONASE4,
  SET_HEADER_KUBIKASI1,
  SET_HEADER_KUBIKASI2,
  SET_HEADER_KUBIKASI3,
  SET_HEADER_KUBIKASI4,
  SET_HEADER_KENDARAAN1,
  SET_HEADER_KENDARAAN2,
  SET_HEADER_KENDARAAN3,
  SET_HEADER_KENDARAAN4,
  SET_DISTRIBUTOR_ID,
  SET_SCHEDULE1,
  SET_SCHEDULE2,
  SET_SCHEDULE3,
  SET_SCHEDULE4,
  SET_TOTAL_ERROR,
  RESET,
  RESET_DETAIL
} from "../constants/rpo_form";

const initialState = {
  detail: [],
  distributor_code: '',
  distributor_id: '',
  tempProdukTonaseKubikasi: [],
  total_error: 0,
  header: {
    week1: 0,
    week2: 0,
    week3: 0,
    week4: 0,
    tonase1: 0,
    tonase2: 0,
    tonase3: 0,
    tonase4: 0,
    kubikasi1: 0,
    kubikasi2: 0,
    kubikasi3: 0,
    kubikasi4: 0,
    kendaraan1: [{
      id_kendaraan: '',
      nama: '( Empty )',
      kubikasi: 0,
      tonase: 0
    }],
    kendaraan2: [{
      id_kendaraan: '',
      nama: '( Empty )',
      kubikasi: 0,
      tonase: 0
    }],
    kendaraan3: [{
      id_kendaraan: '',
      nama: '( Empty )',
      kubikasi: 0,
      tonase: 0
    }],
    kendaraan4: [{
      id_kendaraan: '',
      nama: '( Empty )',
      kubikasi: 0,
      tonase: 0
    }],
  },
  schedule1: false,
  schedule2: false,
  schedule3: false,
  schedule4: false,
};

const rpo_form = function (state = initialState, action) {
  switch (action.type) {
    case GET_DETAIL_CMO: {
      return {
        ...state,
        detail: [...action.payload]
      };
    }
    case SET_HEADER_WEEK1: {
      return {
        ...state,
        header: {
          ...state.header,
          week1: action.payload
        }
      };
    }
    case SET_HEADER_WEEK2: {
      return {
        ...state,
        header: {
          ...state.header,
          week2: action.payload
        }
      };
    }
    case SET_HEADER_WEEK3: {
      return {
        ...state,
        header: {
          ...state.header,
          week3: action.payload
        }
      };
    }
    case SET_HEADER_WEEK4: {
      return {
        ...state,
        header: {
          ...state.header,
          week4: action.payload
        }
      };
    }
    case SET_HEADER_TONASE1: {
      return {
        ...state,
        header: {
          ...state.header,
          tonase1: action.payload
        }
      };
    }
    case SET_HEADER_TONASE2: {
      return {
        ...state,
        header: {
          ...state.header,
          tonase2: action.payload
        }
      };
    }
    case SET_HEADER_TONASE3: {
      return {
        ...state,
        header: {
          ...state.header,
          tonase3: action.payload
        }
      };
    }
    case SET_HEADER_TONASE4: {
      return {
        ...state,
        header: {
          ...state.header,
          tonase4: action.payload
        }
      };
    }
    case SET_HEADER_KUBIKASI1: {
      return {
        ...state,
        header: {
          ...state.header,
          kubikasi1: action.payload
        }
      };
    }
    case SET_HEADER_KUBIKASI2: {
      return {
        ...state,
        header: {
          ...state.header,
          kubikasi2: action.payload
        }
      };
    }
    case SET_HEADER_KUBIKASI3: {
      return {
        ...state,
        header: {
          ...state.header,
          kubikasi3: action.payload
        }
      };
    }
    case SET_HEADER_KUBIKASI4: {
      return {
        ...state,
        header: {
          ...state.header,
          kubikasi4: action.payload
        }
      };
    }
    case SET_HEADER_KENDARAAN1: {
      return {
        ...state,
        header: {
          ...state.header,
          kendaraan1: action.payload
        }
      };
    }
    case SET_HEADER_KENDARAAN2: {
      return {
        ...state,
        header: {
          ...state.header,
          kendaraan2: action.payload
        }
      };
    }
    case SET_HEADER_KENDARAAN3: {
      return {
        ...state,
        header: {
          ...state.header,
          kendaraan3: action.payload
        }
      };
    }
    case SET_HEADER_KENDARAAN4: {
      return {
        ...state,
        header: {
          ...state.header,
          kendaraan4: action.payload
        }
      };
    }
    case SET_DISTRIBUTOR_CODE: {
      return {
        ...state,
        distributor_code: action.payload
      };
    }
    case SET_DISTRIBUTOR_ID: {
      return {
        ...state,
        distributor_id: action.payload
      };
    }
    case SET_PRODUCT_TONASE_KUBIKASI: {
      return {
        ...state,
        tempProdukTonaseKubikasi: [...state.tempProdukTonaseKubikasi, action.payload]
      };
    }
    case RESET: {
      return {
        ...state,
        header: { ...initialState.header },
        tempProdukTonaseKubikasi: []
      };
    }
    case SET_SCHEDULE1: {
      return {
        ...state,
        schedule1: action.payload,
      };
    }
    case SET_SCHEDULE2: {
      return {
        ...state,
        schedule2: action.payload,
      };
    }
    case SET_SCHEDULE3: {
      return {
        ...state,
        schedule3: action.payload,
      };
    }
    case SET_SCHEDULE4: {
      return {
        ...state,
        schedule4: action.payload,
      };
    }
    case SET_TOTAL_ERROR: {
      return {
        ...state,
        total_error: action.payload,
      };
    }
    case RESET: {
      return {
        ...state,
        header: { ...initialState.header },
        tempProdukTonaseKubikasi: []
      };
    }
    case RESET_DETAIL: {
      return {
        ...state,
        header: { ...initialState.header },
        tempProdukTonaseKubikasi: [],
        detail: []
      };
    }
    default: {
      return state;
    }
  }
};

export default rpo_form;
