import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  biddingInformationRequest: ['data'],
  biddingInformationSuccess: ['payload'],
  biddingInformationFailure: null,

  biddingInformationProcessRequest: ['dataprocess', 'cb'],
  biddingInformationProcessSuccess: ['payloadprocess'],
  biddingInformationProcessFailure: null,

})

export const BiddingInformationTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  fetching: null,
  payload: [],
  error: null,

  dataprocess: null,
  fetchingprocess: null,
  payloadprocess: [],
  errorprocess: null
})

/* ------------- Selectors ------------- */

export const BiddingInformationSelectors = {
  getData: state => state.data
}

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
  state.merge({ fetching: true, data })

// successful api lookup
export const success = (state, action) => {
  const { payload } = action
  return state.merge({ fetching: false, error: null, payload })
}

// Something went wrong somewhere.
export const failure = state =>
  state.merge({ fetching: false, error: true })

////////////////////////////////////////

export const requestprocess = (state, { dataprocess }) =>
  state.merge({ fetchingprocess: true, dataprocess })

// successful api lookup
export const successprocess = (state, action) => {
  const { payloadprocess } = action
  return state.merge({ fetchingprocess: false, payloadprocess, errorprocess: null })
}

// Something went wrong somewhere.
export const failureprocess = state =>
  state.merge({ fetchingprocess: false, errorprocess: true })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BIDDING_INFORMATION_REQUEST]: request,
  [Types.BIDDING_INFORMATION_SUCCESS]: success,
  [Types.BIDDING_INFORMATION_FAILURE]: failure,

  [Types.BIDDING_INFORMATION_PROCESS_REQUEST]: requestprocess,
  [Types.BIDDING_INFORMATION_PROCESS_SUCCESS]: successprocess,
  [Types.BIDDING_INFORMATION_PROCESS_FAILURE]: failureprocess,
  
})
