/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This biddingInformation uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put } from 'redux-saga/effects'
import BiddingInformationActions from '../appRedux/reducers/BiddingInformationRedux'
import { notification } from 'antd'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * biddingInformationRequest (api, action) {
  const { data } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.biddingInformationRequest, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    console.log('biddingInformation response.data', response.data)
    yield put(BiddingInformationActions.biddingInformationSuccess(response.data.results))
  } else {
    yield put(BiddingInformationActions.biddingInformationFailure())
  }
}

export function * biddingInformationProcessRequest (api, action) {
  const { dataprocess , cb } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.biddingInformationProcessRequest, dataprocess)
  console.log('biddingInformationProcessRequest response', JSON.stringify(response))
  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    console.log('biddingInformationProcessRequest response.data', response.data)
    notification.success({
      message: `Success`,
      description: 'Bidding Quotation Succesfully',
      placement: 'topRight',
    })
    yield put(BiddingInformationActions.biddingInformationProcessSuccess(response.data))
    if (cb) cb()
  } else {
    notification.error({
      message: `Failed`,
      description: 'Bidding Quotation Failed',
      placement: 'topRight',
    })
    yield put(BiddingInformationActions.biddingInformationProcessFailure())
  }
}
