const TARGET = process.env.REACT_APP_URL || "esalesdev.enesis.com";
const PORT = process.env.REACT_APP_PORT || 1337;

let urlElements = window.location.href.split('/')
console.log('urlElements[0]', urlElements[0])
let URL = null;
// if (TARGET === 'localhost') {
    if (urlElements[0] === 'https:') {
        URL = `${urlElements[0]}//${TARGET}/api/`;
     } else {
        URL = `${urlElements[0]}//${TARGET}:${PORT}/`;
     }
// } else {
//     URL = `http://esalesdev.enesis.com:1337/`;
// }
// URL = `http://esalesdev.enesis.com:1337/`;
console.log('URL', URL)

export { URL };