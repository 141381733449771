import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  termOfPaymentRequest: ['data'],
  termOfPaymentSuccess: ['payload', 'pagination'],
  termOfPaymentFailure: null,

  termOfPaymentCalcRequest: ['datacalc'],
  termOfPaymentCalcSuccess: ['calcresult'],
  termOfPaymentCalcFailure: null,

  termOfPaymentResetCalc: null,

  termOfPaymentTopRequest: ['datatop', 'cb'],
  termOfPaymentTopSuccess: ['payloadtop'],
  termOfPaymentTopFailure: null,
})

export const TermOfPaymentTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  fetching: null,
  payload: [],
  error: null,
  
  datacalc: null,
  fetchingcalc: null,
  calcresult: 0,
  errorcalc: null,

  datatop: null,
  fetchingtop: null,
  payloadtop: null,
  errortop: null,
})

/* ------------- Selectors ------------- */

export const TermOfPaymentSelectors = {
  getPayload: state => state.termofpayment.payload
}

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
  state.merge({ fetching: true, data })

// successful api lookup
export const success = (state, action) => {
  const { payload, pagination } = action
  return state.merge({ fetching: false, error: null, payload, pagination: (pagination) ? pagination : state.pagination })
}

// Something went wrong somewhere.
export const failure = state =>
  state.merge({ fetching: false, error: true })


//CALC

export const requestcalc = (state, { datacalc }) =>
  state.merge({ fetchingcalc: true, datacalc, calcresult: 0 })

// successful api lookup
export const successcalc = (state, action) => {
  const { calcresult } = action
  return state.merge({ fetchingcalc: false, errorcalc: null, calcresult })
}

// Something went wrong somewhere.
export const failurecalc = state =>
  state.merge({ fetchingcalc: false, errorcalc: true, calcresult: 0 })


export const resetcalc = state =>
  state.merge({ fetchingcalc: false, errorcalc: false, calcresult: 0 })


//TOP
// request the data from an api
export const requesttop = (state, { datatop }) =>
  state.merge({ fetchingtop: true, datatop })

// successful api lookup
export const successtop = (state, action) => {
  const { payloadtop } = action
  return state.merge({ fetchingtop: false, errortop: null, payloadtop })
}

// Something went wrong somewhere.
export const failuretop = state =>
  state.merge({ fetchingtop: false, errortop: true })
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.TERM_OF_PAYMENT_REQUEST]: request,
  [Types.TERM_OF_PAYMENT_SUCCESS]: success,
  [Types.TERM_OF_PAYMENT_FAILURE]: failure,

  [Types.TERM_OF_PAYMENT_CALC_REQUEST]: requestcalc,
  [Types.TERM_OF_PAYMENT_CALC_SUCCESS]: successcalc,
  [Types.TERM_OF_PAYMENT_CALC_FAILURE]: failurecalc,

  [Types.TERM_OF_PAYMENT_RESET_CALC]: resetcalc,

  [Types.TERM_OF_PAYMENT_TOP_REQUEST]: requesttop,
  [Types.TERM_OF_PAYMENT_TOP_SUCCESS]: successtop,
  [Types.TERM_OF_PAYMENT_TOP_FAILURE]: failuretop,
})
