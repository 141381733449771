import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    proposalRequest: ['data'],
    proposalSuccess: ['payload', 'pagination'],
    proposalFailure: null,

    proposalCrRequest: ['data'],
    proposalCrSuccess: ['payload', 'pagination'],
    proposalCrFailure: null,
    
    proposalCrDetailRequest: ['id', 'datadetail', 'cb'],
    proposalCrDetailSuccess: ['payloaddetail'],
    proposalCrDetailFailure: null,

    proposalCrProcessRequest: ['datacrproc', 'cb'],
    proposalCrProcessSuccess: ['payloadcrproc'],
    proposalCrProcessFailure: null,

    proposalDetailRequest: ['id', 'datadetail', 'cb'],
    proposalDetailSuccess: ['payloaddetail'],
    proposalDetailFailure: null,

    proposalCreateRequest: ['datacreate', 'cb'],
    proposalCreateSuccess: ['payloadcreate'],
    proposalCreateFailure: null,

    proposalDeleteFileRequest: ['datadeletefile', 'cb'],
    proposalDeleteFileSuccess: ['payloaddeletefile'],
    proposalDeleteFileFailure: null,

    proposalKirimRequest: ['datakirim', 'cb'],
    proposalKirimSuccess: ['payloadkirim'],
    proposalKirimFailure: null,

    proposalCopyRequest: ['datacopy', 'act', 'cb'],
    proposalCopySuccess: ['payloadcopy'],
    proposalCopyFailure: null,

    proposalDeleteRequest: ['datadelete', 'cb'],
    proposalDeleteSuccess: ['payloaddelete'],
    proposalDeleteFailure: null,

    proposalApRjRequest: ['dataaprj', 'cb'],
    proposalApRjSuccess: ['payloadaprj'],
    proposalApRjFailure: null,

    proposalDasboardRequest: ['datadashboard', 'cb'],
    proposalDasboardSuccess: ['payloaddashboard'],
    proposalDasboardFailure: null,

    proposalCancelRequest: ['datacancel', 'cb'],
    proposalCancelSuccess: ['payloadcancel'],
    proposalCancelFailure: null,

    proposalReversalRequest: ['datareversal', 'cb'],
    proposalReversalSuccess: ['payloadreversal'],
    proposalReversalFailure: null,

    proposalReset: null,
})

export const ProposalTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: null,
    fetching: null,
    payload: [],
    error: null,

    pagination: null,

    id: null,
    datadetail: null,
    fetchingdetail: null,
    payloaddetail: {},
    errordetail: null,

    datacreate: null,
    fetchingcreate: null,
    payloadcreate: {},
    errorcreate: null,

    datadeletefile: null,
    fetchingdeletefile: null,
    payloaddeletefile: {},
    errordeletefile: null,

    datakirim: null,
    fetchingkirim: null,
    payloadkirim: {},
    errorkirim: null,

    datacopy: null,
    fetchingcopy: null,
    payloadcopy: {},
    errorcopy: null,

    datadelete: null,
    fetchingdelete: null,
    payloaddelete: {},
    errordelete: null,
    
    dataaprj: null,
    fetchingaprj: null,
    payloadaprj: {},
    erroraprj: null,

    datacancel: null,
    fetchingcancel: null,
    payloadcancel: {},
    errorcancel: null,

    datareversal: null,
    fetchingreversal: null,
    payloadreversal: {},
    errorreversal: null,

    datadashboard: null,
    fetchingdashboard: null,
    payloaddashboard: [],
    errordashboard: null,

    datacrproc: null,
    fetchingcrproc: null,
    payloadcrproc: {},
    errorcrproc: null,
})

/* ------------- Selectors ------------- */

export const ProposalSelectors = {
    getData: state => state.proposal.data,
    getPayload: state => state.proposal.payload,
    getPayloadDetail: state => state.proposal.payloaddetail,

}

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
    state.merge({ fetching: true, data })

// successful api lookup
export const success = (state, action) => {
    const { payload, pagination } = action
    return state.merge({ fetching: false, error: null, payload, pagination: (pagination) ? pagination : state.pagination })
}

// Something went wrong somewhere.
export const failure = state =>
    state.merge({ fetching: false, error: true })
    

// request the data from an api
export const requestdetail = (state, { id, datadetail }) =>
    state.merge({ fetchingdetail: true, datadetail, id, payloaddetail : {} })

// successful api lookup
export const successdetail = (state, action) => {
    const { payloaddetail } = action
    return state.merge({ fetchingdetail: false, errordetail: null, payloaddetail })
}

// Something went wrong somewhere.
export const failuredetail = state =>
    state.merge({ fetchingdetail: false, errordetail: true, payloaddetail: {} })


// request the data from an api
export const requestcreate = (state, { datacreate }) =>
    state.merge({ fetchingcreate: true, datacreate, payloadcreate : {} })

// successful api lookup
export const successcreate = (state, action) => {
    const { payloadcreate } = action
    return state.merge({ fetchingcreate: false, errorcreate: null, payloadcreate })
}

// Something went wrong somewhere.
export const failurecreate = state =>
    state.merge({ fetchingcreate: false, errorcreate: true, payloadcreate: {} })


//DELETE
// request the data from an api
export const requestdeletefile = (state, { datadeletefile }) =>
    state.merge({ fetchingdeletefile: true, datadeletefile, payloaddeletefile : {} })

// successful api lookup
export const successdeletefile = (state, action) => {
    const { payloaddeletefile } = action
    return state.merge({ fetchingdeletefile: false, errordeletefile: null, payloaddeletefile })
}

// Something went wrong somewhere.
export const failuredeletefile = state =>
    state.merge({ fetchingdeletefile: false, errordeletefile: true, payloaddeletefile: {} })
/* ------------- Hookup Reducers To Types ------------- */


// request the data from an api
export const requestkirim = (state, { datakirim }) =>
    state.merge({ fetchingkirim: true, datakirim, payloadkirim : {} })

// successful api lookup
export const successkirim = (state, action) => {
    const { payloadkirim } = action
    return state.merge({ fetchingkirim: false, errorkirim: null, payloadkirim })
}

// Something went wrong somewhere.
export const failurekirim = state =>
    state.merge({ fetchingkirim: false, errorkirim: true, payloadkirim: {} })



// request the data from an api
export const requestcopy = (state, { datacopy }) =>
    state.merge({ fetchingcopy: true, datacopy, payloadcopy : {} })

// successful api lookup
export const successcopy = (state, action) => {
    const { payloadcopy } = action
    return state.merge({ fetchingcopy: false, errorcopy: null, payloadcopy })
}

// Something went wrong somewhere.
export const failurecopy = state =>
    state.merge({ fetchingcopy: false, errorcopy: true, payloadcopy: {} })


// request the data from an api
export const requestdelete = (state, { datadelete }) =>
    state.merge({ fetchingdelete: true, datadelete, payloaddelete : {} })

// successful api lookup
export const successdelete = (state, action) => {
    const { payloaddelete } = action
    const deleteid = payloaddelete.proposal_id
    const newPayload = [...state.payload.filter(x => x.proposal_id !== deleteid)]
    return state.merge({ fetchingdelete: false, errordelete: null, payloaddelete, payload: newPayload })
}

// Something went wrong somewhere.
export const failuredelete = state =>
    state.merge({ fetchingdelete: false, errordelete: true, payloaddelete: {} })


// request the data from an api
export const requestaprj = (state, { dataaprj }) =>
    state.merge({ fetchingaprj: true, dataaprj, payloadaprj : {} })

// successful api lookup
export const successaprj = (state, action) => {
    const { payloadaprj } = action 
    return state.merge({ fetchingaprj: false, erroraprj: null, payloadaprj })
}

// Something went wrong somewhere.
export const failureaprj = state =>
    state.merge({ fetchingaprj: false, erroraprj: true, payloadaprj: {} })


// request the data from an api
export const requestcancel = (state, { datacancel }) =>
    state.merge({ fetchingcancel: true, datacancel, payloadcancel : {} })

// successful api lookup
export const successcancel = (state, action) => {
    const { payloadcancel } = action 
    return state.merge({ fetchingcancel: false, errorcancel: null, payloadcancel })
}

// Something went wrong somewhere.
export const failurecancel = state =>
    state.merge({ fetchingcancel: false, errorcancel: true, payloadcancel: {} })

    
export const requestdashboard = (state, { datadashboard }) =>
    state.merge({ fetchingdashboard: true, datadashboard, payloaddashboard : [] })

// successful api lookup
export const successdashboard = (state, action) => {
    const { payloaddashboard } = action 
    return state.merge({ fetchingdashboard: false, errordashboard: null, payloaddashboard })
}

// Something went wrong somewhere.
export const failuredashboard = state =>
    state.merge({ fetchingdashboard: false, errordashboard: true, payloaddashboard: [] })

export const requestreversal = (state, { datareversal }) =>
    state.merge({ fetchingreversal: true, datareversal, payloadreversal : {} })

// successful api lookup
export const successreversal = (state, action) => {
    const { payloadreversal } = action 
    return state.merge({ fetchingreversal: false, errorreversal: null, payloadreversal })
}

// Something went wrong somewhere.
export const failurereversal = state =>
    state.merge({ fetchingreversal: false, errorreversal: true, payloadreversal: {} })


export const requestcrproc = (state, { datacrproc }) =>
    state.merge({ fetchingcrproc: true, datacrproc, payloadcrproc : {} })

// successful api lookup
export const successcrproc = (state, action) => {
    const { payloadcrproc } = action 
    return state.merge({ fetchingcrproc: false, errorcrproc: null, payloadcrproc })
}

// Something went wrong somewhere.
export const failurecrproc = state =>
    state.merge({ fetchingcrproc: false, errorcrproc: true, payloadcrproc: {} })

export const reset = state =>
    state.merge(INITIAL_STATE)

export const reducer = createReducer(INITIAL_STATE, {
    [Types.PROPOSAL_REQUEST]: request,
    [Types.PROPOSAL_SUCCESS]: success,
    [Types.PROPOSAL_FAILURE]: failure,

    [Types.PROPOSAL_CR_REQUEST]: request,
    [Types.PROPOSAL_CR_SUCCESS]: success,
    [Types.PROPOSAL_CR_FAILURE]: failure,

    [Types.PROPOSAL_DETAIL_REQUEST]: requestdetail,
    [Types.PROPOSAL_DETAIL_SUCCESS]: successdetail,
    [Types.PROPOSAL_DETAIL_FAILURE]: failuredetail,

    [Types.PROPOSAL_CR_DETAIL_REQUEST]: requestdetail,
    [Types.PROPOSAL_CR_DETAIL_SUCCESS]: successdetail,
    [Types.PROPOSAL_CR_DETAIL_FAILURE]: failuredetail,

    [Types.PROPOSAL_CREATE_REQUEST]: requestcreate,
    [Types.PROPOSAL_CREATE_SUCCESS]: successcreate,
    [Types.PROPOSAL_CREATE_FAILURE]: failurecreate,
    
    [Types.PROPOSAL_DELETE_FILE_REQUEST]: requestdeletefile,
    [Types.PROPOSAL_DELETE_FILE_SUCCESS]: successdeletefile,
    [Types.PROPOSAL_DELETE_FILE_FAILURE]: failuredeletefile,
    
    [Types.PROPOSAL_KIRIM_REQUEST]: requestkirim,
    [Types.PROPOSAL_KIRIM_SUCCESS]: successkirim,
    [Types.PROPOSAL_KIRIM_FAILURE]: failurekirim,

    [Types.PROPOSAL_COPY_REQUEST]: requestcopy,
    [Types.PROPOSAL_COPY_SUCCESS]: successcopy,
    [Types.PROPOSAL_COPY_FAILURE]: failurecopy,
    
    [Types.PROPOSAL_DELETE_REQUEST]: requestdelete,
    [Types.PROPOSAL_DELETE_SUCCESS]: successdelete,
    [Types.PROPOSAL_DELETE_FAILURE]: failuredelete,
    
    [Types.PROPOSAL_AP_RJ_REQUEST]: requestaprj,
    [Types.PROPOSAL_AP_RJ_SUCCESS]: successaprj,
    [Types.PROPOSAL_AP_RJ_FAILURE]: failureaprj,

    [Types.PROPOSAL_CANCEL_REQUEST]: requestcancel,
    [Types.PROPOSAL_CANCEL_SUCCESS]: successcancel,
    [Types.PROPOSAL_CANCEL_FAILURE]: failurecancel,

    [Types.PROPOSAL_REVERSAL_REQUEST]: requestreversal,
    [Types.PROPOSAL_REVERSAL_SUCCESS]: successreversal,
    [Types.PROPOSAL_REVERSAL_FAILURE]: failurereversal,

    [Types.PROPOSAL_CR_PROCESS_REQUEST]: requestcrproc,
    [Types.PROPOSAL_CR_PROCESS_SUCCESS]: successcrproc,
    [Types.PROPOSAL_CR_PROCESS_FAILURE]: failurecrproc,

    [Types.PROPOSAL_DASBOARD_REQUEST]: requestdashboard,
    [Types.PROPOSAL_DASBOARD_SUCCESS]: successdashboard,
    [Types.PROPOSAL_DASBOARD_FAILURE]: failuredashboard,

    [Types.PROPOSAL_RESET]: reset,


})
