import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    userOrganisasiRequest: ['data', 'cb'],
    userOrganisasiSuccess: ['payload', 'pagination'],
    userOrganisasiFailure: null,

    userOrganisasiDetailRequest: ['datadetail', 'id'],
    userOrganisasiDetailSuccess: ['payloaddetail'],
    userOrganisasiDetailFailure: null,

    userOrganisasiAddRequest: ['dataadd', 'cb'],
    userOrganisasiAddSuccess: ['payloadadd'],
    userOrganisasiAddFailure: null,

    userOrganisasiDeleteRequest: ['datadelete', 'cb'],
    userOrganisasiDeleteSuccess: ['payloaddelete'],
    userOrganisasiDeleteFailure: null,

    userOrganisasiEditRequest: ['dataedit', 'cb'],
    userOrganisasiEditSuccess: ['payloadedit'],
    userOrganisasiEditFailure: null,
})

export const UserOrganisasiTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: null,
    fetching: null,
    payload: [],
    error: null,

    datadetail: null,
    fetchingdetail: null,
    payloaddetail: null,
    errordetail: null,

    dataadd: null,
    fetchingadd: null,
    payloadadd: null,
    erroradd: null,

    dataedit: null,
    fetchingedit: null,
    payloadedit: null,
    erroredit: null,

    datadelete: null,
    fetchingdelete: null,
    payloaddelete: null,
    errordelete: null,
}) 

/* ------------- Selectors ------------- */

export const UserOrganisasiSelectors = {
    getData: state => state.userorganisasi.data,
    getPayload: state => state.userorganisasi.payload,
}

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
    state.merge({ fetching: true, data })

// successful api lookup
export const success = (state, action) => {
    const { payload, pagination } = action
    return state.merge({ fetching: false, error: null, payload, pagination: (pagination) ? pagination : state.pagination })
}

// Something went wrong somewhere.
export const failure = state =>
    state.merge({ fetching: false, error: true })

  
//DETAIL
export const requestdetail = (state, { datadetail }) =>
    state.merge({ fetchingdetail: true, datadetail, payloaddetail: null })

// successful api lookup
export const successdetail = (state, action) => {
    const { payloaddetail } = action
    return state.merge({ fetchingdetail: false, errordetail: null, payloaddetail })
}

// Something went wrong somewhere.
export const failuredetail = state =>
    state.merge({ fetchingdetail: false, errordetail: true, payloaddetail: null })


//ADD
export const requestadd = (state, { dataadd }) =>
    state.merge({ fetchingadd: true, dataadd, payloadadd: null })

// successful api lookup
export const successadd = (state, action) => {
    const { payloadadd } = action
    return state.merge({ fetchingadd: false, erroradd: null, payloadadd, payload:[...state.payload, payloadadd] })
}

// Something went wrong somewhere.
export const failureadd = state =>
    state.merge({ fetchingadd: false, erroradd: true, payloadadd: null })


//EDIT
export const requestedit = (state, { dataedit }) =>
    state.merge({ fetchingedit: true, dataedit, payloadedit: null })

// successful api lookup
export const successedit = (state, action) => {
    const { payloadedit } = action
    return state.merge({ fetchingedit: false, erroredit: null, payloadedit/*, payload:[...state.payload, payloadedit]*/ })
}

// Something went wrong somewhere.
export const failureedit = state =>
    state.merge({ fetchingedit: false, erroredit: true, payloadedit: null })


//DELETE
export const requestdelete = (state, { datadelete }) =>
    state.merge({ fetchingdelete: true, datadelete, payloaddelete: null })

// successful api lookup
export const successdelete = (state, action) => {
    const { payloaddelete } = action
    return state.merge({ fetchingdelete: false, errordelete: null, payloaddelete, payload:[...state.payload.filter( x => x.m_user_organisasi_id !== payloaddelete )] })
}

// Something went wrong somewhere.
export const failuredelete = state =>
    state.merge({ fetchingdelete: false, errordelete: true, payloaddelete: null })  

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.USER_ORGANISASI_REQUEST]: request,
    [Types.USER_ORGANISASI_SUCCESS]: success,
    [Types.USER_ORGANISASI_FAILURE]: failure,

    [Types.USER_ORGANISASI_DETAIL_REQUEST]: requestdetail,
    [Types.USER_ORGANISASI_DETAIL_SUCCESS]: successdetail,
    [Types.USER_ORGANISASI_DETAIL_FAILURE]: failuredetail,

    [Types.USER_ORGANISASI_ADD_REQUEST]: requestadd,
    [Types.USER_ORGANISASI_ADD_SUCCESS]: successadd,
    [Types.USER_ORGANISASI_ADD_FAILURE]: failureadd,

    [Types.USER_ORGANISASI_EDIT_REQUEST]: requestedit,
    [Types.USER_ORGANISASI_EDIT_SUCCESS]: successedit,
    [Types.USER_ORGANISASI_EDIT_FAILURE]: failureedit,

    [Types.USER_ORGANISASI_DELETE_REQUEST]: requestdelete,
    [Types.USER_ORGANISASI_DELETE_SUCCESS]: successdelete,
    [Types.USER_ORGANISASI_DELETE_FAILURE]: failuredelete,
})
