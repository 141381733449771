import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Popover, Modal } from 'antd';
import { userSignOut } from 'appRedux/actions/Auth';

const { confirm } = Modal;

const UserInfo = () => {

    const dispatch = useDispatch();
    const m_user_id = useSelector(({ auth }) => {
        if (auth.authUser) {
            return auth.authUser.m_user_id;
        }
        return '';
    });

    const signOut = () => {
        confirm({
            title: 'Are you sure to Logout from this app?',
            onOk() {
                dispatch(userSignOut({ m_user_id }))
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const userMenuOptions = (
        <ul className="gx-user-popover">
            <li>My Account</li> 
            <li onClick={() => signOut()}>Keluar
            </li>
        </ul >
    );

    return (
        <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
            trigger="click">
            <Avatar src='https://via.placeholder.com/150x150'
                className="gx-avatar gx-pointer" alt="" />
        </Popover>
    )
};

export default UserInfo;
