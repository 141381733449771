import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  loadingOverlaySetLoading: ['isloading'],
  loadingOverlaySet: ['isloading', 'text'],
  loadingOverlaySetText: ['text'],
})

export const LoadingOverlayTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isloading: false,
  text: 'Harap Tunggu Beberapa Saat ...'
})

/* ------------- Selectors ------------- */

export const LoadingOverlaySelectors = {
  isLoading: state => state.loadingoverlay.isloading,
  getText: state => state.loadingoverlay.text
}

/* ------------- Reducers ------------- */

export const setloading = (state, { isloading }) =>
  state.merge({ isloading })

export const setoverlayandtext = (state, { isloading, text }) =>
  state.merge({ isloading, text })

// successful api lookup
export const settext = (state, action) => {
  const { text } = action
  return state.merge({ text })
}
 
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOADING_OVERLAY_SET_LOADING]: setloading,
  [Types.LOADING_OVERLAY_SET]: setoverlayandtext,
  [Types.LOADING_OVERLAY_SET_TEXT]: settext, 
})
