/* eslint-disable no-undef */
import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    INIT_URL,
    SIGNOUT_USER_SUCCESS,
    USER_DATA,
    USER_TOKEN_SET,
    GET_MENUS
} from '../../constants/ActionTypes';
import axios from 'util/Api'
import $ from 'jquery';
import { URL } from '../../util/URL';

import Cookies from 'universal-cookie'

const cookieOpt = { 
    path: '/' ,
    domain: '.enesis.com', // (string): domain for the cookie (sub.domain.com or .allsubdomains.com)
    secure: false, // (boolean): Is only accessible through HTTPS? 
    sameSite: false
}

export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};

export const userSignUp = (values) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });


    }
};

export const userSignIn = ({ email, password }) => {
    const cookies = new Cookies();
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('login', {
            username: email,
            password: password,
        }
        ).then(({ data }) => {
            console.log('userSignIn: ', data);
            const response = data.data;
            if (response.token) {
                console.log('TOOOOKEN', response.token);
                localStorage.setItem('token', JSON.stringify(response.token));
                // axios.defaults.headers.common['Authorization'] = "Bearer " + response.token;
                console.log(response);
                // let userData = {
                //     m_user_id: response.m_user_id,
                //     r_organisasi_id: response.r_organisasi_id,
                //     nama: response.nama,
                //     email: response.email,
                //     image: response.image,
                //     role_default_id: response.role_default_id,
                //     roles: response.roles,
                //     profile: response.profile
                // }

                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: USER_DATA, payload: response }); //userData

                dispatch({ type: GET_MENUS, payload: response.menus });
                localStorage.setItem('menus', JSON.stringify(response.menus));
                // dispatch(getMenuByRoleID(response.role_default_id, response.m_user_id));

                //setCookie User
                cookies.set('esalesToken', response.token, cookieOpt);

                localStorage.setItem('user', JSON.stringify(response)); //userData
                dispatch({ type: USER_TOKEN_SET, payload: response.token }); //set token
            } else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    }
};

export const getUser = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('auth/me',
        ).then(({ data }) => {
            if (data.result) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: USER_DATA, payload: data.user });
            } else {
                dispatch({ type: FETCH_ERROR, payload: data.error });
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            console.log('Error****:', error.message);
        });
    }
};


export const userSignOut = (data = {}) => {
    console.log(data);
    const cookies = new Cookies(); 
    return (dispatch) => {
    // dispatch({ type: FETCH_START });
        $.ajax({
            url: `${URL}logout`,
            method: 'post',
            data,
        }).then(({ data }) => {
            //remove login cookies
            cookies.remove('esalesToken',cookieOpt)
            console.log('COOKIE REMOVED')
            if (data) {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('menus');
                localStorage.clear(); //tambahan untuk issue filter terbawa
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: SIGNOUT_USER_SUCCESS });

                delete axios.defaults.headers.common['Authorization'];
            } else {
                dispatch({ type: FETCH_ERROR, payload: data.error });
            }
        }).catch(function (error) {
            console.log(error);
            dispatch({ type: FETCH_ERROR, payload: error.message });
            console.log('Error****:', error);
        });
    }
};

// fungsi getMenuByRoleID di hilangkan saja, get menu sudah ada di respon login sukses dan set ke localstorage di fungsi login tersebut
// mendpatkan menu secara terpisah dengan action yg berbeda menyebabkan user error re-route ke menu pertama mereka

// export const getMenuByRoleID = (m_role_id = '', m_user_id = '') => {
//   return (dispatch) => {
//     axios.get(`menu/roles?m_role_id=${m_role_id}&m_user_id=${m_user_id}`,
//     ).then(({ data }) => {
//       if (data.results) {
//         console.log(data.results);
//         dispatch({ type: GET_MENUS, payload: data.results });

//         localStorage.setItem("menus", JSON.stringify(data.results));
//       } else {
//         dispatch({ type: FETCH_ERROR, payload: data.error });
//       }
//     }).catch(function (error) {
//       dispatch({ type: FETCH_ERROR, payload: error.message });
//       console.log("Error****:", error.message);
//     });
//   }
// }
