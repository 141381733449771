/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This shipment uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, select } from 'redux-saga/effects'
import CreateShipmentActions from '../appRedux/reducers/CreateShipmentRedux'
import LoadingOverlayActions from '../appRedux/reducers/LoadingOverlayRedux'
import ShipmentActions, { ShipmentSelectors } from '../appRedux/reducers/ShipmentRedux'
import _ from 'lodash'
import { notification } from 'antd'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * createShipmentRequest (api, action) {
  const { data, cb } = action
  // yield put(LoadingOverlayActions.loadingOverlaySetLoading(true))
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.createShipmentRequest, data)
  console.log('createShipmentRequest response', response)
  // success?
  if (response.ok) {

    if (response.data.error) {
      yield put(CreateShipmentActions.createShipmentFailure())
      notification.error({
        message: `Failed`,
        description: response.data.message,
        placement: 'topRight',
      })
      if (cb) cb()
      return
    }

    let currentData = _.cloneDeep(yield select(ShipmentSelectors.getPayload))
 
    console.log('createShipmentRequest OK', JSON.stringify(response.data.result))
 
    const combineData = { ..._.keyBy(currentData, 'cmo_id'), ..._.keyBy([response.data.result], 'cmo_id') }

    console.log('combineData', combineData)
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api. 

    notification.success({
      message: `Success`,
      description: 'Shipment Created Succesfully',
      placement: 'topRight',
    })
  yield put(CreateShipmentActions.createShipmentSuccess(_.values(combineData)))
  yield put(ShipmentActions.shipmentSuccess(_.values(combineData)))
  if (cb) cb()
  } else {
    notification.error({
      message: `Failed`,
      description: 'Create Shipment Failed',
      placement: 'topRight',
    })
    yield put(CreateShipmentActions.createShipmentFailure())
  }
  // yield put(LoadingOverlayActions.loadingOverlaySetLoading(false))
}
