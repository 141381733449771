import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, Row, Col, Select, notification, Icon } from 'antd';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import './styles.scss';
import CardBox from '../components/CardBox';
import axios from '../util/Api';
import * as actions from '../appRedux/actions';

const FormItem = Form.Item;
const { Option } = Select;

const SignUp = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const token = useSelector(({ auth }) => auth.token);

    const [dropdownState, setStateDropDown] = useState([]);
    const [dropdownTransportZone, setTransportZoneDropDown] = useState([]);
    const [dropdownTitle, setTitleDropDown] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            console.log('values', values);
            if (!err) {
                const { first_name, last_name,
                    title, street, housenumber,
                    district, city, state,
                    transport_zone, telepon,
                    mobile, email1, email2, email3 } = values;

                let data = {
                    first_name,
                    last_name,
                    title,
                    street,
                    housenumber,
                    district,
                    city,
                    state,
                    transport_zone,
                    telepon,
                    mobile,
                    email1,
                    email2,
                    email3
                }

                await axios.post('register', data).then(({ data }) => {
                    console.log('data:', data);
                    if (data.data) {
                        if (data.data.rowsAffected[0] > 0) {
                            notification.open({
                                message: 'Success',
                                description:
                  `terimakasih sudah mendaftar kami akan menkonfirmasi
                  data anda dan akan memberikan laporan ke email anda`,
                                icon: <Icon type="check-circle" style={{ color: 'green' }} />
                            });
                        }
                    } else {
                        console.log('payload: data.error', data.error);
                        dispatch(actions.fetchError('Network Error'));
                    }
                }).catch(function (error) {
                    dispatch(actions.fetchError(error.message));
                    console.log('Error****:', error.message);
                });
                history.push('/cmo');
            }
        });
    };

    const _localAction = {
        dropdownList: async function ({ url, table, order_by, order_type }) {
            let tempData = [];

            let params = {
                table
            }

            if(order_by)
            {
                params.order_by = order_by
            }

            if(order_type)
            {
                params.order_type = order_type
            }

            await axios.get(url, {params}).then(({ data }) => {
                if (data.results) {
                    tempData = data.results;
                } else {
                    dispatch(actions.fetchError('Network Error'));
                }
            }).catch(function (error) {
                dispatch(actions.fetchError(error.message));
            });

            return tempData;
        }
    }

    useEffect(() => {
        if (token !== null) {
            props.history.push('/');
        }

        const transport = _localAction.dropdownList({ url: 'dropdownlist', table: 'r_transport_zone', order_by: 'kode', order_type: 'ASC' });
        const state = _localAction.dropdownList({ url: 'dropdownlist', table: 'r_general_state', order_by: 'kode_region', order_type: 'ASC' });
        const title = _localAction.dropdownList({ url: 'dropdownlist', table: 'r_tittle', order_by: 'kode', order_type: 'ASC' });

        transport.then(data => {
            setTransportZoneDropDown(data);
        });
        state.then(data => {
            setStateDropDown(data);
        });
        title.then(data => {
            setTitleDropDown(data);
        });
    }, []);

    const { getFieldDecorator } = props.form;
    return (
        <div className="form-signup">
            <CardBox>
                <Row type="flex" justify="start">
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <div className="signup-logo-content" style={{ width: '100%', height: '100%' }}>
                            <Row type="flex" justify="start">
                                <Col span={24}>
                                    <Row type="flex" justify="space-around" gutter={[30, 0]}>
                                        <Col span={24}>
                                            <h1 style={{ color: 'white', textAlign: 'center' }}><IntlMessages id="app.userAuth.signUp" /></h1>
                                        </Col>
                                    </Row>
                                    <Row type="flex" justify="space-around" gutter={[30, 0]}>
                                        <Col span={24}>
                                            <img alt="example" src={require('assets/images/slurr.png')} />
                                        </Col>
                                    </Row>
                                    <Row type="flex" justify="space-around" gutter={[30, 0]}>
                                        <Col span={24}>
                                            <p style={{ color: 'white', textAlign: 'center', marginTop: 30 }}>Sign Up General Distributor</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                        <div className="signup-form-content" style={{ padding: '30px' }}>
                            <Row type="flex" justify="end" gutter={[20, 0]} style={{ alignItems: 'center', marginBottom: 20 }}>
                                <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                    <p style={{ color: 'green', textAlign: 'center' }}>Already have an account?</p>
                                </Col>
                                <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                                    <Link to="/signin">
                                        <div className="btn-submit-login">
                                            <Button type="primary" className="gx-mb-0" htmlType="submit" style={{ backgroundColor: '#004926 !important', borderColor: '#004926 !important' }}>
                                                <IntlMessages id="app.userAuth.signIn" />
                                            </Button>
                                        </div>
                                    </Link>
                                </Col>
                            </Row>
                            <Row type="flex" justify="center" gutter={[20, 0]} style={{ alignItems: 'center' }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <h3 style={{ textAlign: 'center' }}>CREATE AN ACCOUNT</h3>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <h1 style={{ fontWeight: 'bolder', textAlign: 'center' }}>Get started right now!</h1>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <p style={{ textAlign: 'center' }}>Create your account. It's only take a minute</p>
                                </Col>
                            </Row>
                            <Row type="flex" justify="center" style={{ marginTop: 20 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form onSubmit={handleSubmit} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                        <Row type="flex" justify="space-between" gutter={[30, 0]}>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <FormItem label="First Name">
                                                    {getFieldDecorator('first_name', {
                                                        rules: [{ required: true, message: 'Please input your first name!' }],
                                                    })(
                                                        <Input />
                                                    )}
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <FormItem label="Last Name">
                                                    {getFieldDecorator('last_name', {
                                                        rules: [{ required: true, message: 'Please input your last name!' }],
                                                    })(
                                                        <Input />
                                                    )}
                                                </FormItem>
                                            </Col>
                                        </Row>
                                        <Row type="flex" justify="space-between" gutter={[30, 0]}>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <FormItem label="Title">
                                                    {getFieldDecorator('title', {
                                                        rules: [{ required: true, message: 'Please input your title distributor!' }],
                                                    })(
                                                        <Select
                                                            showSearch
                                                            optionFilterProp="children"
                                                        >
                                                            {(dropdownTitle) ? dropdownTitle.map(item =>
                                                                <Select.Option value={item.r_tittle_id}>
                                                                    {`${item.kode} - ${item.nama}`}
                                                                </Select.Option>
                                                            ) : <Select.Option value="">--Pilih--</Select.Option>}
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <FormItem label="Telephone">
                                                    {getFieldDecorator('telepon', {
                                                        rules: [{ required: true, message: 'Please input your telephone!' }],
                                                    })(
                                                        <Input />
                                                    )}
                                                </FormItem>
                                            </Col>
                                        </Row>
                                        <Row type="flex" justify="space-between" gutter={[30, 0]}>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <FormItem label="Street">
                                                    {getFieldDecorator('street', {
                                                        rules: [{ required: true, message: 'Please input your street!' }],
                                                    })(
                                                        <Input />
                                                    )}
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <FormItem label="House Number">
                                                    {getFieldDecorator('housenumber', {
                                                        rules: [{ required: true, message: 'Please input your house number!' }],
                                                    })(
                                                        <Input />
                                                    )}
                                                </FormItem>
                                            </Col>
                                        </Row>
                                        <Row type="flex" justify="space-between" gutter={[30, 0]}>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <FormItem label="District">
                                                    {getFieldDecorator('district', {
                                                        rules: [{ required: true, message: 'Please input your district!' }],
                                                    })(
                                                        <Input />
                                                    )}
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <FormItem label="Postal Code">
                                                    {getFieldDecorator('postal_code', {
                                                        rules: [{ required: true, message: 'Please input your postal code!' }],
                                                    })(
                                                        <Input />
                                                    )}
                                                </FormItem>
                                            </Col>
                                        </Row>
                                        <Row type="flex" justify="space-between" gutter={[30, 0]}>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <FormItem label="City">
                                                    {getFieldDecorator('city', {
                                                        rules: [{ required: true, message: 'Please input your city!' }],
                                                    })(
                                                        <Input />
                                                    )}
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <FormItem label="State">
                                                    {getFieldDecorator('state', {
                                                        rules: [{ required: true, message: 'Please input your state!' }],
                                                    })(
                                                        <Select
                                                            showSearch
                                                            optionFilterProp="children"
                                                        >
                                                            {(dropdownState) ? dropdownState.map(item =>
                                                                <Select.Option value={item.r_general_state_id}>
                                                                    {`${item.kode_region} - ${item.nama}`}
                                                                </Select.Option>
                                                            ) : <Select.Option value="">--Pilih--</Select.Option>}

                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </Col>
                                        </Row>
                                        <Row type="flex" justify="space-between" gutter={[30, 0]}>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <FormItem label="Transport Zone">
                                                    {getFieldDecorator('transport_zone', {
                                                        rules: [{ required: true, message: 'Please input your transport zone!' }],
                                                    })(
                                                        <Select
                                                            showSearch
                                                            optionFilterProp="children"
                                                        >
                                                            {(dropdownTransportZone) ? dropdownTransportZone.map(item =>
                                                                <Select.Option value={item.r_transport_zone_id}>
                                                                    {`${item.kode} - ${item.nama}`}
                                                                </Select.Option>
                                                            ) : <Select.Option value="">--Pilih--</Select.Option>}

                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <FormItem label="Mobile">
                                                    {getFieldDecorator('mobile', {
                                                        rules: [{ required: true, message: 'Please input your mobile!' }],
                                                    })(
                                                        <Input />
                                                    )}
                                                </FormItem>
                                            </Col>
                                        </Row>
                                        {/* <Row type="flex" justify="space-between" gutter={[30, 0]}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FormItem label="Language">
                          <Checkbox>EN</Checkbox>
                          <Checkbox>English</Checkbox>
                        </FormItem>
                      </Col>
                    </Row> */}
                                        <Row type="flex" justify="space-between" gutter={[30, 0]}>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <FormItem label="Email 1">
                                                    {getFieldDecorator('email1', {
                                                        rules: [{
                                                            type: 'email',
                                                            message: 'The input is not valid E-mail!',
                                                        }, { required: true, message: 'Please input your email 1!' }],
                                                    })(
                                                        <Input />
                                                    )}
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <FormItem label="Email 2">
                                                    {getFieldDecorator('email2', {
                                                        rules: [{
                                                            type: 'email',
                                                            message: 'The input is not valid E-mail!',
                                                        }],
                                                    })(
                                                        <Input />
                                                    )}
                                                </FormItem>
                                            </Col>
                                        </Row>
                                        <Row type="flex" justify="space-between" gutter={[30, 0]}>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <FormItem label="Email 3">
                                                    {getFieldDecorator('email3', {
                                                        rules: [{
                                                            type: 'email',
                                                            message: 'The input is not valid E-mail!',
                                                        }],
                                                    })(
                                                        <Input />
                                                    )}
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            </Col>
                                        </Row>

                                        <FormItem>
                                            {getFieldDecorator('remember', {
                                                valuePropName: 'checked',
                                                initialValue: true,
                                            })(
                                                <Checkbox><IntlMessages id="appModule.iAccept" /></Checkbox>
                                            )}
                                            <span style={{ color: '#057d43', cursor: 'pointer' }}><IntlMessages
                                                id="appModule.termAndCondition" /></span>
                                        </FormItem>
                                        <FormItem>
                                            <Row type="flex" justify="start" gutter={[30, 0]}>
                                                <Col>
                                                    <div className="btn-submit-login">
                                                        <Button type="primary" className="gx-mb-0" htmlType="submit" style={{ backgroundColor: '#004926 !important', borderColor: '#004926 !important' }}>
                                                            <IntlMessages id="app.userAuth.signUp" />
                                                        </Button>
                                                    </div>
                                                </Col>
                                                {/* <Col>
                        <span><IntlMessages id="app.userAuth.or" /> </span>
                        <Link to="/signin">
                          <span style={{ color: 'green' }}><IntlMessages id="app.userAuth.signIn" /> </span>
                        </Link>
                      </Col> */}
                                            </Row>
                                        </FormItem>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </CardBox>
        </div>
    );
};

const WrappedSignUpForm = Form.create()(SignUp);

export default WrappedSignUpForm;
