import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    contractRequest: ['data'],
    contractSuccess: ['payload', 'pagination'],
    contractFailure: null,

    contractDetailRequest: ['datadetail', 'id'],
    contractDetailSuccess: ['payloaddetail'],
    contractDetailFailure: null,

    contractAssignDriverRequest: ['dataassigndriver', 'cb'],
    contractAssignDriverSuccess: ['payloadassigndriver'],
    contractAssignDriverFailure: null,

    contractMultipleAssignRequest: ['datamar', 'cb'],
    contractMultipleAssignSuccess: ['payloadmar'],
    contractMultipleAssignFailure: null
})

export const ContractTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: null,
    fetching: null,
    payload: [],
    error: null,
    pagination: {},

    datadetail: null,
    fetchingdetail: null,
    payloaddetail: null,
    errordetail: null,
  
    dataassigndriver: null,
    fetchingassigndriver: null,
    payloadassigndriver: null,
    errorassigndriver: null,

    datamar: null,
    fetchingmar: null,
    payloadmar: null,
    errormar: null,
})

/* ------------- Selectors ------------- */

export const ContractSelectors = {
    getData: state => state.contract.data,
    getPayload: state => state.contract.payload,
}

/* ------------- Reducers ------------- */

// request the data from an api
// request the data from an api
export const request = (state, { data }) =>
    state.merge({ fetching: true, data })

// successful api lookup
export const success = (state, action) => {
    const { payload, pagination } = action
    return state.merge({ fetching: false, error: null, payload, pagination: (pagination) ? pagination : state.pagination })
}

// Something went wrong somewhere.
export const failure = state =>
    state.merge({ fetching: false, error: true })


//DETAIL
export const requestdetail = (state, { datadetail }) =>
    state.merge({ fetchingdetail: true, datadetail, payloaddetail: null })

// successful api lookup
export const successdetail = (state, action) => {
    const { payloaddetail } = action
    return state.merge({ fetchingdetail: false, errordetail: null, payloaddetail })
}

// Something went wrong somewhere.
export const failuredetail = state =>
    state.merge({ fetchingdetail: false, errordetail: true, payloaddetail: null })

//ASSIGN DRIVER
export const requestassigndriver = (state, { dataassigndriver }) =>
    state.merge({ fetchingassigndriver: true, dataassigndriver, payloadassigndriver: null })

// successful api lookup
export const successassigndriver = (state, action) => {
    const { payloadassigndriver } = action
    return state.merge({ fetchingassigndriver: false, errorassigndriver: null, payloadassigndriver })
}

// Something went wrong somewhere.
export const failureassigndriver = state =>
    state.merge({ fetchingassigndriver: false, errorassigndriver: true, payloadassigndriver: null })

//MAR
export const requestmar = (state, { datamar }) =>
    state.merge({ fetchingmar: true, datamar, payloadmar: null })

// successful api lookup
export const successmar = (state, action) => {
    const { payloadmar } = action
    return state.merge({ fetchingmar: false, errormar: null, payloadmar })
}

// Something went wrong somewhere.
export const failuremar = state =>
    state.merge({ fetchingmar: false, errormar: true, payloadmar: null })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.CONTRACT_REQUEST]: request,
    [Types.CONTRACT_SUCCESS]: success,
    [Types.CONTRACT_FAILURE]: failure,
  
    [Types.CONTRACT_DETAIL_REQUEST]: requestdetail,
    [Types.CONTRACT_DETAIL_SUCCESS]: successdetail,
    [Types.CONTRACT_DETAIL_FAILURE]: failuredetail,

    [Types.CONTRACT_ASSIGN_DRIVER_REQUEST]: requestassigndriver,
    [Types.CONTRACT_ASSIGN_DRIVER_SUCCESS]: successassigndriver,
    [Types.CONTRACT_ASSIGN_DRIVER_FAILURE]: failureassigndriver,

    [Types.CONTRACT_MULTIPLE_ASSIGN_REQUEST]: requestmar,
    [Types.CONTRACT_MULTIPLE_ASSIGN_SUCCESS]: successmar,
    [Types.CONTRACT_MULTIPLE_ASSIGN_FAILURE]: failuremar,

    


})
