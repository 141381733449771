/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This dropdown uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, fork } from 'redux-saga/effects'
import DropdownActions from '../appRedux/reducers/DropdownRedux'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * dropdownRequest (api, action) {
    const { property, data, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    // console.log('property', property)
    const response = yield call(api.dropdownRequest, property, data)
    // console.log('RESPONSE DD', JSON.stringify(response))
    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        // console.log('dropdown response.data', response.data)
        yield put(DropdownActions.dropdownSuccess(property, response.data.results))
        if (cb) cb()
    } else {
        yield put(DropdownActions.dropdownFailure(property))
    }
}
 
// export function * dropdownRequest (api, action) {
//     yield fork(forkDropdownRequest,api,action)
// }