import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import './styles.scss';

import CustomScrollbars from 'util/CustomScrollbars';
import SidebarLogo from './SidebarLogo';
import UserProfile from './UserProfile';
import AppsNavigation from './AppsNavigation';
import {
    NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    THEME_TYPE_LITE
} from '../../constants/ThemeSetting';
import IntlMessages from '../../util/IntlMessages';
import { FolderOpenOutlined, FolderOutlined } from '@ant-design/icons';

const SidebarContent = () => {

    let { navStyle, themeType, pathname } = useSelector(({ settings }) => settings);
    let { menus, authUser } = useSelector(({ auth }) => auth);
    const user = JSON.parse(localStorage.getItem('user'));
    const pajak = localStorage.getItem('user') ? user.profile.kode : null ;
    // let pajak = ``
    const urlStok = `https://employee.enesis.com/stock?r=${pajak}`
    // console.log('menus###',user.profile.kode_region)
    const getNoHeaderClass = (navStyle) => {
        if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
            return 'gx-no-header-notifications';
        }
        return '';
    };

    const openCloseSubMenu = (isopen, submenuname) => {
        if (isopen) {
            return (
                <div>
                    <span style={{  }} >
                        <FolderOpenOutlined
                            style={{ fontSize: 25, alignItems: 'center', marginLeft: -1, marginRight: 25}}
                        />
                        <span style={{ marginTop: 3 }}>
                            {submenuname}
                        </span>
                    </span>
                </div>
            )
        }
        return (
            <div>
                <span style={{  }} >
                    <FolderOutlined
                        style={{ fontSize: 25, alignItems: 'center', marginLeft: -1, marginRight: 25}}
                    />
                    <span style={{ marginTop: 3 }}>
                        {submenuname}
                    </span>
                </span>
            </div>
        )
    }

    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];

    const [openedmenus, setOpenedMenus] = useState([]);


    return (<>

        <SidebarLogo />
        <div className="gx-sidebar-content">
            <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
                <UserProfile />
            </div>
            <CustomScrollbars className="gx-layout-sider-scrollbar">
                <Menu
                    defaultOpenKeys={[defaultOpenKeys]}
                    selectedKeys={[selectedKeys]}
                    theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                    mode="inline"
                    onOpenChange={openKeys => {
                        console.log(openKeys)
                        setOpenedMenus(openKeys)
                    }}
                    style={{overflowY: 'scroll', paddingBottom: '300px'}}
                >

                    {menus && menus.map((item) => {
                        console.log('menu!', JSON.stringify(item));
                        if(item.nama.toLowerCase() === 'upload stock')
                        {
                            return (
                                <Menu.Item key={item.m_menu_id}>
                                    <a href={urlStok}  target="_blank">
                                        <i className="icon icon-widgets" style={{ marginRight: 30 }} />
                                        <IntlMessages id={item.nama} />
                                    </a>
                                </Menu.Item>
                            )
                        }
                        else if(item.nama.toLowerCase() === 'upload sales')
                        {
                            return (
                                <Menu.Item key={item.m_menu_id}>
                                    <a href="https://enigma.enesis.com" target="_blank">
                                        <i className="icon icon-widgets" style={{ marginRight: 30 }} />
                                        <IntlMessages id={item.nama} />
                                    </a>
                                </Menu.Item>
                            )
                        }
                        else if(item.nama.toLowerCase() === 'e-proposal')
                        {
                            return (
                                <Menu.Item key={item.m_menu_id}>
                                    <a href="https://eprop.enesis.com/login" target="_blank">
                                        {/* https://eprop.enesis.com/dev/login */}
                                        <i className="icon icon-widgets" style={{ marginRight: 30 }} />
                                        <IntlMessages id={item.nama} />
                                    </a>
                                </Menu.Item>
                            )
                        } else if (item.sub) {
                            return (
                                <Menu.SubMenu
                                    key={item.m_menu_id}
                                    title={
                                        openCloseSubMenu(openedmenus.includes(item.m_menu_id), item.nama)
                                    }
                                >
                                    {
                                        item.sub.map(x => (
                                            <Menu.Item key={x.m_menu_id}>
                                                <Link to={x.route}>
                                                    <i className="icon icon-widgets" style={{ marginRight: 10 }} />
                                                    <IntlMessages id={x.nama} />
                                                </Link>
                                            </Menu.Item>
                                        ))
                                    }
                                </Menu.SubMenu>

                            )
                        }
                        else
                        {
                            return (
                                <Menu.Item key={item.m_menu_id}>
                                    <Link to={item.route}>
                                        <i className="icon icon-widgets" style={{ marginRight: 30 }} />
                                        <IntlMessages id={item.nama} /></Link>
                                </Menu.Item>

                            )
                        }
                    })}
                    {/* <Menu.Item key="samplefilter">
            <Link to="/samplefilter"><i className="icon icon-widgets" style={{ marginRight: 30 }} />
              <IntlMessages id="Sample Filter" /></Link>
          </Menu.Item> */}
                    {/* <Menu.Item key="dashboard">
            <Link to="/dashboard"><i className="icon icon-widgets" style={{ marginRight: 30 }} />
              <IntlMessages id="Dashboard" /></Link>
          </Menu.Item>
          <Menu.Item key="cmo">
            <Link to="/cmo"><i className="icon icon-widgets" style={{ marginRight: 30 }} />
              <IntlMessages id="CMO" /></Link>
          </Menu.Item>
          <Menu.Item key="sales">
            <Link to="/sales"><i className="icon icon-widgets" style={{ marginRight: 30 }} />
              <IntlMessages id="Sales" /></Link>
          </Menu.Item>
          <Menu.Item key="finance">
            <Link to="/finance"><i className="icon icon-widgets" style={{ marginRight: 30 }} />
              <IntlMessages id="Finance" /></Link>
          </Menu.Item>
          <Menu.Item key="shipment">
            <Link to="/shipment"><i className="icon icon-widgets" style={{ marginRight: 30 }} />
              <IntlMessages id="Shipment" /></Link>
          </Menu.Item>
          <Menu.Item key="tracking">
            <Link to="/tracking"><i className="icon icon-widgets" style={{ marginRight: 30 }} />
              <IntlMessages id="Tracking" /></Link>
          </Menu.Item>
          <Menu.Item key="distributor-sales-data">
            <Link to="/distributor-sales-data"><i className="icon icon-widgets" style={{ marginRight: 30 }} />
              <IntlMessages id="Distributor Sales Data" /></Link>
          </Menu.Item>
          <Menu.Item key="distributor-accounting">
            <Link to="/distributor-accounting"><i className="icon icon-widgets" style={{ marginRight: 30 }} />
              <IntlMessages id="Distributor Accounting" /></Link>
          </Menu.Item>
          <Menu.Item key="trucking">
            <Link to="/trucking"><i className="icon icon-widgets" style={{ marginRight: 30 }} />
              <IntlMessages id="Trucking Logistik" /></Link>
          </Menu.Item>
          <Menu.Item key="helloworld">
            <Link to="/sayhelloworld"><i className="icon icon-widgets" style={{ marginRight: 30 }} />
              <IntlMessages id="HelloWorld" /></Link>
          </Menu.Item> */}

                </Menu>
            </CustomScrollbars>
        </div>
    </>
    );
};

SidebarContent.propTypes = {};

export default SidebarContent;

