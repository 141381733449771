import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    deliveryOrderRequest: ['data', 'id'],
    deliveryOrderSuccess: ['payload', 'pagination'],
    deliveryOrderFailure: null,

    deliveryOrderDetailRequest: ['datadetail', 'id'],
    deliveryOrderDetailSuccess: ['payloaddetail'],
    deliveryOrderDetailFailure: null,

    deliveryOrderSearchSapRequest: ['datasearchsap', 'cb'],
    deliveryOrderSearchSapSuccess: ['payloadsearchsap'],
    deliveryOrderSearchSapFailure: null,
    deliveryOrderSearchSapReset: null,

    deliveryOrderAssignRequest: ['dataassign', 'cb'],
    deliveryOrderAssignSuccess: ['payloadassign'],
    deliveryOrderAssignFailure: null,

    deliveryOrderSetPayloadDetail: ['payloaddetail'],

    deliveryOrderSaveRequest: ['datasave', 'cb'],
    deliveryOrderSaveSuccess: ['payloadsave'],
    deliveryOrderSaveFailure: null,

    deliveryOrderProveRequest: ['dataprove', 'cb'],
    deliveryOrderProveSuccess: ['payloadprove'],
    deliveryOrderProveFailure: null,

    deliveryOrderUploadPodRequest: ['datapod', 'cb'],
    deliveryOrderUploadPodSuccess: ['payloadpod'],
    deliveryOrderUploadPodFailure: null,

    deliveryOrderChangeDriverRequest: ['datachangedriver', 'cb'],
    deliveryOrderChangeDriverSuccess: ['payloadchangedriver'],
    deliveryOrderChangeDriverFailure: null,
 
    deliveryOrderReadyInvoiceRequest: ['datareadyinvoice'],
    deliveryOrderReadyInvoiceSuccess: ['payloadreadyinvoice'],
    deliveryOrderReadyInvoiceFailure: null,

    deliveryOrderMultiAssignRequest: ['dataassign', 'cb'],
    deliveryOrderMultiAssignSuccess: ['payloadassign'],
    deliveryOrderMultiAssignFailure: null,
 
    deliveryOrderEditRequest: ['dataedit', 'cb'],
    deliveryOrderEditSuccess: ['payloadedit'],
    deliveryOrderEditFailure: null,

    deliveryOrderDeleteRequest: ['datadelete', 'cb'],
    deliveryOrderDeleteSuccess: ['payloaddelete'],
    deliveryOrderDeleteFailure: null,

    deliveryOrderBundleNotAssignedRequest: ['databnar', 'cb'],
    deliveryOrderBundleNotAssignedSuccess: ['payloadbnar'],
    deliveryOrderBundleNotAssignedFailure: null,
    
})

export const DeliveryOrderTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: null,
    fetching: null,
    payload: [],
    error: null,
    pagination: {},

    datadetail: null,
    fetchingdetail: null,
    payloaddetail: null,
    errordetail: null,

    datasearchsap: null,
    fetchingsearchsap: null,
    payloadsearchsap: [],
    errorsearchsap: null,

    dataassign: null,
    fetchingassign: null,
    payloadassign: null,
    errorassign: null,

    datasave: null,
    fetchingsave: null,
    payloadsave: null,
    errorsave: null,

    dataprove: null,
    fetchingprove: null,
    payloadprove: null,
    errorprove: null,

    datapod: null,
    fetchingpod: null,
    payloadpod: null,
    errorpod: null,
    
    datachangedriver: null,
    fetchingchangedriver: null,
    payloadchangedriver: null,
    errorchangedriver: null,

    datareadyinvoice: null,
    fetchingreadyinvoice: null,
    payloadreadyinvoice: [],
    errorreadyinvoice: null,

    dataedit: null,
    fetchingedit: null,
    payloadedit: null,
    erroredit: null,

    datadelete: null,
    fetchingdelete: null,
    payloaddelete: null,
    errordelete: null,

    databnar: null,
    fetchingbnar: null,
    payloadbnar: null,
    errorbnar: null,
})

/* ------------- Selectors ------------- */

export const DeliveryOrderSelectors = {
    getData: state => state.deliveryorder.data,
    getPayloadDetail: state => state.deliveryorder.payloaddetail,
    getPayload: state => state.deliveryorder.payload,
}

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
    state.merge({ fetching: true, data })

// successful api lookup
export const success = (state, action) => {
    const { payload, pagination } = action
    return state.merge({ fetching: false, error: null, payload, pagination: (pagination) ? pagination : state.pagination })
}

// Something went wrong somewhere.
export const failure = state =>
    state.merge({ fetching: false, error: true })


//DETAIL
export const requestdetail = (state, { datadetail }) =>
    state.merge({ fetchingdetail: true, datadetail, payloaddetail: null })

// successful api lookup
export const successdetail = (state, action) => {
    const { payloaddetail } = action
    return state.merge({ fetchingdetail: false, errordetail: null, payloaddetail })
}

// Something went wrong somewhere.
export const failuredetail = state =>
    state.merge({ fetchingdetail: false, errordetail: true, payloaddetail: null })


//SEACH SAP
export const requestsearchsap = (state, { datasearchsap }) =>
    state.merge({ fetchingsearchsap: true, datasearchsap, payloadsearchsap: [] })

// successful api lookup
export const successsearchsap = (state, action) => {
    const { payloadsearchsap } = action
    return state.merge({ fetchingsearchsap: false, errorsearchsap: null, payloadsearchsap })
}

// Something went wrong somewhere.
export const failuresearchsap = state =>
    state.merge({ fetchingsearchsap: false, errorsearchsap: true, payloadsearchsap: [] })

export const resetsearchsap = state =>
    state.merge({ fetchingsearchsap: false, errorsearchsap: null, payloadsearchsap: [], datasearchsap: null })
    

//ASSIGN
export const requestassign = (state, { dataassign }) =>
    state.merge({ fetchingassign: true, dataassign, payloadassign: null })

// successful api lookup
export const successassign = (state, action) => {
    const { payloadassign } = action
    return state.merge({ fetchingassign: false, errorassign: null, payloadassign })
}

// Something went wrong somewhere.
export const failureassign = state =>
    state.merge({ fetchingassign: false, errorassign: true, payloadassign: null })


//SAVE
export const requestsave = (state, { datasave }) =>
    state.merge({ fetchingsave: true, datasave, payloadsave: null })

// successful api lookup
export const successsave = (state, action) => {
    const { payloadsave } = action
    return state.merge({ fetchingsave: false, errorsave: null, payloadsave })
}

// Something went wrong somewhere.
export const failuresave = state =>
    state.merge({ fetchingsave: false, errorsave: true, payloadsave: null })

//PROVE
export const requestprove = (state, { dataprove }) =>
    state.merge({ fetchingprove: true, dataprove, payloadprove: null })

// successful api lookup
export const successprove = (state, action) => {
    const { payloadprove } = action
    return state.merge({ fetchingprove: false, errorprove: null, payloadprove })
}

// Something went wrong somewhere.
export const failureprove = state =>
    state.merge({ fetchingprove: false, errorprove: true, payloadprove: null })

//POD
export const requestpod = (state, { datapod }) =>
    state.merge({ fetchingpod: true, datapod, payloadpod: null })

// successful api lookup
export const successpod = (state, action) => {
    const { payloadpod } = action
    return state.merge({ fetchingpod: false, errorpod: null, payloadpod })
}

// Something went wrong somewhere.
export const failurepod = state =>
    state.merge({ fetchingpod: false, errorpod: true, payloadpod: null })


//Change Driver
export const requestchangedriver = (state, { datachangedriver }) =>
    state.merge({ fetchingchangedriver: true, datachangedriver, payloadchangedriver: null })

// successful api lookup
export const successchangedriver = (state, action) => {
    const { payloadchangedriver } = action
    return state.merge({ fetchingchangedriver: false, errorchangedriver: null, payloadchangedriver })
}

// Something went wrong somewhere.
export const failurechangedriver = state =>
    state.merge({ fetchingchangedriver: false, errorchangedriver: true, payloadchangedriver: null })

//READY INVOICE
export const requestreadyinvoice = (state, { datareadyinvoice }) =>
    state.merge({ fetchingreadyinvoice: true, datareadyinvoice, payloadreadyinvoice: [] })

// successful api lookup
export const successreadyinvoice = (state, action) => {
    const { payloadreadyinvoice } = action
    return state.merge({ fetchingreadyinvoice: false, errorreadyinvoice: null, payloadreadyinvoice })
}

// Something went wrong somewhere.
export const failurereadyinvoice = state =>
    state.merge({ fetchingreadyinvoice: false, errorreadyinvoice: true, payloadreadyinvoice: [] })


//EDIT
export const requestedit = (state, { dataedit }) =>
    state.merge({ fetchingedit: true, dataedit, payloadedit: null })

// successful api lookup
export const successedit = (state, action) => {
    const { payloadedit } = action
    return state.merge({ fetchingedit: false, erroredit: null, payloadedit })
}

// Something went wrong somewhere.
export const failureedit = state =>
    state.merge({ fetchingedit: false, erroredit: true, payloadedit: null })

    
//DELETE
export const requestdelete = (state, { datadelete }) =>
    state.merge({ fetchingdelete: true, datadelete, payloaddelete: null })

// successful api lookup
export const successdelete = (state, action) => {
    const { payloaddelete } = action
    return state.merge({ fetchingdelete: false, errordelete: null, payloaddelete })
}

// Something went wrong somewhere.
export const failuredelete = state =>
    state.merge({ fetchingdelete: false, errordelete: true, payloaddelete: null })

//BNAR
export const requestbnar = (state, { databnar }) =>
    state.merge({ fetchingbnar: true, databnar, payloadbnar: null })

// successful api lookup
export const successbnar = (state, action) => {
    const { payloadbnar } = action
    return state.merge({ fetchingbnar: false, errorbnar: null, payloadbnar })
}

// Something went wrong somewhere.
export const failurebnar = state =>
    state.merge({ fetchingbnar: false, errorbnar: true, payloadbnar: null })    


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.DELIVERY_ORDER_REQUEST]: request,
    [Types.DELIVERY_ORDER_SUCCESS]: success,
    [Types.DELIVERY_ORDER_FAILURE]: failure,

    [Types.DELIVERY_ORDER_DETAIL_REQUEST]: requestdetail,
    [Types.DELIVERY_ORDER_DETAIL_SUCCESS]: successdetail,
    [Types.DELIVERY_ORDER_DETAIL_FAILURE]: failuredetail,

    [Types.DELIVERY_ORDER_SEARCH_SAP_REQUEST]: requestsearchsap,
    [Types.DELIVERY_ORDER_SEARCH_SAP_SUCCESS]: successsearchsap,
    [Types.DELIVERY_ORDER_SEARCH_SAP_FAILURE]: failuresearchsap,
    [Types.DELIVERY_ORDER_SEARCH_SAP_RESET]: resetsearchsap,

    [Types.DELIVERY_ORDER_ASSIGN_REQUEST]: requestassign,
    [Types.DELIVERY_ORDER_ASSIGN_SUCCESS]: successassign,
    [Types.DELIVERY_ORDER_ASSIGN_FAILURE]: failureassign,

    [Types.DELIVERY_ORDER_SET_PAYLOAD_DETAIL]: successdetail,

    [Types.DELIVERY_ORDER_SAVE_REQUEST]: requestsave,
    [Types.DELIVERY_ORDER_SAVE_SUCCESS]: successsave,
    [Types.DELIVERY_ORDER_SAVE_FAILURE]: failuresave,

    [Types.DELIVERY_ORDER_PROVE_REQUEST]: requestprove,
    [Types.DELIVERY_ORDER_PROVE_SUCCESS]: successprove,
    [Types.DELIVERY_ORDER_PROVE_FAILURE]: failureprove,

    [Types.DELIVERY_ORDER_PROVE_REQUEST]: requestpod,
    [Types.DELIVERY_ORDER_PROVE_SUCCESS]: successpod,
    [Types.DELIVERY_ORDER_PROVE_FAILURE]: failurepod,

    [Types.DELIVERY_ORDER_CHANGE_DRIVER_REQUEST]: requestchangedriver,
    [Types.DELIVERY_ORDER_CHANGE_DRIVER_SUCCESS]: successchangedriver,
    [Types.DELIVERY_ORDER_CHANGE_DRIVER_FAILURE]: failurechangedriver,

    [Types.DELIVERY_ORDER_READY_INVOICE_REQUEST]: requestreadyinvoice,
    [Types.DELIVERY_ORDER_READY_INVOICE_SUCCESS]: successreadyinvoice,
    [Types.DELIVERY_ORDER_READY_INVOICE_FAILURE]: failurereadyinvoice,

    [Types.DELIVERY_ORDER_MULTI_ASSIGN_REQUEST]: requestassign,
    [Types.DELIVERY_ORDER_MULTI_ASSIGN_SUCCESS]: successassign,
    [Types.DELIVERY_ORDER_MULTI_ASSIGN_FAILURE]: failureassign, 

    [Types.DELIVERY_ORDER_EDIT_REQUEST]: requestedit,
    [Types.DELIVERY_ORDER_EDIT_SUCCESS]: successedit,
    [Types.DELIVERY_ORDER_EDIT_FAILURE]: failureedit,

    [Types.DELIVERY_ORDER_DELETE_REQUEST]: requestdelete,
    [Types.DELIVERY_ORDER_DELETE_SUCCESS]: successdelete,
    [Types.DELIVERY_ORDER_DELETE_FAILURE]: failuredelete,

    [Types.DELIVERY_ORDER_BUNDLE_NOT_ASSIGNED_REQUEST]: requestbnar,
    [Types.DELIVERY_ORDER_BUNDLE_NOT_ASSIGNED_SUCCESS]: successbnar,
    [Types.DELIVERY_ORDER_BUNDLE_NOT_ASSIGNED_FAILURE]: failurebnar,
})
