import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    trackingPlafondRequest: ['data'],
    trackingPlafondSuccess: ['payload', 'pagination'],
    trackingPlafondFailure: null,

    trackingPlafondDetailRequest: ['datadetail', 'id'],
    trackingPlafondDetailSuccess: ['payloaddetail'],
    trackingPlafondDetailFailure: null,

    trackingPlafondSetDetail: ['payloaddetail'],

})

export const TrackingPlafondTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: null,
    fetching: null,
    payload: [],
    error: null,
    pagination: {},

    datadetail: null,
    fetchingdetail: null,
    payloaddetail: null,
    errordetail: null,

})

/* ------------- Selectors ------------- */

export const TrackingPlafondSelectors = {
    getData: state => state.data
}

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
    state.merge({ fetching: true, data })

// successful api lookup
export const success = (state, action) => {
    const { payload, pagination } = action
    return state.merge({ fetching: false, error: null, payload, pagination: (pagination) ? pagination : state.pagination })
}

// Something went wrong somewhere.
export const failure = state =>
    state.merge({ fetching: false, error: true })

//DETAIL
export const requestdetail = (state, { datadetail }) =>
    state.merge({ fetchingdetail: true, datadetail, payloaddetail: null })

// successful api lookup
export const successdetail = (state, action) => {
    const { payloaddetail } = action
    return state.merge({ fetchingdetail: false, errordetail: null, payloaddetail })
}

export const setdetail = (state, action) => {
    const { payloaddetail } = action
    return state.merge({ fetchingdetail: false, errordetail: null, payloaddetail })
}

// Something went wrong somewhere.
export const failuredetail = state =>
    state.merge({ fetchingdetail: false, errordetail: true, payloaddetail: null })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.TRACKING_PLAFOND_REQUEST]: request,
    [Types.TRACKING_PLAFOND_SUCCESS]: success,
    [Types.TRACKING_PLAFOND_FAILURE]: failure,

    [Types.TRACKING_PLAFOND_DETAIL_REQUEST]: requestdetail,
    [Types.TRACKING_PLAFOND_DETAIL_SUCCESS]: successdetail,
    [Types.TRACKING_PLAFOND_DETAIL_FAILURE]: failuredetail,

    [Types.TRACKING_PLAFOND_SET_DETAIL]: setdetail,
})
