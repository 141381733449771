import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    orderRequest: ['data'],
    orderSuccess: ['payload'],
    orderFailure: null,

    orderToSapDpdRequest: ['dataordertosapdpd', 'cb'],
    orderToSapDpdSuccess: ['payloadordertosapdpd'],
    orderToSapDpdFailure: null
})

export const OrderTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: null,
    fetching: null,
    payload: [],
    error: null,

    dataordertosapdpd: null,
    fetchingordertosapdpd: null,
    payloadordertosapdpd: null,
    errorordertosapdpd: null
})

/* ------------- Selectors ------------- */

export const OrderSelectors = {
    getPayload: state => state.order.payload
}

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
    state.merge({ fetching: true, data })

// successful api lookup
export const success = (state, action) => {
    const { payload } = action
    return state.merge({ fetching: false, error: null, payload })
}

// Something went wrong somewhere.
export const failure = state =>
    state.merge({ fetching: false, error: true })

// ORDER TO SAP - DPD
export const requestordertosapdpd = (state, { dataordertosapdpd }) =>
    state.merge({ fetchingordertosapdpd: true, dataordertosapdpd })

// successful api lookup
export const successordertosapdpd = (state, action) => {
    const { payloadordertosapdpd } = action
    return state.merge({ fetchingordertosapdpd: false, errorordertosapdpd: null, payloadordertosapdpd })
}

// Something went wrong somewhere.
export const failureordertosapdpd = state =>
    state.merge({ fetchingordertosapdpd: false, errorordertosapdpd: true })

  
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.ORDER_REQUEST]: request,
    [Types.ORDER_SUCCESS]: success,
    [Types.ORDER_FAILURE]: failure,

    [Types.ORDER_TO_SAP_DPD_REQUEST]: requestordertosapdpd,
    [Types.ORDER_TO_SAP_DPD_SUCCESS]: successordertosapdpd,
    [Types.ORDER_TO_SAP_DPD_FAILURE]: failureordertosapdpd
})
