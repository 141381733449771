// a library to wrap and simplify api calls
import apisauce from 'apisauce'
import { URL } from '../util/URL'
// import StaticVar from '../Config/StaticVar'

import {stringify} from 'querystring'

// our "constructor"
const create = (baseURL = `${URL}`) => {
    // ------
    // STEP 1
    // ------
    //
    // Create and configure an apisauce-based api object.
    //
    const api = apisauce.create({
    // base URL is read from the "constructor"
        baseURL,
        // here are some default headers
        headers: {
            // 'Cache-Control': 'no-cache',
            // 'Content-Type': 'application/json',
        },
        // 10 second timeout...
        timeout: (1000 * 1800) //30 menit
    })

    // ------
    // STEP 2
    // ------
    //
    // Define some functions that call the api.  The goal is to provide
    // a thin wrapper of the api layer providing nicer feeling functions
    // rather than "get", "post" and friends.
    //
    // I generally don't like wrapping the output at this level because
    // sometimes specific actions need to be take on `403` or `401`, etc.
    //
    // Since we can't hide from that, we embrace it by getting out of the
    // way at this level.
    //
    const edititemcmo = (data) => api.put('cmo/edititemcmo',data)
    const approvecmo = (data) => api.post('approvetosap', data)
    const shipmentRequest = (data) => api.get('shipment', data)
    const transporterRequest = (data) => api.get('transporter', data)
    const orderRequest = (data) => api.get(`order${data ? `/${data}` : ''}`)
    const orderToSapDpdRequest = (data) => api.post('ordertosap/dpd', data)
    const createShipmentRequest = (data) => api.post('shipment', data)
    const biddingRequest = (data) => api.post('biddingshipment', data)
    const biddingInformationRequest = (data) => api.get('biddingshipment/order', data)
    const biddingInformationProcessRequest = (data) => api.post('biddingshipment/quotation', data)
    const trackingOrderRequest = (data) => api.get('trackingorder', data)

    const deliveryOrderRequest = (data, id) => api.get(`do${id ? '/'+id : ''}`, data)
    const deliveryOrderDetailRequest = (id) => api.get(`do/${id}`)
    const deliveryOrderSearchSapRequest = (data) => api.get('do/carido', data)
    const deliveryOrderAssignRequest = (data) => api.post('do/assigndosap', data)
    const deliveryOrderMultiAssignRequest = (data) => api.post('do/assigndosap/multiple', data)
    const deliveryOrderProveRequest = (data) => api.post('do/provedelivery', data)
    const deliveryOrderChangeDriverRequest = (data) => api.post('do/changedriver', data)
    const deliveryOrderReadyInvoiceRequest = (data) => api.get('do/readyinvoice', data)
    const deliveryOrderEditRequest = (data) => api.put('do/bundleandconsole', data)
    const deliveryOrderDeleteRequest = (data) => api.delete('do', {}, { data })
    const deliveryOrderBundleNotAssignedRequest = (data) => api.get('do/bundlenotyetassign', data)


    const trackingPlafondRequest = (data, id) => api.get(`trackingplafond${id ? '/'+id : ''}`, data)
    const listSoldToRequest = (data) => api.get('listsoldto', data)
    const termOfPaymentRequest = (data) => api.get('termofpayment', data)
    const termOfPaymentCalcRequest = (data) => api.post('termofpayment/calculation', data)
    const termOfPaymentTopRequest = (data) => api.post('termofpayment/requesttop', data)
    const topApprovalRequest = (data, id) => api.get(`requesttop${id ? '/'+id : ''}`, data)
    const topApprovalProcessRequest = (data) => api.post('requesttop/process', data)
    const contractRequest = (data, id) => api.get(`contract${id ? '/'+id : ''}`, data)
    // const contractAssignDriverRequest = (data) => api.put('contract', data)
    const contractAssignDriverRequest = (data) => api.post('shipment/assigndriver', data)
    const contractMultipleAssignRequest = (data) => api.post('shipment/assigndriverMultiple', data)

    const driverRequest = (data, id) => api.get(`driver${id ? '/'+id : ''}`, data)
    const driverAddRequest = (data) => api.post('driver', data)
    const driverEditRequest = (data) => api.put('driver', data)
    const driverDeleteRequest = (data) => api.delete('driver', {}, { data })

    const menuRequest = (data, id) => api.get(`menu${id ? '/'+id : ''}`, data)
    const menuAddRequest = (data) => api.post('menu', data)
    const menuEditRequest = (data) => api.put('menu', data)
    const menuDeleteRequest = (id) => api.delete('menu?id='+id)

    const jenisOrganisasiRequest = (data, id) => api.get(`jenisorganisasi${id ? '/'+id : ''}`, data)
    const jenisOrganisasiAddRequest = (data) => api.post('jenisorganisasi', data)
    const jenisOrganisasiEditRequest = (data) => api.put('jenisorganisasi', data)
    const jenisOrganisasiDeleteRequest = (id) => api.delete('jenisorganisasi?id='+id)

    const roleRequest = (data, id) => api.get(`role${id ? '/'+id : ''}`, data)
    const roleAddRequest = (data) => api.post('role', data)
    const roleEditRequest = (data) => api.put('role', data)
    const roleDeleteRequest = (id) => api.delete('role?id='+id)

    const organisasiRequest = (data, id) => api.get(`organisasi${id ? '/'+id : ''}`, data)
    const organisasiAddRequest = (data) => api.post('organisasi', data)
    const organisasiEditRequest = (data) => api.put('organisasi', data)
    const organisasiDeleteRequest = (id) => api.delete('organisasi?id='+id)

    const userOrganisasiRequest = (data, id) => api.get(`userorganisasi${id ? '/'+id : ''}`, data)
    const userOrganisasiAddRequest = (data) => api.post('userorganisasi', data)
    const userOrganisasiEditRequest = (data) => api.put('userorganisasi', data)
    const userOrganisasiDeleteRequest = (id) => api.delete('userorganisasi?id='+id)

    const roleMenuRequest = (data, id) => api.get(`rolemenu${id ? '/'+id : ''}`, data)
    const roleMenuAddRequest = (data) => api.post('rolemenu', data)
    const roleMenuEditRequest = (data) => api.put('rolemenu', data)
    const roleMenuDeleteRequest = (id) => api.delete('rolemenu?id='+id)

    const fakturRequest = (data, id) => api.get(`submitfaktur${id ? '/'+id : ''}`, data)
    const fakturAddRequest = (data) => api.post('submitfaktur', data)  //REAL
    // const fakturAddRequest = (data) => api.post('submitfakturDEBUG', data)  //DEBUG
    const fakturEditRequest = (data) => api.put('submitfaktur', data)
    const fakturDeleteRequest = (data) => api.delete('submitfaktur',{}, { data })

    const fakturApproveRejectRequest = (act, data) => api.post('submitfaktur/'+act, data)

    const fakturCekBastRequest = (data) => api.get('submitfaktur/cekbast', data)


    const fkrBarangRequest = (data, id) => api.get(`fkr${id ? '/'+id : ''}`, data)
    const fkrBarangUploadRequest = (data) => api.post('read/excel/fkr', data)

    const fkrBarangAddRequest = (data) => api.post('fkr/savenonbast', data)
    const fkrBarangAddRequestWithBast = (data) => api.post('fkr/save', data)

    const fkrBarangApproveRejectRequest = (act, data) => api.post('fkr/'+act, data)
    const fkrBarangOnLoadRequest = (data) => api.post('fkr/loadgrcn', data)
    const fkrBarangPrintRequest = (data,id) => api.get(`fkr/print${id ? '/'+id : ''}`+data)

    const fkrExecutionRequest = (data, id) => api.get(`fkreksekusi${id ? '/'+id : ''}`, data)

    const klaimProposalRequest = (data, id) => api.get(`proposalklaim${id ? '/'+id : ''}`, data)
    const klaimProposalListRequest = (data, id) => api.get(`proposalklaim/list${id ? '/'+id : ''}`, data)
    const klaimProposalAddRequest = (data) => api.post('proposalklaim', data)
    const klaimProposalEditRequest = (data) => api.put('proposalklaim', data)
    const klaimProposalArsRequest = (act, data) => api.post('proposalklaim/'+act, data)
    const klaimProposalPayRequest = (data) => api.post('proposalklaim/pay', data)
    const klaimProposalApproveStatusRequest = (data) => api.post('proposalklaim/apf2', data)
    const klaimProposalDeleteRequest = (data) => api.delete('proposalklaim',{}, { data })

    const pajakRequest = (data) => api.get('dropdownlist', {table: 'r_pajak', ...data})
    const dropdownRequest = (property, data) => api.get('dropdownlist', {table: property, ...data})

    const deliveryOrderSaveRequest = (data) => api.post('do/save', data)

    const userDetailRequest = (id) => api.get('/users/'+id)
    const userChangeNameRequest = (data) => api.put('/users',data)
    const userChangePasswordRequest = (data) => api.post('/users/changepassword', data)
    const userRequest = (data, id) => api.get(`users${id ? '/'+id : ''}`, data)
    const userAddRequest = (data) => api.post('users', data)
    const userEditRequest = (data) => api.put('users', data)
    const userDeleteRequest = (id) => api.delete('users?id='+id)

    const userGetPlannerRequest = (data) => api.get('/users/planner', data)
    const partnerRequest = (kode) => api.get(`partnerkey/${kode}`)
    const paymentTermRequest = (data) => api.get('proposalklaim/paymentterm', data)

    const fkrForExportRequest = (data) => api.get('fkr/getexportexcel', data)

    const resetPasswordRequest = (data) => api.post('users/resetpassword', data)

    const distributionChannelRequest = () => api.get('/dropdownlist?table=r_distribution_channel&order_by=kode', )

    const getDocumentNoRequest = (data) => api.get('/documentnumber', data)

    const getConsoleNoRequest = (data) => api.get('/consolenumber', data)

    const cmoReportRequest = (data) => api.get('/cmo/report', data)

    const budgetingRequest = (data) => api.get('/budgeting', data)
    const postBudgeting = data => api.post('/budgeting', data)
    const postPemindahanBudgetBudgeting = data => api.post('/movement/budgeting', data)

    const budgetBrandRequest = (data) => api.get('/brandbudget', data)

    const budgetActivityRequest = (data) => api.get('/budgetactivity', data)
    const budgetSummaryRequest = (data) => api.get('/budgetsummary', data)
    

    const proposalRequest = (data, id) => api.get('/proposal'+(id ? `/${id}` : ''), {...data, crapi: undefined})
    const proposalCrRequest = (data, id) => api.get('/proposal/'+data.crapi+(id ? `/${id}` : ''), {...data, crapi: undefined})
    const proposalCrProcessRequest = (data) => api.post('/proposal/costrasio', data)

    const proposalCreateRequest = (data) => api.post('/proposal', data)

    const proposalEditRequest = (data) => api.post('/editproposal', data)

    const proposalGenerateApprRequest = (data) => api.post('/generateapproval', data)

    const proposalDeleteFileRequest = (data) => api.post('/deletefileproposal', data)

    const proposalKirimRequest = (data) => api.post('/kirimproposal', data)

    const proposalCopyRevisiRequest = (data) => api.get('/copyrevisiproposal', data)
    const proposalCopyRequest = (data) => api.get('/copyproposal', data)
    const proposalApRjRequest = (data) => api.post('/proposalapprove_approve', data)
    const proposalDasboardRequest = (data) => api.get('/dashboard_proposal', data)
    const proposalCancelRequest = (data) => api.post('/proposalcancel', data)
    const proposalReversalRequest = (data) => api.post('/reversalbudget', data)


    const proposalDeleteRequest = (data) => api.post('/deleteproposal ', data)


    const getBudgetYear = (data) => api.get('/budgetyear', data)
    const getBudgetYear2 = (data) => api.get('/budgetyear2', data)
    const getBudgetYear3 = (data) => api.get('/budgetyear3', data)

    const getDivision = (data) => api.get(`/division?${stringify(data)}`)

    const getRegion = (data) => api.get(`/region?${stringify(data)}`)

    //parameter ke dua disini (dalam contoh ini adalah var 'data'), otomatis akan menjadi query string saat hit api bila
    //isinya adalah json object, contoh bila isi data adalah {company_id: 2, m_user_id: 'xxx'}, maka saat API Call
    //akan menjadi .../executor?company_id=2&m_user_id=xxx
    //Terkecuali, bila api nya membutuhkan slash parameter, misal: /executor/123 <-- dimana 123 adalah parameter, maka baru begini
    //  `/executor/${stringify(data)}`
    //DAN bila di gabungkan seperti ini api.get(`/executor/${stringify(data)}`, data) <-- ada parameter ke dua
    //maka hasilnya akan begini .../executor/123?company_id=2&m_user_id=xxx
    const getExecutor = data => api.get('/executor', data)

    const getMarketType = data => api.get('/markettype', data)

    const getDistributorMt = data => api.get('/distributor_mt', data)

    const getBranch = data => api.get('/branch', data)

    const getBrand = data => api.get('/brand', data)

    const getSku = data => api.get('/variant', data)

    const getActivity = data => api.get('/activity', data)

    const cekBudgetRequest = data => api.post('/checkbudget', data)

    const getApprovalProposal = data => api.get('/proposalapprove', data)

    const getApprovalBudget = data => api.get('/budgetapproval', data)

    const getMasterActivity = (data, id = '') => api.get(`/master/activity${id ? `/${id}` : ''}`, data)
    const postMasterActivity = data => api.post('/master/activity', data)
    const putMasterActivity = data => api.put('/master/activity', data)
    const deleteMasterActivity = (data, id) => api.delete(`/master/activity/${id}`, data)

    const getMasterBrand = (data, id = '') => api.get(`/master/brand${id ? `/${id}` : ''}`, data)
    const postMasterBrand = data => api.post('/master/brand', data)
    const putMasterBrand = data => api.put('/master/brand', data)
    const deleteMasterBrand = (data, id) => api.delete(`/master/brand/${id}`, data)

    const getMasterMarketType = (data, id = '') => api.get(`/master/markettype${id ? `/${id}` : ''}`, data)
    const postMasterMarketType = data => api.post('/master/markettype', data)
    const putMasterMarketType = data => api.put('/master/markettype', data)
    const deleteMasterMarketType = (data, id) => api.delete(`/master/markettype/${id}`, data)

    const getMasterDivision = (data, id = '') => api.get(`/master/division${id ? `/${id}` : ''}`, data)
    const postMasterDivision = data => api.post('/master/division', data)
    const putMasterDivision = data => api.put('/master/division', data)
    const deleteMasterDivision = (data, id) => api.delete(`/master/division/${id}`, data)

    const getMasterCompany = (data, id = '') => api.get(`/master/company${id ? `/${id}` : ''}`, data)
    const postMasterCompany = data => api.post('/master/company', data)
    const putMasterCompany = data => api.put('/master/company', data)
    const deleteMasterCompany = (data, id) => api.delete(`/master/company/${id}`, data)

    const getMasterDistributor = (data, id = '') => api.get(`/master/distributor${id ? `/${id}` : ''}`, data)
    const postMasterDistributor = data => api.post('/master/distributor', data)
    const putMasterDistributor = data => api.put('/master/distributor', data)
    const deleteMasterDistributor = (data, id) => api.delete(`/master/distributor/${id}`, data)

    const getMasterEmployee = (data, id = '') => api.get(`/master/employee${id ? `/${id}` : ''}`, data)
    const postMasterEmployee = data => api.post('/master/employee', data)
    const putMasterEmployee = data => api.put('/master/employee', data)
    const deleteMasterEmployee = (data, id) => api.delete(`/master/employee/${id}`, data)

    const getMasterVariant = (data, id = '') => api.get(`/master/variant${id ? `/${id}` : ''}`, data)
    const postMasterVariant = data => api.post('/master/variant', data)
    const putMasterVariant = data => api.put('/master/variant', data)
    const deleteMasterVariant = (data, id) => api.delete(`/master/variant/${id}`, data)

    const getMasterRegion = (data, id = '') => api.get(`/master/region${id ? `/${id}` : ''}`, data)
    const postMasterRegion = data => api.post('/master/region', data)
    const putMasterRegion = data => api.put('/master/region', data)
    const deleteMasterRegion = (data, id) => api.delete(`/master/region/${id}`, data)

    const getMasterBudget = (data, id = '') => api.get(`/master/budget${id ? `/${id}` : ''}`, data)
    const postMasterBudget = data => api.post('/master/budget', data)
    const putMasterBudget = data => api.put('/master/budget', data)
    const deleteMasterBudget = (data, id) => api.delete(`/master/budget/${id}`, data)

    const getMasterPosition = (data, id = '') => api.get(`/master/position${id ? `/${id}` : ''}`, data)
    const postMasterPosition = data => api.post('/master/position', data)
    const putMasterPosition = data => api.put('/master/position', data)
    const deleteMasterPosition = (data, id) => api.delete(`/master/position/${id}`, data)

    const getMasterWorkflow = (data, id = '') => api.get(`/master/workflow${id ? `/${id}` : ''}`, data)
    const postMasterWorkflow = data => api.post('/master/workflow', data)
    const putMasterWorkflow = data => api.put('/master/workflow', data)
    const deleteMasterWorkflow = (data, id) => api.delete(`/master/workflow/${id}`, data)
    const deleteMasterWorkflowDetail = (data, id) => api.delete(`/master/workflow/details/${id}`, data)

    const getMasterGroupActivity = (data, id = '') => api.get(`/groupactivity${id ? `/${id}` : ''}`, data)
    const postMasterGroupActivity = data => api.post('/groupactivity', data)
    const putMasterGroupActivity = data => api.put('/groupactivity', data)
    const deleteMasterGroupActivity = (data, id) => api.delete(`/groupactivity/${id}`, data)

    const getReportGenerateLogbook = (data) => api.get('/logbook/generate', data)
    const getReportGeneratePayment = (data) => api.get('/payment/generate', data)

    const getQuarter = (data, id = '') => api.get(`/quarter${id ? `/${id}` : ''}`, data)

    const statusRequest = (data) => api.get('/master/status', data)


    const getShiptoAll = data => api.get('/shiptoall', data)
    const getShipto = data => api.get('/shipto', data)

    const getBranchMultiple = data => api.get('/branchmultiple', data)
    
    const cmoDateValidatorRequest = (data) => api.get('/tanggalcmo', data)

    const deliveryOrderUploadPodRequest = (data) => api.post('do/uploadpod', data)  //REAL
    // ------
    // STEP 3
    // ------
    //
    // Return back a collection of functions that we would consider our
    // interface.  Most of the time it'll be just the list of all the
    // methods in step 2.
    //
    // Notice we're not returning back the `api` created in step 1?  That's
    // because it is scoped privately.  This is one way to create truly
    // private scoped goodies in JavaScript.
    //
    return {
    // a list of the API functions from step 2
        edititemcmo,
        approvecmo,
        shipmentRequest,
        transporterRequest,
        orderRequest,
        createShipmentRequest,
        biddingRequest,
        biddingInformationRequest,
        biddingInformationProcessRequest,
        trackingOrderRequest,
        deliveryOrderRequest,
        deliveryOrderDetailRequest,
        trackingPlafondRequest,
        listSoldToRequest,
        termOfPaymentRequest,
        termOfPaymentCalcRequest,
        termOfPaymentTopRequest,
        topApprovalRequest,
        topApprovalProcessRequest,
        contractRequest,
        contractAssignDriverRequest,
        driverRequest,
        driverAddRequest,
        driverDeleteRequest,
        driverEditRequest,
        fakturRequest,
        fakturAddRequest,
        fakturEditRequest,
        fakturDeleteRequest,
        fkrBarangRequest,
        fkrBarangUploadRequest,
        fkrBarangAddRequest,
        fkrBarangAddRequestWithBast,
        fkrExecutionRequest,
        fakturApproveRejectRequest,
        klaimProposalRequest,
        klaimProposalAddRequest,
        klaimProposalListRequest,
        klaimProposalArsRequest,
        klaimProposalEditRequest,
        pajakRequest,
        dropdownRequest,
        fkrBarangApproveRejectRequest,
        deliveryOrderSearchSapRequest,
        deliveryOrderAssignRequest,
        deliveryOrderSaveRequest,
        deliveryOrderProveRequest,
        deliveryOrderChangeDriverRequest,
        deliveryOrderReadyInvoiceRequest,
        userDetailRequest,
        userChangeNameRequest,
        userChangePasswordRequest,
        partnerRequest,
        paymentTermRequest,
        deliveryOrderMultiAssignRequest,
        fkrForExportRequest,
        fkrBarangOnLoadRequest,
        menuRequest,
        menuAddRequest,
        menuEditRequest,
        menuDeleteRequest,
        jenisOrganisasiRequest,
        jenisOrganisasiAddRequest,
        jenisOrganisasiEditRequest,
        jenisOrganisasiDeleteRequest,
        roleRequest,
        roleAddRequest,
        roleEditRequest,
        roleDeleteRequest,
        organisasiRequest,
        organisasiAddRequest,
        organisasiEditRequest,
        organisasiDeleteRequest,
        userOrganisasiRequest,
        userOrganisasiAddRequest,
        userOrganisasiEditRequest,
        userOrganisasiDeleteRequest,
        roleMenuRequest,
        roleMenuAddRequest,
        roleMenuEditRequest,
        roleMenuDeleteRequest,
        resetPasswordRequest,
        userGetPlannerRequest,
        klaimProposalPayRequest,
        klaimProposalApproveStatusRequest,
        distributionChannelRequest,
        fkrBarangPrintRequest,
        userRequest,
        userAddRequest,
        userEditRequest,
        userDeleteRequest,
        orderToSapDpdRequest,
        getDocumentNoRequest,
        getConsoleNoRequest,
        deliveryOrderEditRequest,
        deliveryOrderDeleteRequest,
        deliveryOrderBundleNotAssignedRequest,
        contractMultipleAssignRequest,
        cmoReportRequest,
        fakturCekBastRequest,
        klaimProposalDeleteRequest,
        budgetingRequest,
        postBudgeting,
        postPemindahanBudgetBudgeting,
        budgetBrandRequest,
        budgetActivityRequest,
        budgetSummaryRequest,
        proposalRequest,
        proposalCrRequest,
        proposalCreateRequest,
        getBudgetYear,
        getDivision,
        getRegion,
        getExecutor,
        getMarketType,
        getDistributorMt,
        getBranch,
        getBrand,
        getSku,
        getActivity,
        cekBudgetRequest,
        proposalDeleteFileRequest,
        proposalEditRequest,
        proposalKirimRequest,
        proposalCopyRevisiRequest,
        proposalCopyRequest,
        proposalDeleteRequest,
        proposalGenerateApprRequest,
        getApprovalProposal,
        getApprovalBudget,
        proposalApRjRequest,
        proposalDasboardRequest,
        getMasterActivity,
        postMasterActivity,
        putMasterActivity,
        deleteMasterActivity,
        getMasterBrand,
        postMasterBrand,
        putMasterBrand,
        deleteMasterBrand,
        getMasterMarketType,
        postMasterMarketType,
        putMasterMarketType,
        deleteMasterMarketType,
        getMasterDivision,
        postMasterDivision,
        putMasterDivision,
        deleteMasterDivision,
        getMasterCompany,
        postMasterCompany,
        putMasterCompany,
        deleteMasterCompany,
        getMasterDistributor,
        postMasterDistributor,
        putMasterDistributor,
        deleteMasterDistributor,
        getMasterEmployee,
        postMasterEmployee,
        putMasterEmployee,
        deleteMasterEmployee,
        getMasterVariant,
        postMasterVariant,
        putMasterVariant,
        deleteMasterVariant,
        getMasterRegion,
        postMasterRegion,
        putMasterRegion,
        deleteMasterRegion,
        getMasterBudget,
        postMasterBudget,
        putMasterBudget,
        deleteMasterBudget,
        getMasterPosition,
        postMasterPosition,
        putMasterPosition,
        deleteMasterPosition,
        getMasterWorkflow,
        postMasterWorkflow,
        putMasterWorkflow,
        deleteMasterWorkflow,
        deleteMasterWorkflowDetail,
        getMasterGroupActivity,
        postMasterGroupActivity,
        putMasterGroupActivity,
        deleteMasterGroupActivity,
        getQuarter,
        getBudgetYear2,
        getBudgetYear3,
        getReportGenerateLogbook,
        getReportGeneratePayment,
        statusRequest,
        proposalCancelRequest,
        getShipto,
        getBranchMultiple,
        getShiptoAll,
        cmoDateValidatorRequest,
        proposalReversalRequest,
        deliveryOrderUploadPodRequest,
        proposalCrProcessRequest
    }
}

// let's return back our create method as the default.
export default {
    create
}
