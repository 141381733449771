/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This user uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, select } from 'redux-saga/effects'
import UserActions , { UserSelectors } from '../appRedux/reducers/UserRedux'
import { notification } from 'antd';
import _ from 'lodash' 

export function * userRequest (api, action) {
    const { data, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.userRequest, data)

    // success?
    if (response.ok) {
        var dataresult = response.data.results
        yield put(UserActions.userSuccess(dataresult, response.data.meta))
        if (cb) cb(true, response.data.results)
    } else {
        yield put(UserActions.userFailure())
        if (cb) cb(false)
    }
}

export function * userDetailRequest (api, action) {
    const { datadetail, id } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.userDetailRequest, datadetail, id) 
    // success?
    if (response.ok) {
        if (response.data.error) {
            yield put(UserActions.userDetailFailure())
            return 
        } 
        yield put(UserActions.userDetailSuccess(response.data.result))
    } else {
        yield put(UserActions.userDetailFailure())
    }
}
export function * userChangePasswordRequest (api, action) {
    const { datachangepassword } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.userChangePasswordRequest, datachangepassword)

    console.log('response',response)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        yield put(UserActions.userChangePasswordSuccess(response.data))

        console.log('disini ok')
    } else {
        console.log('disini eror')
        yield put(UserActions.userChangePasswordFailure(response.data))
    }
}

export function * userGetPlannerRequest (api, action) {
    const { datagetplanner } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.userGetPlannerRequest, datagetplanner) 
    // success?
    if (response.ok && !response.data.error) { 
        yield put(UserActions.userGetPlannerSuccess(response.data.results))
    } else {
        yield put(UserActions.userGetPlannerFailure())
    }
}


export function * userAddRequest (api, action) {
    const { dataadd, cb } = action
    // get current data from Store
    
    // make the call to the api
    const response = yield call(api.userAddRequest, dataadd) 


    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Data User Added',
            placement: 'topRight',
        }); 
 
        yield put(UserActions.userAddSuccess(response.data.result))  
        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when creating Data User, Please try again',
            placement: 'topRight',
        });
        yield put(UserActions.userAddFailure())
        if (cb) cb(false)
    }
}

export function * userEditRequest (api, action) {
    const { dataedit, cb } = action
    // get current data from Store
    // make the call to the api
    const response = yield call(api.userEditRequest, dataedit) 
  
    // success?
    if (response.ok) {
        let currentData = _.cloneDeep(yield select(UserSelectors.getPayload))
        currentData = _.keyBy(currentData, 'm_user_id')

        Object.assign(currentData[dataedit.m_user_id], dataedit);



        notification.success({
            message: 'Success',
            description: 'Data user Modified',
            placement: 'topRight',
        });

        yield put(UserActions.userEditSuccess(response.data.result))
        yield put(UserActions.userSuccess(_.values(currentData)))

        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when Modifying Data user, Please try again',
            placement: 'topRight',
        });
        yield put(UserActions.userEditFailure())
        if (cb) cb(false)
    }
}

export function * userDeleteRequest (api, action) {
    const { datadelete, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.userDeleteRequest, datadelete) 

    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Data user Deleted',
            placement: 'topRight',
        });

        console.log('userDelete response.data', response.data)
        yield put(UserActions.userDeleteSuccess(datadelete))
        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when Deleting Data user, Please try again',
            placement: 'topRight',
        });
        yield put(UserActions.userDeleteFailure())
        if (cb) cb(false)
    }
}
