import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
    cmoRequest: ['data'],
    cmoSuccess: ['payload'],
    cmoFailure: null,

    dataCmoDpdRequest: ['dataCmoDpd', 'cb'],
    dataCmoDpdSuccess: ['payloadordertosapdpd'],
    dataCmoDpdFailure: null
})

export const ApproveCMOTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
    data: null,
    fetching: null,
    payload: [],
    error: null,

    datacmotosapdpd: null,
    fetchingcmosapdpd: null,
    payloadcmotosapdpd: null,
    errorcmotosapdpd: null
})

export const OrderSelectors = {
    getPayload: state => state.order.payload
}

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
    state.merge({ fetching: true, data })

// successful api lookup
export const success = (state, action) => {
    const { payload } = action
    return state.merge({ fetching: false, error: null, payload })
}

// Something went wrong somewhere.
export const failure = state =>
    state.merge({ fetching: false, error: true })
    
// saat request
export const requestcmotosap = (state,{datacmotosapdpd}) =>
    state.merge({fetchingcmosapdpd: true, datacmotosapdpd})

// saat sukses
export const succescmotosap = (state, action) => {
    const { payloadcmotosapdpd } = action
    return state.merge({ fetchingcmosapdpd: false, errorcmotosapdpd: null, payloadcmotosapdpd })
}


//saat gagal
// Something went wrong somewhere.
export const failurecmotosap = state =>
    state.merge({ fetchingcmosapdpd: false, errorcmotosapdpd: true })



export const reducer = createReducer(INITIAL_STATE, {
    [Types.CMO_REQUEST]: request,
    [Types.CMO_SUCCESS]: success,
    [Types.CMO_FAILURE]: failure,

    [Types.DATA_CMO_DPD_REQUEST]: requestcmotosap,
    [Types.DATA_CMO_DPD_SUCCESS]: succescmotosap,
    [Types.DATA_CMO_DPD_FAILURE]: failurecmotosap
})