export const GET_DETAIL_SALES = "@@SALES/Get detail SALES from server";
export const SET_HEADER_WEEK1 = "@@SALES/set header week 1";
export const SET_HEADER_WEEK2 = "@@SALES/set header week 2";
export const SET_HEADER_WEEK3 = "@@SALES/set header week 3";
export const SET_HEADER_WEEK4 = "@@SALES/set header week 4";
export const SET_DISTRIBUTOR_CODE = "@@SALES/set distributor code";
export const SET_DISTRIBUTOR_ID = "@@SALES/set distributor id";
export const SET_PRODUCT_TONASE_KUBIKASI = "@@SALES/set tonasi & kubikasi produk";

export const SET_HEADER_TONASE1 = "@@SALES/set header tonase 1";
export const SET_HEADER_TONASE2 = "@@SALES/set header tonase 2";
export const SET_HEADER_TONASE3 = "@@SALES/set header tonase 3";
export const SET_HEADER_TONASE4 = "@@SALES/set header tonase 4";

export const SET_HEADER_KUBIKASI1 = "@@SALES/set header kubikasi 1";
export const SET_HEADER_KUBIKASI2 = "@@SALES/set header kubikasi 2";
export const SET_HEADER_KUBIKASI3 = "@@SALES/set header kubikasi 3";
export const SET_HEADER_KUBIKASI4 = "@@SALES/set header kubikasi 4";

export const SET_HEADER_KENDARAAN1 = "@@SALES/set header kendaraan 1";
export const SET_HEADER_KENDARAAN2 = "@@SALES/set header kendaraan 2";
export const SET_HEADER_KENDARAAN3 = "@@SALES/set header kendaraan 3";
export const SET_HEADER_KENDARAAN4 = "@@SALES/set header kendaraan 4";

export const RESET = "@@SALES/reset data";
export const RESET_DETAIL = "@@SALES/reset data detail";

export const SET_TOTAL_ERROR = "@@SALES/total error data";
export const TOOGLE_SAVE_BUTTON = "@@SALES/Toogle Save Button";

export const SET_SUMMARY_BRAND = "@@SALES/Summary Brand Init";
export const SET_TOTAL_BRUTO = "@@SALES/Total Bruto Init";
export const SET_TOTAL_NETT = "@@SALES/Total Nett Init";
export const SET_SUMMARY_FOOTER = "@@SALES/Summary Footer";


