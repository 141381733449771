/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This deliveryOrder uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/
import React from 'react'
import { call, put, select } from 'redux-saga/effects'
import DeliveryOrderActions, { DeliveryOrderSelectors } from '../appRedux/reducers/DeliveryOrderRedux'
// import { v4 as uuidv4 } from 'uuid'
import { notification } from 'antd'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'
import _ from 'lodash'
import GetFileExt from '../util/GetFileExt'

function getRowMessage(arr) {
    return (
        <div>
            {
                arr.map(text => (<div>{text}<br /></div>) )
            }
        </div>
    ) 
}

export function * deliveryOrderRequest (api, action) {
    const { data, id } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.deliveryOrderRequest, data, id) 

    // success?
    if (response.ok) {
        // console.log('TEMP TEST deliveryOrderRequest', response.data)
        // You might need to change the response here - do this with a 'transform',
        // located in ../Transforms/. Otherwise, just pass the data back from the api.
        // console.log('deliveryOrder response.data', response.data)
        if (id) {
            yield put(DeliveryOrderActions.deliveryOrderSuccess(response.data.result))
        } else {
            yield put(DeliveryOrderActions.deliveryOrderSuccess(response.data.results, response.data.meta))
        }
    } else {
        yield put(DeliveryOrderActions.deliveryOrderFailure())
    }
}

export function * deliveryOrderDetailRequest (api, action) {
    const { datadetail, id } = action
    // console.log('test')
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.deliveryOrderRequest, datadetail, id) 
    // success?
    if (response.ok) {
        console.log('TEMP', JSON.stringify(response.data.result))
        // You might need to change the response here - do this with a 'transform',
        // located in ../Transforms/. Otherwise, just pass the data back from the api.
        yield put(DeliveryOrderActions.deliveryOrderDetailSuccess(response.data.result))
    } else {
        yield put(DeliveryOrderActions.deliveryOrderDetailFailure())
    }
}

export function * deliveryOrderSearchSapRequest (api, action) {
    const { datasearchsap, cb } = action 
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.deliveryOrderSearchSapRequest, datasearchsap)  
    // success?
    if (response.ok) {
        // const assignTempPid = response.data.results.map(x => {
        //     return {
        //         ...x,
        //         temp_id: uuidv4()
        //     }
        // })
        console.log('response.data', response.data)
        yield put(DeliveryOrderActions.deliveryOrderSearchSapSuccess(response.data.results))
        if (cb) cb(true)
    } else {
        yield put(DeliveryOrderActions.deliveryOrderSearchSapFailure())
        if (cb) cb(false)
    }
}

export function * deliveryOrderAssignRequest (api, action) {
    const { dataassign, cb } = action 
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.deliveryOrderAssignRequest, dataassign)   
    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    //const newData = UpdateArrayData(currentData, 'delivery_order_id', datacs.delivery_order_id, {status: statustext}) //array, key, modif data with
        console.log('assign ok', response.data.result)
        yield put(DeliveryOrderActions.deliveryOrderAssignSuccess(response.data.result))
        if (cb) cb(true, response.data.result)
    } else {
        if (response.data && response.data.meta && response.data.meta.message) {
            notification.error({
                message: 'Error',
                description: getRowMessage(response.data.meta.message), 
            });
        }
        yield put(DeliveryOrderActions.deliveryOrderAssignFailure())
        if (cb) cb(false)
    }
}

export function * deliveryOrderSaveRequest (api, action) {
    const { datasave, cb } = action 
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.deliveryOrderSaveRequest, datasave)   
    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    //const newData = UpdateArrayData(currentData, 'delivery_order_id', datacs.delivery_order_id, {status: statustext}) //array, key, modif data with
        console.log('save ok', response.data)
        yield put(DeliveryOrderActions.deliveryOrderSaveSuccess(response.data))
        if (cb) cb(true, response.data.result)
    } else {
        yield put(DeliveryOrderActions.deliveryOrderSaveFailure())
        if (cb) cb(false)
    }
} 

export function * deliveryOrderProveRequest (api, action) {
    const { dataprove, cb } = action 
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.deliveryOrderProveRequest, dataprove)   
    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    //const newData = UpdateArrayData(currentData, 'delivery_order_id', datacs.delivery_order_id, {status: statustext}) //array, key, modif data with
        console.log('do prove ok', response.data)
        yield put(DeliveryOrderActions.deliveryOrderProveSuccess(response.data))
        if (cb) cb(true, response.data)
    } else {
        if (response.data && response.data.meta && response.data.meta.message) {
            notification.error({
                message: 'Error',
                description: response.data.meta.message //getRowMessage(response.data.meta.message), 
            });
        } 
        yield put(DeliveryOrderActions.deliveryOrderProveFailure())
        if (cb) cb(false)
    }
}

export function * deliveryOrderUploadPodRequest (api, action) {
    const { datapod, cb } = action 

    let dokumentParam = {}
    let dokumentFile = {}
    for (const key in datapod) {
        if (key === 'file' && (datapod[key])) { 
            dokumentFile[key] = datapod[key]
        }  
    }
    for (const key in datapod) {
        if (key !== 'file') 
            dokumentParam[key] = datapod[key]
    }
    
    // eslint-disable-next-line no-undef
    const formdata = new FormData() 
    // formdata.append('document', JSON.stringify(dokumentParam))
    for (const key in datapod) {
        if (key !== 'file') 
            // dokumentParam[key] = datapod[key]
            // console.log('>>>', key, datapod[key])
            formdata.append(key, datapod[key])
    }

    for (const key in dokumentFile) {
        
        formdata.append('file', dokumentFile[key], dokumentParam['file_name']) 
    }  
    
    console.log('!!3', formdata)
    const response = yield call(api.deliveryOrderUploadPodRequest, formdata)   
    // success?
    if (response.ok) {
        console.log('do upload ok', response.data)
        yield put(DeliveryOrderActions.deliveryOrderUploadPodSuccess(response.data))
        if (cb) cb(true, response.data)
    } else {
        if (response.data && response.data.meta && response.data.meta.message) {
            notification.error({
                message: 'Error',
                description: response.data.meta.message //getRowMessage(response.data.meta.message), 
            });
        } 
        yield put(DeliveryOrderActions.deliveryOrderUploadPodFailure())
        if (cb) cb(false)
    }
}

export function * deliveryOrderChangeDriverRequest (api, action) {
    const { datachangedriver, cb } = action  
    const response = yield call(api.deliveryOrderChangeDriverRequest, datachangedriver)   
    // success?
    if (response.ok) {  
        
        console.log('re-assign driver ok', response.data)
        const currentDetail = yield select(DeliveryOrderSelectors.getPayloadDetail) //getPayloadDetail
        const incomingData = response.data.data

        const merge = {
            ...currentDetail,
            ...incomingData
        }

        yield put(DeliveryOrderActions.deliveryOrderSetPayloadDetail(merge))
        yield put(DeliveryOrderActions.deliveryOrderChangeDriverSuccess(response.data))
        if (cb) cb(true, response.data)
    } else {
        yield put(DeliveryOrderActions.deliveryOrderChangeDriverFailure())
        if (cb) cb(false)
    }
}

export function * deliveryOrderReadyInvoiceRequest (api, action) {
    const { datareadyinvoice } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.deliveryOrderReadyInvoiceRequest, datareadyinvoice) 
    console.log(JSON.stringify(response))
    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('deliveryOrderReadyInvoice response.data', response.data)
        yield put(DeliveryOrderActions.deliveryOrderReadyInvoiceSuccess(response.data.results))
    } else {
        yield put(DeliveryOrderActions.deliveryOrderReadyInvoiceFailure())
    }
}

export function * deliveryOrderMultiAssignRequest (api, action) {
    const { dataassign, cb } = action 
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.deliveryOrderMultiAssignRequest, dataassign)   
    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    //const newData = UpdateArrayData(currentData, 'delivery_order_id', datacs.delivery_order_id, {status: statustext}) //array, key, modif data with
        console.log('multi assign ok', response.data.results)
        yield put(DeliveryOrderActions.deliveryOrderMultiAssignSuccess(response.data.results))
        if (cb) cb(true, response.data.results)
    } else {
        if (response.data && response.data.meta && response.data.meta.message) {
            notification.error({
                message: 'Error',
                description: getRowMessage(response.data.meta.message), 
            });
        }
        yield put(DeliveryOrderActions.deliveryOrderMultiAssignFailure())
        if (cb) cb(false)
    }
}

export function * deliveryOrderEditRequest (api, action) {
    const { dataedit, cb } = action 
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.deliveryOrderEditRequest, dataedit)   
    // success?
    if (response.ok) {
        console.log('do edit ok', response.data)
    
        // const currentData = yield select(DeliveryOrderSelectors.getPayload) //getPayloadDetail
        // const incomingData = response.data.data

        // const merge = {
        //     ...currentData,
        //     ...incomingData
        // }

        // yield put(DeliveryOrderActions.deliveryOrderSuccess(merge))
        // yield put(DeliveryOrderActions.deliveryOrderEditSuccess(response.data.data))
        const currentData = yield select(DeliveryOrderSelectors.getPayload) 

        const curDataObject = _.keyBy(currentData, 'delivery_order_id')
        
        // eslint-disable-next-line no-undef
        const incomingData = _.keyBy([response.data.data], 'delivery_order_id') 
        
        const merge = _.values({ ... curDataObject, ...incomingData })
        
        yield put(DeliveryOrderActions.deliveryOrderSuccess(merge)) 
        
        yield put(DeliveryOrderActions.deliveryOrderEditSuccess(response.data.data))


        if (cb) cb(true, response.data)
    } else {
        if (response.data && response.data.meta && response.data.meta.message) {
            notification.error({
                message: 'Error',
                description: response.data.meta.message //getRowMessage(response.data.meta.message), 
            });
        } 
        yield put(DeliveryOrderActions.deliveryOrderEditFailure())
        if (cb) cb(false)
    }
}

export function * deliveryOrderDeleteRequest (api, action) {
    const { datadelete, cb } = action 
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.deliveryOrderDeleteRequest, datadelete)   
    // success?
    if (response.ok) {
        console.log('do delete ok', response.data) 

        const currentData = yield select(DeliveryOrderSelectors.getPayload) 
        const newDataObject = [...currentData.filter(x => x.delivery_order_id !== datadelete.delivery_order_id)] 
        
        yield put(DeliveryOrderActions.deliveryOrderSuccess(newDataObject))  

        yield put(DeliveryOrderActions.deliveryOrderDeleteSuccess(response.data))
        if (cb) cb(true, response.data)
    } else {
        if (response.data && response.data.meta && response.data.meta.message) {
            notification.error({
                message: 'Error',
                description: response.data.meta.message //getRowMessage(response.data.meta.message), 
            });
        } 
        yield put(DeliveryOrderActions.deliveryOrderDeleteFailure())
        if (cb) cb(false)
    }
}

export function * deliveryOrderBundleNotAssignedRequest (api, action) {
    const { databnar, id } = action
    // console.log('test')
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.deliveryOrderBundleNotAssignedRequest, databnar, id) 
    // success?
    if (response.ok) { 
        // console.log('tem', response.data.results)
        yield put(DeliveryOrderActions.deliveryOrderBundleNotAssignedSuccess(response.data.results))
    } else {
        yield put(DeliveryOrderActions.deliveryOrderBundleNotAssignedFailure())
    }
}