import React from 'react';
import { Route, Switch } from 'react-router-dom';

import asyncComponent from 'util/asyncComponent';
// const App = ({match}) => (
//   // <div className="gx-main-content-wrapper">
//   <div>
//     <Switch>
//       <Route exact path={`${match.url}sample`} component={asyncComponent(() => import('./SamplePage'))}/>
//       <Route exact path={`${match.url}dashboard-cmo`} component={asyncComponent(() => import('./DashboardCMO'))}/>
//       <Route path={`${match.url}dashboard-cmo/detail-cmo`} component={asyncComponent(() => import('./ViewCMO'))}/>
//       <Route path={`${match.url}create-cmo`} component={asyncComponent(() => import('./FormCMO'))}/>
//     </Switch>
//   </div>
// );

// eslint-disable-next-line no-empty-pattern
const App = ({ }) => {
    console.log('HEI')
    return (
        <div>
            <Switch>
                {/* MASTER PRODUK */}
                <Route exact path={'/master-produk'} component={asyncComponent(() => import('./MasterData/Produk/DataProduk'))} />
                {/* SALES ROLES */}
                <Route exact path={'/sales-org'} component={asyncComponent(() => import('./SalesRole/DataSales'))} />
                
                {/* DASHBOARD */}
                <Route exact path={`/dashboard`} component={asyncComponent(() => import('./DashboardProposal'))} />

                {/* DISKON B2B */}
                <Route exact path={'/diskon-b2b'} component={asyncComponent(() => import('./B2b/DataB2B'))} />
                <Route exact path={'/diskon-b2b/detail/:id'} component={asyncComponent(() => import('./B2b/DataB2BDetail'))} />

                <Route exact path={'/diskon-b2b/create-diskon-b2b'} component={asyncComponent(() => import('./B2b/DataB2BDetail'))} />

                {/* CMO */}
                <Route exact path={'/cmo'} component={asyncComponent(() => import('./CMO/DataCMO'))} />
                <Route path={'/cmo/dashboard-cmo/detail-cmo/:id'} component={asyncComponent(() => import('./CMO/ViewCMO'))} />
                <Route path={'/cmo/dashboard-cmo/create-cmo'} component={asyncComponent(() => import('./CMO/FormCMO'))} />
                <Route path={'/cmo/dashboard-cmo/edit-cmo/:id'} component={asyncComponent(() => import('./CMO/EditCMO'))} />

                <Route exact path={'/cmo-report'} component={asyncComponent(() => import('./CMO/ReportCMO'))} />

                {/* SALES */}
                <Route exact path={'/sales'} component={asyncComponent(() => import('./Sales/DataSales'))} />
                <Route path={'/sales/dashboard-sales/detail-sales/:id'} component={asyncComponent(() => import('./Sales/FormSales'))} />
                {/* <Route path={`/dashboard-sales/create-sales`} component={asyncComponent(() => import('./CMO/FormCMO'))}/> */}

                {/* FINANCE */}
                <Route exact path={'/finance'} component={asyncComponent(() => import('./Finance/DataFinance'))} />

                {/* HELLO WORLD */}
                <Route exact path={'/sayhelloworld'} component={asyncComponent(() => import('../pages/SayHelloWorld'))} />

                {/* Eproposal */}
                <Route exact path={'/list-proposal'} component={asyncComponent(() => import('./Proposal/DataProposal'))} />
                <Route exact path={'/list-all-proposal'} component={asyncComponent(() => import('./Proposal/DataProposal'))} />
                <Route exact path={'/create-proposal'} component={asyncComponent(() => import('./Proposal/CreateProposal'))} />
                <Route exact path={'/list-proposal/edit-proposal/:id?'} component={asyncComponent(() => import('./Proposal/CreateProposal'))} />
                <Route exact path={'/list-proposal/copy-proposal/:copy?/:id?'} component={asyncComponent(() => import('./Proposal/CreateProposal'))} />
                {/* //Cost Ratio */}
                <Route exact path={'/cost-rasio-proposal'} component={asyncComponent(() => import('./Proposal/DataCostRatio'))} />
                <Route exact path={'/cost-rasio-proposal/detail-proposal/:id?'} component={asyncComponent(() => import('./Proposal/DetailCostRatioProposal'))} />
                <Route exact path={'/history-cost-rasio-proposal'} component={asyncComponent(() => import('./Proposal/DataCostRatio'))} />
                <Route exact path={'/history-cost-rasio-proposal/detail-proposal/:id?'} component={asyncComponent(() => import('./Proposal/DetailCostRatioProposal'))} />

                {/* //ini route untuk detail-proposal, ingat karena detail-proposal adalah sub route dari list-proposal, tambahkan juga route list-proposalnya (lihat contoh di bawah) */}
                <Route exact path={'/list-proposal/detail-proposal/:id?'} component={asyncComponent(() => import('./Proposal/DetailProposal'))} />
                <Route exact path={'/list-all-proposal/detail-proposal/:id?'} component={asyncComponent(() => import('./Proposal/DetailProposal'))} />
                <Route exact path={'/copy-revisi-proposal'} component={asyncComponent(() => import('./Proposal/CopyRevisiProposal'))} />
                <Route exact path={'/copy-proposal'} component={asyncComponent(() => import('./Proposal/CopyProposal'))} />

                <Route exact path={'/approval-budget'} component={asyncComponent(() => import('./Proposal/ApprovalBudget'))} />
                <Route exact path={'/approve-reject-proposal'} component={asyncComponent(() => import('./Proposal/Approval'))} />
                <Route exact path={'/approve-reject-proposal/detail/:type?/:id?'} component={asyncComponent(() => import('./Proposal/DetailProposal'))} />

                {/* hist-approval */}
                <Route exact path={'/hist-approval'} component={asyncComponent(() => import('./Proposal/Approval'))} />

                {/* Budget */}
                <Route exact path={'/budget-brand'} component={asyncComponent(() => import('./Budget/BudgetBrand/DataBudgetBrand'))} />
                <Route exact path={'/budget-activity'} component={asyncComponent(() => import('./Budget/BudgetActivity/DataBudgetActivity'))} />
                <Route exact path={'/budget-activity-summary'} component={asyncComponent(() => import('./Budget/BudgetSummary/DataBudgetSummary'))} />
                <Route exact path={'/budget'} component={asyncComponent(() => import('./Budget/Budgeting/DataBudgeting'))} />

                {/* Master Data Activity */}
                <Route exact path={'/master-activity'} component={asyncComponent(() => import('./MasterData/Activity/DataActivity'))} />
                <Route exact path={'/master-activity/create'} component={asyncComponent(() => import('./MasterData/Activity/FormAddActivity'))} />
                <Route exact path={'/master-activity/edit/:id?'} component={asyncComponent(() => import('./MasterData/Activity/FormEditActivity'))} />

                {/* Master Data Brand */}
                <Route exact path={'/master-brand'} component={asyncComponent(() => import('./MasterData/Brand/DataBrand'))} />
                <Route exact path={'/master-brand/create'} component={asyncComponent(() => import('./MasterData/Brand/FormAddBrand'))} />
                <Route exact path={'/master-brand/edit/:id?'} component={asyncComponent(() => import('./MasterData/Brand/FormEditBrand'))} />

                {/* Master Data Budget */}
                <Route exact path={'/master-budget'} component={asyncComponent(() => import('./MasterData/Budget/DataBudget'))} />
                <Route exact path={'/add-master-budget'} component={asyncComponent(() => import('./MasterData/Budget/FormAddBudget'))} />
                {/*<Route exact path={'/edit-master-brand'} component={asyncComponent(() => import('./MasterData/Brand/FormEditBrand'))} />*/}

                {/* Master Data Budget Brand */}
                <Route exact path={'/master-budget-brand'} component={asyncComponent(() => import('./MasterData/BudgetBrand/DataBudgetBrand'))} />
                {/*<Route exact path={'/add-master-budget'} component={asyncComponent(() => import('./MasterData/Budget/FormAddBudget'))} />*/}
                {/*<Route exact path={'/edit-master-brand'} component={asyncComponent(() => import('./MasterData/Brand/FormEditBrand'))} />*/}

                {/* Master Data Pemindahan Budget */}
                <Route exact path={'/pemindahan-budget'} component={asyncComponent(() => import('./MasterData/PemindahanBudget/DataPemindahanBudget'))} />
                <Route exact path={'/pemindahan-budget/create'} component={asyncComponent(() => import('./MasterData/PemindahanBudget/FormAddPemindahanBudget'))} />
                <Route exact path={'/pemindahan-budget/import'} component={asyncComponent(() => import('./MasterData/PemindahanBudget/ImportPemindahanBudget'))} />

                {/* Master Data Employee */}
                <Route exact path={'/master-employee'} component={asyncComponent(() => import('./MasterData/Employee/DataEmployee'))} />
                <Route exact path={'/master-employee/create'} component={asyncComponent(() => import('./MasterData/Employee/FormAddEmployee'))} />
                <Route exact path={'/master-employee/edit/:id?'} component={asyncComponent(() => import('./MasterData/Employee/FormEditEmployee'))} />

                {/* Master Data Company */}
                <Route exact path={'/master-company'} component={asyncComponent(() => import('./MasterData/Company/DataCompany'))} />
                <Route exact path={'/master-company/create'} component={asyncComponent(() => import('./MasterData/Company/FormAddCompany'))} />
                <Route exact path={'/master-company/edit/:id?'} component={asyncComponent(() => import('./MasterData/Company/FormEditCompany'))} />

                {/* Master Data Division */}
                <Route exact path={'/master-division'} component={asyncComponent(() => import('./MasterData/Division/DataDivision'))} />
                <Route exact path={'/master-division/create'} component={asyncComponent(() => import('./MasterData/Division/FormAddDivision'))} />
                <Route exact path={'/master-division/edit/:id?'} component={asyncComponent(() => import('./MasterData/Division/FormEditDivision'))} />

                {/* Master Data Distributor */}
                <Route exact path={'/master-distributor'} component={asyncComponent(() => import('./MasterData/Distributor/DataDistributor'))} />
                <Route exact path={'/master-distributor/create'} component={asyncComponent(() => import('./MasterData/Distributor/FormAddDistributor'))} />
                <Route exact path={'/master-distributor/edit/:id?'} component={asyncComponent(() => import('./MasterData/Distributor/FormEditDistributor'))} />

                {/* Master Data Region */}
                <Route exact path={'/master-region'} component={asyncComponent(() => import('./MasterData/Region/DataRegion'))} />
                <Route exact path={'/master-region/create'} component={asyncComponent(() => import('./MasterData/Region/FormAddRegion'))} />
                <Route exact path={'/master-region/edit/:id?'} component={asyncComponent(() => import('./MasterData/Region/FormEditRegion'))} />

                {/* Master Data Variant */}
                <Route exact path={'/master-variant'} component={asyncComponent(() => import('./MasterData/Variant/DataVariant'))} />
                <Route exact path={'/master-variant/create'} component={asyncComponent(() => import('./MasterData/Variant/FormAddVariant'))} />
                <Route exact path={'/master-variant/edit/:id?'} component={asyncComponent(() => import('./MasterData/Variant/FormEditVariant'))} />

                {/* Master Data MarketType */}
                <Route exact path={'/master-market-type'} component={asyncComponent(() => import('./MasterData/MarketType/DataMarketType'))} />
                <Route exact path={'/master-market-type/create'} component={asyncComponent(() => import('./MasterData/MarketType/FormAddMarketType'))} />
                <Route exact path={'/master-market-type/edit/:id?'} component={asyncComponent(() => import('./MasterData/MarketType/FormEditMarketType'))} />

                {/* Master Data Workflow */}
                <Route exact path={'/workflow'} component={asyncComponent(() => import('./MasterData/Workflow/DataWorkflow'))} />
                <Route exact path={'/workflow/create'} component={asyncComponent(() => import('./MasterData/Workflow/FormAddWorkflow'))} />
                <Route exact path={'/workflow/edit/:id?'} component={asyncComponent(() => import('./MasterData/Workflow/FormEditWorkflow'))} />
                <Route exact path={'/workflow/detail/:id?'} component={asyncComponent(() => import('./MasterData/Workflow/DetailWorkflow'))} />

                {/*Report*/}
                <Route exact path={'/logbook'} component={asyncComponent(() => import('./Report/Logbook'))} />
                <Route exact path={'/payment-eprop'} component={asyncComponent(() => import('./Report/Payment'))} />

                {/* SHIPMENT */}
                <Route exact path={'/shipment'} component={asyncComponent(() => import('./Shipment/DataShipment'))} />

                {/* TRACKING */}
                <Route exact path={'/tracking'} component={asyncComponent(() => import('./Tracking'))} />

                {/* TRACKING PLAFOND */}
                <Route exact path={'/tracking-plafond'} component={asyncComponent(() => import('./TrackingPlafond/TrackingPlafondData'))} />
                <Route exact path={'/tracking-plafond/detail-tracking-plafond'} component={asyncComponent(() => import('./TrackingPlafond/DetailTrackingPlafond'))} />

                {/* DISTIRUBUTOR SALES DATA */}
                <Route exact path={'/distributor-sales-data'} component={asyncComponent(() => import('./DistributorSales/DistributorSalesData'))} />

                {/* DISTIRUBUTOR ACCOUNTING */}
                <Route exact path={'/distributor-accounting'} component={asyncComponent(() => import('./DistributorAccounting/DistributorAccountingData'))} />

                {/* DISTRIBUTOR LOGISTIK*/}
                <Route exact path={'/trucking'} component={asyncComponent(() => import('./Trucking'))} />

                {/* TRUCKING LOGISTIK*/}
                <Route exact path={'/distributor-logistik'} component={asyncComponent(() => import('./DistributorLogistik/DistributorLogistikData'))} />

                {/* TRACKING ORDER*/}
                <Route exact path={'/tracking-order'} component={asyncComponent(() => import('./TrackingOrder/TrackingOrderData'))} />

                {/* TRACKING ORDER DETAIL*/}
                <Route exact path={'/tracking-order/tracking-order-detail'} component={asyncComponent(() => import('./TrackingOrder/TrackingOrderView'))} />

                {/* Tracking DO Driver*/}

                <Route exact path={'/tracking-do-driver'} component={asyncComponent(() => import('./TrackingDoDriver'))} />
                {/* Tracking DO Detail*/}
                <Route exact path={'/tracking-do-driver/:data/:id'} component={asyncComponent(() => import('./TrackingDoDriver/TrackingDoDetail'))} />

                {/* BIDDING INFORMATION */}
                <Route exact path={'/bidding-information'} component={asyncComponent(() => import('./BiddingInformation/DataBiddingInformation'))} />

                {/* SAMPLE FILTER */}
                <Route exact path={'/samplefilter'} component={asyncComponent(() => import('./SampleFilter/Data'))} />

                {/* RPO */}
                <Route exact path={'/rpo'} component={asyncComponent(() => import('./RPO/DataCMO'))} />
                <Route path={'/rpo/dashboard-rpo/detail-rpo/:id'} component={asyncComponent(() => import('./RPO/ViewCMO'))} />
                <Route path={'/rpo/dashboard-rpo/create-rpo'} component={asyncComponent(() => import('./RPO/FormCMO'))} />
                <Route path={'/rpo/dashboard-rpo/edit-rpo/:id'} component={asyncComponent(() => import('./RPO/EditCMO'))} />

                <Route exact path={'/rpo-report'} component={asyncComponent(() => import('./RPO/ReportCMO'))} />

                {/* DELIVERY ORDER */}
                <Route exact path={'/do'} component={asyncComponent(() => import('./DeliveryOrder/DeliveryOrderData'))} />
                <Route exact path={'/do/detail-do/:id?'} component={asyncComponent(() => import('./DeliveryOrder/DeliveryOrderDetail'))} />
                <Route exact path={'/do/search-do'} component={asyncComponent(() => import('./DeliveryOrder/SearchDO'))} />

                {/* TERM OF PAYMENT */}
                <Route exact path={'/top'} component={asyncComponent(() => import('./TermOfPayment/TermOfPaymentData'))} />

                {/* APPROVE TERM OF PAYMENT */}
                <Route exact path={'/app-top'} component={asyncComponent(() => import('./ApproveTOP/ApproveTopData'))} />

                {/* SUBMIT FAKTUR */}
                <Route exact path={'/submit-faktur'} component={asyncComponent(() => import('./SubmitFaktur/SubmitFakturData'))} />

                {/* NOTA PENGIRIMAN BARANG */}
                <Route exact path={'/npb'} component={asyncComponent(() => import('./NotaPengirimanBarang/NotaPengirimanBarangData'))} />

                {/* CONTRACT DEAL */}
                <Route exact path={'/contract-deal'} component={asyncComponent(() => import('./ContractDeal/ContractDealData'))} />

                {/* DRIVER */}
                <Route exact path={'/master-driver'} component={asyncComponent(() => import('./Driver/DriverData'))} />

                {/* FKR BARANG*/}
                <Route exact path={'/fkr-barang'} component={asyncComponent(() => import('./FKR/DataFKR'))} />
                {/* <Route path={`/fkr-barang/create-fkr`} component={asyncComponent(() => import('./FKR/AddFKR'))} /> */}
                <Route path={'/fkr-barang/create-fkr/:id?'} component={asyncComponent(() => import('./FKR/AddFKR'))} />
                {/* FKR PEMUSNAHAN*/}
                <Route exact path={'/fkr-pemusnahan'} component={asyncComponent(() => import('./FKR/DataFKR')  /*import('./Pemusnahan/DataFKR')*/)} />
                {/* <Route path={`/fkr-pemusnahan/create-fkr`} component={asyncComponent(() => import('./FKR/AddFKR'))} /> */}
                <Route path={'/fkr-pemusnahan/create-fkr/:id?'} component={asyncComponent(() => import('./FKR/AddFKR') /*import('./Pemusnahan/AddFKR')*/)} />
                {/* FKR pengalihan*/}
                <Route exact path={'/fkr-pengalihan'} component={asyncComponent(() => import('./FKR/DataFKR')  /*import('./Pemusnahan/DataFKR')*/)} />
                {/* <Route path={`/fkr-pemusnahan/create-fkr`} component={asyncComponent(() => import('./FKR/AddFKR'))} /> */}
                <Route path={'/fkr-pengalihan/create-fkr/:id?'} component={asyncComponent(() => import('./FKR/AddFKR'))} />


                {/* FKR EXECUTION */}
                {/* FKR EXECUTION PENGEMBALIAN */}
                <Route exact path={'/fkr-excecution'} component={asyncComponent(() => import('./FKRExecution/DataFKRExecution'))} />
                <Route path={'/fkr-excecution/detail-fkr-execution/:id'} component={asyncComponent(() => import('./FKRExecution/DetailFKRExecution'))} />
                {/* FKR EXECUTION PEMUSNAHAN */}
                <Route exact path={'/fkr-excecution-pemusnahan'} component={asyncComponent(() => import('./FKRExecution/DataFKRExecution'))} />
                <Route path={'/fkr-excecution-pemusnahan/detail-fkr-execution/:id'} component={asyncComponent(() => import('./FKRExecution/DetailFKRExecution'))} />
                {/* FKR EXECUTION PENGALIHAN */}
                <Route exact path={'/fkr-excecution-pengalihan'} component={asyncComponent(() => import('./FKRExecution/DataFKRExecution'))} />
                <Route path={'/fkr-excecution-pengalihan/detail-fkr-execution/:id'} component={asyncComponent(() => import('./FKRExecution/DetailFKRExecution'))} />

                {/* APPROVE FKR */}
                {/* APPROVE FKR PENGEMBALIAN */}
                <Route exact path={'/approve-pengembalian'} component={asyncComponent(() => import('./FKRExecution/DataFKRExecution'))} />
                <Route path={'/approve-pengembalian/detail-fkr-execution/:id'} component={asyncComponent(() => import('./FKRExecution/DetailFKRExecution'))} />
                {/* APPROVE FKR PEMUSNAHAN */}
                <Route exact path={'/approve-pemusnahan'} component={asyncComponent(() => import('./FKRExecution/DataFKRExecution'))} />
                <Route path={'/approve-pemusnahan/detail-fkr-execution/:id'} component={asyncComponent(() => import('./FKRExecution/DetailFKRExecution'))} />
                {/* APPROVE FKR PENGALIHAN */}
                <Route exact path={'/approve-pengalihan'} component={asyncComponent(() => import('./FKRExecution/DataFKRExecution'))} />
                <Route path={'/approve-pengalihan/detail-fkr-execution/:id'} component={asyncComponent(() => import('./FKRExecution/DetailFKRExecution'))} />


                {/* KLAIM PROPOSAL */}
                <Route exact path={'/klaim-proposal'} component={asyncComponent(() => import('./ClaimProposal/DataClaimProposal'))} />
                <Route exact path={'/klaim-proposal/menu-klaim-proposal/:id?'} component={asyncComponent(() => import('./ClaimProposal/MenuClaimProposal'))} />
                <Route exact path={'/klaim-proposal/detail-klaim-proposal/:id?'} component={asyncComponent(() => import('./ClaimProposal/CheckApproveProposal'))} />

                {/* APPROVE KLAIM PROPOSAL */}
                <Route exact path={'/app-proposal-klaim'} component={asyncComponent(() => import('./ApproveClaimProposal/DataApproveProposal'))} />
                <Route exact path={'/app-proposal-klaim/check-app-proposal-klaim/:id?'} component={asyncComponent(() => import('./ApproveClaimProposal/CheckApproveProposal'))} />

                {/* FAKTUR TRANSPORTER */}
                <Route exact path={'/faktur-transporter'} component={asyncComponent(() => import('./FakturTransporter/DataFakturTransporter'))} />


                {/* FKR approve-pengembalian DEDE */}
                {/* approve-pengembalian-log */}
                <Route exact path={'/approve-pengembalian-log'} component={asyncComponent(() => import('./FKRExecution/DataFKRExecution'))} />
                <Route path={'/approve-pengembalian-log/detail-fkr-execution/:id'} component={asyncComponent(() => import('./FKRExecution/DetailFKRExecution'))} />
                {/* approve-pemusnahan-log */}
                <Route exact path={'/approve-pemusnahan-log'} component={asyncComponent(() => import('./FKRExecution/DataFKRExecution'))} />
                <Route path={'/approve-pemusnahan-log/detail-fkr-execution/:id'} component={asyncComponent(() => import('./FKRExecution/DetailFKRExecution'))} />
                {/* approve-pengalihan-log */}
                <Route exact path={'/approve-pengalihan-log'} component={asyncComponent(() => import('./FKRExecution/DataFKRExecution'))} />
                <Route path={'/approve-pengalihan-log/detail-fkr-execution/:id'} component={asyncComponent(() => import('./FKRExecution/DetailFKRExecution'))} />

                {/* Kumpulan LIST ALL */}
                <Route exact path={'/approve-proses-log'} component={asyncComponent(() => import('./FKRExecution/DataFKRExecution'))} />
                <Route path={'/approve-proses-log/detail-fkr-execution/:id'} component={asyncComponent(() => import('./FKRExecution/DetailFKRExecution'))} />
                <Route exact path={'/approve-proses-acc'} component={asyncComponent(() => import('./FKRExecution/DataFKRExecution'))} />
                <Route path={'/approve-proses-acc/detail-fkr-execution/:id'} component={asyncComponent(() => import('./FKRExecution/DetailFKRExecution'))} />
                <Route exact path={'/approve-proses-sales'} component={asyncComponent(() => import('./FKRExecution/DataFKRExecution'))} />
                <Route path={'/approve-proses-sales/detail-fkr-execution/:id'} component={asyncComponent(() => import('./FKRExecution/DetailFKRExecution'))} />
                <Route exact path={'/approve-proses-asdh'} component={asyncComponent(() => import('./FKRExecution/DataFKRExecution'))} />
                <Route path={'/approve-proses-asdh/detail-fkr-execution/:id'} component={asyncComponent(() => import('./FKRExecution/DetailFKRExecution'))} />


                {/* FKR approve-pengembalian ACC */}
                {/* approve-pengembalian-acc */}
                <Route exact path={'/approve-pengembalian-acc'} component={asyncComponent(() => import('./FKRExecution/DataFKRExecution'))} />
                <Route path={'/approve-pengembalian-acc/detail-fkr-execution/:id'} component={asyncComponent(() => import('./FKRExecution/DetailFKRExecution'))} />
                {/* approve-pemusnahan-acc */}
                <Route exact path={'/approve-pemusnahan-acc'} component={asyncComponent(() => import('./FKRExecution/DataFKRExecution'))} />
                <Route path={'/approve-pemusnahan-acc/detail-fkr-execution/:id'} component={asyncComponent(() => import('./FKRExecution/DetailFKRExecution'))} />
                {/* approve-pengalihan-acc */}
                <Route exact path={'/approve-pengalihan-acc'} component={asyncComponent(() => import('./FKRExecution/DataFKRExecution'))} />
                <Route path={'/approve-pengalihan-acc/detail-fkr-execution/:id'} component={asyncComponent(() => import('./FKRExecution/DetailFKRExecution'))} />

                {/* USER */}
                <Route exact path={'/users'} component={asyncComponent(() => import('./User/DataUser'))} />

                {/* JENIS ORGANISASI */}
                <Route exact path={'/jenis-organisasi'} component={asyncComponent(() => import('./JenisOrganisasi/DataJenisOrganisasi'))} />

                {/* ROLE */}
                <Route exact path={'/role'} component={asyncComponent(() => import('./Role/DataRole'))} />

                {/* MENU */}
                <Route exact path={'/menus'} component={asyncComponent(() => import('./Menu/DataMenu'))} />

                {/* REPORT FKR */}
                <Route exact path={'/fkr-excecution-report'} component={asyncComponent(() => import('./FKR/ReportFKR'))} />

                {/* ORGANISASI */}
                <Route exact path={'/orgs'} component={asyncComponent(() => import('./Organisasi/DataOrganisasi'))} />

                {/* MENU BY ROLE */}
                <Route exact path={'/role-menus'} component={asyncComponent(() => import('./MenuByRole/DataRoleMenu'))} />

                {/* USER ORGANISASI */}
                <Route exact path={'/user-organisasi'} component={asyncComponent(() => import('./UserOrganisasi/DataUserOrganisasi'))} />

                {/* Trigger Logout */}
                <Route exact path={'/logout-sso'} component={asyncComponent(() => import('./Logout'))} />
            </Switch>
        </div>
    )
};

export default App;
