/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This budgetBrand uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put } from 'redux-saga/effects'
import BrandActions from '../../appRedux/reducers/MasterData/BrandRedux'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * getMasterBrand (api, action) {
    const { data } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.getMasterBrand, data)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetBrand response.data', response.data)
        yield put(BrandActions.masterBrandSuccess(response.data.results, response.data.meta))
    } else {
        yield put(BrandActions.masterBrandFailure())
    }
}

export function * getDetailMasterBrand (api, action) {
    const { data, id } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.getMasterBrand, data, id)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetBrand response.data', response.data)
        let division = response.data.result.division
        division = division.map(v => v.division_code)
        yield put(BrandActions.masterBrandDetailSuccess({
            ...response.data.result,
            // division: division.join(',')
        }))
    } else {
        yield put(BrandActions.masterBrandDetailFailure())
    }
}

export function * createMasterBrand (api, action) {
    const { data, util: { info, callback } } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.postMasterBrand, data)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetBrand response.data', response.data)
        info.success(response.data.message)
        callback()
        yield put(BrandActions.masterBrandCreateSuccess())
    } else {
        info.error(response.data.message)
        yield put(BrandActions.masterBrandCreateFailure())
    }
}

export function * editMasterBrand (api, action) {
    const { data, util: { info, callback } } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.putMasterBrand, data)
    
    console.log('response EDIT', response)
    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetBrand response.data', response.data)
        info.success(response.data.message)
        callback()
        yield put(BrandActions.masterBrandEditSuccess())
    } else {
        info.error(response.data.message)
        yield put(BrandActions.masterBrandEditFailure())
    }
}

export function * deleteMasterBrand (api, action) {
    const { data, id, info } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.deleteMasterBrand, data, id)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetBrand response.data', response.data)
        info.success(response.data.message)
        yield put(BrandActions.masterBrandDeleteSuccess())
        yield put(BrandActions.masterBrandRequest())
    } else {
        info.error(response.data.message)
        yield put(BrandActions.masterBrandDeleteFailure())
    }
}
