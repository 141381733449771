import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import createRootReducer from '../reducers';
//ignite pattern
import rootSaga from '../../Sagas/'
import createSagaMiddleware from 'redux-saga'

const createBrowserHistory = require('history').createBrowserHistory;

export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);

//ignite pattern
const sagaMiddleware = createSagaMiddleware()

const middlewares = [thunk, routeMiddleware, sagaMiddleware];


export default function configureStore(preloadedState) {
    const store = createStore(
        createRootReducer(history), // root reducer with router state
        preloadedState,
        composeWithDevTools(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                ...middlewares
            )
        )
    );

    //ignite pattern
    let sagasManager = sagaMiddleware.run(rootSaga)
    console.log('sagasManager', sagasManager)
    // const newYieldedSagas = require('../../Sagas').default
    //   sagasManager.cancel()
    //   console.log('newYieldedSagas', newYieldedSagas)
    // sagasManager.done.then(() => {
    // sagasManager = sagaMiddleware(newYieldedSagas)
    // })

    return store;
}
