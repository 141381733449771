/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This budgetDivision uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put } from 'redux-saga/effects'
import DivisionActions from '../../appRedux/reducers/MasterData/DivisionRedux'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * getMasterDivision (api, action) {
  const { data } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.getMasterDivision, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetDivision response.data', response.data)
    yield put(DivisionActions.masterDivisionSuccess(response.data.results, response.data.meta))
  } else {
    yield put(DivisionActions.masterDivisionFailure())
  }
}

export function * getDetailMasterDivision (api, action) {
  const { data, id } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.getMasterDivision, data, id)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetDivision response.data', response.data)
    yield put(DivisionActions.masterDivisionDetailSuccess(response.data.result))
  } else {
    yield put(DivisionActions.masterDivisionDetailFailure())
  }
}

export function * createMasterDivision (api, action) {
  const { data, util: { info, callback } } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.postMasterDivision, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetDivision response.data', response.data)
    info.success(response.data.message)
    callback()
    yield put(DivisionActions.masterDivisionCreateSuccess())
  } else {
    info.error(response.data.message)
    yield put(DivisionActions.masterDivisionCreateFailure())
  }
}

export function * editMasterDivision (api, action) {
  const { data, util: { info, callback } } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.putMasterDivision, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetDivision response.data', response.data)
    info.success(response.data.message)
    callback()
    yield put(DivisionActions.masterDivisionEditSuccess())
  } else {
    info.error(response.data.message)
    yield put(DivisionActions.masterDivisionEditFailure())
  }
}

export function * deleteMasterDivision (api, action) {
  const { data, id, info } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.deleteMasterDivision, data, id)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetDivision response.data', response.data)
    info.success(response.data.message)
    yield put(DivisionActions.masterDivisionDeleteSuccess())
    yield put(DivisionActions.masterDivisionRequest())
  } else {
    info.error(response.data.message)
    yield put(DivisionActions.masterDivisionDeleteFailure())
  }
}
