import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input, Row, Col } from "antd";
import { Link } from "react-router-dom";

import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import "./styles.scss";
import CardBox from "../components/CardBox";

const FormItem = Form.Item;

const ForgetPassword = () => {

    return (
        <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
                <div className="forget-password">
                    <CardBox style={{ padding: '30px 20px 20px' }}>
                        <Row type="flex" justify="center" >
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <h1 style={{ fontWeight: 600, textAlign: 'center' }}>Forget Password?</h1>
                            </Col>
                        </Row>
                        <Row type="flex" justify="start" style={{marginTop: -45}}>
                            <Col>
                                <div className="btn-back">
                                    <Link to="/signin">
                                        <Button shape="circle" icon="left" size="large" style={{color: '#004926'}} color='#004926'></Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                        <Row type="flex" justify="center" style={{ marginTop: 10 }}>
                            <Col xs={24} sm={24} md={23} lg={23} xl={23}>
                                <p><IntlMessages id="Enter the email address you used when you joined and we’ll send you instructions to reset your password." /></p>
                            </Col>
                        </Row>
                        <Row type="flex" justify="center">
                            <Col xs={24} sm={24} md={23} lg={23} xl={23}>
                                <p><IntlMessages id="For security reasons, we do NOT store your password. So rest assured that we will never send your password via email." /></p>
                            </Col>
                        </Row>
                        <Row type="flex" justify="center">
                            <Col xs={24} sm={24} md={23} lg={23} xl={23}>
                                <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                    <FormItem label="Email Address" rules={[{
                                        required: true, message: 'Please input your Email Address!',
                                    }]}>
                                        <Input placeholder="Username" />
                                    </FormItem>
                                    <FormItem>
                                        <Row type="flex" justify="center">
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <div className="btn-submit">
                                                    <Button type="primary" className="gx-mb-0" htmlType="submit" style={{ backgroundColor: '#004926 !important', borderColor: '#004926 !important' }}>
                                                        <IntlMessages id="Send Reset Instructions" />
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </FormItem>
                                </Form>
                            </Col>
                        </Row>
                    </CardBox>
                    <InfoView />
                </div>
            </div>
        </div >
    );
};

export default ForgetPassword;
