/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This budgetPosition uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put } from 'redux-saga/effects'
import PositionActions from '../../appRedux/reducers/MasterData/PositionRedux'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * getMasterPosition (api, action) {
  const { data } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.getMasterPosition, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetPosition response.data', response.data)
    yield put(PositionActions.masterPositionSuccess(response.data.results, response.data.meta))
  } else {
    yield put(PositionActions.masterPositionFailure())
  }
}

export function * getDetailMasterPosition (api, action) {
  const { data, id } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.getMasterPosition, data, id)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetPosition response.data', response.data)
    yield put(PositionActions.masterPositionDetailSuccess(response.data.result))
  } else {
    yield put(PositionActions.masterPositionDetailFailure())
  }
}

export function * createMasterPosition (api, action) {
  const { data, util: { info, callback } } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.postMasterPosition, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetPosition response.data', response.data)
    info.success(response.data.message)
    callback()
    yield put(PositionActions.masterPositionCreateSuccess())
  } else {
    info.error(response.data.message)
    yield put(PositionActions.masterPositionCreateFailure())
  }
}

export function * editMasterPosition (api, action) {
  const { data, util: { info, callback } } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.putMasterPosition, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetPosition response.data', response.data)
    info.success(response.data.message)
    callback()
    yield put(PositionActions.masterPositionEditSuccess())
  } else {
    info.error(response.data.message)
    yield put(PositionActions.masterPositionEditFailure())
  }
}

export function * deleteMasterPosition (api, action) {
  const { data, id, info } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.deleteMasterPosition, data, id)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetPosition response.data', response.data)
    info.success(response.data.message)
    yield put(PositionActions.masterPositionDeleteSuccess())
    yield put(PositionActions.masterPositionRequest())
  } else {
    info.error(response.data.message)
    yield put(PositionActions.masterPositionDeleteFailure())
  }
}
