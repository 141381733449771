/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This budgetDistributor uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put } from 'redux-saga/effects'
import DistributorActions from '../../appRedux/reducers/MasterData/DistributorRedux'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * getMasterDistributor (api, action) {
  const { data } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.getMasterDistributor, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetDistributor response.data', response.data)
    yield put(DistributorActions.masterDistributorSuccess(response.data.results, response.data.meta))
  } else {
    yield put(DistributorActions.masterDistributorFailure())
  }
}

export function * getDetailMasterDistributor (api, action) {
  const { data, id } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.getMasterDistributor, data, id)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetDistributor response.data', response.data)
    yield put(DistributorActions.masterDistributorDetailSuccess(response.data.result))
  } else {
    yield put(DistributorActions.masterDistributorDetailFailure())
  }
}

export function * createMasterDistributor (api, action) {
  const { data, util: { info, callback } } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.postMasterDistributor, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetDistributor response.data', response.data)
    info.success(response.data.message)
    callback()
    yield put(DistributorActions.masterDistributorCreateSuccess())
  } else {
    info.error(response.data.message)
    yield put(DistributorActions.masterDistributorCreateFailure())
  }
}

export function * editMasterDistributor (api, action) {
  const { data, util: { info, callback } } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.putMasterDistributor, data)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetDistributor response.data', response.data)
    info.success(response.data.message)
    callback()
    yield put(DistributorActions.masterDistributorEditSuccess())
  } else {
    info.error(response.data.message)
    yield put(DistributorActions.masterDistributorEditFailure())
  }
}

export function * deleteMasterDistributor (api, action) {
  const { data, id, info } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.deleteMasterDistributor, data, id)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetDistributor response.data', response.data)
    info.success(response.data.message)
    yield put(DistributorActions.masterDistributorDeleteSuccess())
    yield put(DistributorActions.masterDistributorRequest())
  } else {
    info.error(response.data.message)
    yield put(DistributorActions.masterDistributorDeleteFailure())
  }
}
