/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This jenisOrganisasi uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, select } from 'redux-saga/effects'
import JenisOrganisasiActions, { JenisOrganisasiSelectors } from '../appRedux/reducers/JenisOrganisasiRedux'
import { notification } from 'antd';
import _ from 'lodash' 
export function * jenisOrganisasiRequest (api, action) {
    const { data, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.jenisOrganisasiRequest, data)

    // success?
    if (response.ok) {
        var dataresult = response.data.results

        yield put(JenisOrganisasiActions.jenisOrganisasiSuccess(dataresult, response.data.meta))
        if (cb) cb(true, response.data.results)
    } else {
        yield put(JenisOrganisasiActions.jenisOrganisasiFailure())
        if (cb) cb(false)
    }
}

export function * jenisOrganisasiDetailRequest (api, action) {
    const { datadetail, id } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.jenisOrganisasiRequest, datadetail, id) 

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        console.log('jenisOrganisasiDetail response.data', response.data.result)
        yield put(JenisOrganisasiActions.jenisOrganisasiDetailSuccess(response.data.result))
    } else {
        yield put(JenisOrganisasiActions.jenisOrganisasiDetailFailure())
    }
}

export function * jenisOrganisasiAddRequest (api, action) {
    const { dataadd, cb } = action
    // get current data from Store
    
    // make the call to the api
    const response = yield call(api.jenisOrganisasiAddRequest, dataadd) 

    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Data jenisOrganisasi Added',
            placement: 'topRight',
        }); 
 
        console.log("response data",response.data)

        yield put(JenisOrganisasiActions.jenisOrganisasiAddSuccess(response.data.result))  
        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when creating Data jenisOrganisasi, Please try again',
            placement: 'topRight',
        });
        yield put(JenisOrganisasiActions.jenisOrganisasiAddFailure())
        if (cb) cb(false)
    }
}

export function * jenisOrganisasiEditRequest (api, action) {
    const { dataedit, cb } = action
    // get current data from Store
    // make the call to the api
    const response = yield call(api.jenisOrganisasiEditRequest, dataedit) 
  
    // success?
    if (response.ok) {
        let currentData = _.cloneDeep(yield select(JenisOrganisasiSelectors.getPayload))
        currentData = _.keyBy(currentData, 'r_jenis_organisasi_id')
        Object.assign(currentData[dataedit.id], dataedit);

        notification.success({
            message: 'Success',
            description: 'Data jenisOrganisasi Modified',
            placement: 'topRight',
        });


        yield put(JenisOrganisasiActions.jenisOrganisasiEditSuccess(response.data.result))
        yield put(JenisOrganisasiActions.jenisOrganisasiSuccess(_.values(currentData)))

        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when Modifying Data jenisOrganisasi, Please try again',
            placement: 'topRight',
        });
        yield put(JenisOrganisasiActions.jenisOrganisasiEditFailure())
        if (cb) cb(false)
    }
}

export function * jenisOrganisasiDeleteRequest (api, action) {
    const { datadelete, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.jenisOrganisasiDeleteRequest, datadelete) 

    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Data jenisOrganisasi Deleted',
            placement: 'topRight',
        });

        yield put(JenisOrganisasiActions.jenisOrganisasiDeleteSuccess(datadelete))
        if (cb) cb(true)
    } else {
        notification.error({
            message: 'Failed',
            description: 'Error when Deleting Data jenisOrganisasi, Please try again',
            placement: 'topRight',
        });
        yield put(JenisOrganisasiActions.jenisOrganisasiDeleteFailure())
        if (cb) cb(false)
    }
}