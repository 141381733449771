/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This klaimProposal uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put, select } from 'redux-saga/effects'
import KlaimProposalActions, { KlaimProposalSelectors } from '../appRedux/reducers/KlaimProposalRedux'
import { notification, message } from 'antd';
import _ from 'lodash'
import GetFileExt from '../util/GetFileExt';

export function * klaimProposalRequest (api, action) {
    const { data, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.klaimProposalListRequest, data)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
        console.log('klaimProposal response.data', response.data)
        yield put(KlaimProposalActions.klaimProposalSuccess(response.data.results, response.data.meta))
        if (cb) cb(true, response.data.results)
    } else {
        yield put(KlaimProposalActions.klaimProposalFailure())
        if (cb) cb(false)
    }
}

export function * klaimProposalDetailRequest (api, action) {
    const { datadetail, id, cb, cekdivisi } = action
    // get current data from Store
    const currentDivisi = yield select(KlaimProposalSelectors.getDivisi)
    // make the call to the api
    const response = yield call(api.klaimProposalRequest, datadetail, id) 

    // success?
    if (response.ok) {
        if (response.data.error) {
            notification.error({
                message: 'Failed',
                description: response.data.message,
                placement: 'topRight',
            });
            yield put(KlaimProposalActions.klaimProposalDetailFailure())
            return 
        } 
        console.log('klaimProposalDetail response.data', currentDivisi, response.data.result.divisi)
        if (cekdivisi) {
            if (currentDivisi !== null && currentDivisi !== response.data.result.divisi) {
                if (cb) cb(false)
                notification.error({
                    message: 'Failed',
                    description: 'Channel Proposal tidak valid',
                    placement: 'topRight',
                });
                yield put(KlaimProposalActions.klaimProposalDetailFailure())
                return
            }
        }
        yield put(KlaimProposalActions.klaimProposalDetailSuccess(response.data.result))
        if (cb) cb(true, response.data.result)
        if (!response.data.result.proposal_id) {
            // message.error('Nomor Tidak Ditemukan !!!')
        } else {
            // message.success('Nomor Valid !!!')
        }
    } else {
        if (cb) cb(false)
        yield put(KlaimProposalActions.klaimProposalDetailFailure())
    }
}

export function * klaimProposalAddRequest (api, action) {
    const { dataadd, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api

    let dokumentParam = {}
    let dokumentFile = {}

    for (const key in dataadd) {
        if (key.includes('file_') && (dataadd[key])) {
            if (!dataadd[key].isremote) {
                dokumentFile[key] = dataadd[key]//dokumentFile.push(dataadd[key])
            }
            dokumentParam[key] = key+GetFileExt(dataadd[key].name) 
        } else {
            dokumentParam[key] = ''
        }
    }
    for (const key in dataadd) {
        if (!key.includes('file_')) 
            dokumentParam[key] = dataadd[key]
    }
    console.log('param', JSON.stringify(dokumentParam))
    console.log('file', dokumentFile)
    // return //wait
    // eslint-disable-next-line no-undef
    const formdata = new FormData() 
    formdata.append('document', JSON.stringify(dokumentParam))
    for (const key in dokumentFile) {
        formdata.append('file', dokumentFile[key], `${key}${GetFileExt(dokumentFile[key].name)}`) 
    }  
    // console.log('mulai response 1', formdata)
    const response = yield call(api.klaimProposalAddRequest, formdata) 
    // console.log('propsal error resp', JSON.stringify(response))
    // console.log('END mulai response 1')
    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: 'Proposal Berhasil',
            placement: 'topRight',
        });

        console.log('klaimProposalAdd response.data', response.data)
        yield put(KlaimProposalActions.klaimProposalAddSuccess(response.data.data))
        if (cb) cb(true)
    } else {
        console.log('response gagal', response.data)
        let err = (response.data && response.data.meta && response.data.meta.message) ? response.data.meta.message : 'Error when Processing Claim'
        notification.error({
            message: 'Failed',
            description: err,
            placement: 'topRight',
        });
        yield put(KlaimProposalActions.klaimProposalAddFailure())
        if (cb) cb(false)
    }
}

export function * klaimProposalArsRequest (api, action) {
    const { act, dataars, cb } = action

    let param = undefined

    if (act === 'apf') {
        //ini approve
        // eslint-disable-next-line no-undef
        const formdata = new FormData() 
        for (const key in dataars) {
            console.log('')
            formdata.append(key, dataars[key])   
        }
        param = formdata
        
    } else {
        //selain approve (bukan formdata)
        param = dataars
    }

    const response = yield call(api.klaimProposalArsRequest, act, /*dataars*/ param) 

    // success?
    if (response.ok) {      
        //disini kasih pesan
        notification.success({
            message: 'Success',
            description: response.data.message,
            placement: 'topRight',
        })
        yield put(KlaimProposalActions.klaimProposalArsSuccess(response.data)) 
        if (cb) cb(true)
    } else {
        let err = (response.data && response.data.meta && response.data.meta.message) ? response.data.meta.message : 'Error when Modifying Data Faktur, Please try again'
        notification.error({
            message: 'Failed',
            description: err,
            placement: 'topRight',
        });
        yield put(KlaimProposalActions.klaimProposalArsFailure())
        if (cb) cb(false)
    }
} 

export function * klaimProposalPayRequest (api, action) {
    const { datapay, cb } = action
 

    const response = yield call(api.klaimProposalPayRequest, datapay) 

    // success?
    if (response.ok) {      
        //disini kasih pesan
        notification.success({
            message: 'Success',
            description: response.data.message,
            placement: 'topRight',
        })
        yield put(KlaimProposalActions.klaimProposalPaySuccess(response.data)) 
        if (cb) cb(true)
    } else {
        let err = (response.data && response.data.meta && response.data.meta.message) ? response.data.meta.message : 'Error when Modifying Data Faktur, Please try again'
        notification.error({
            message: 'Failed',
            description: err,
            placement: 'topRight',
        });
        yield put(KlaimProposalActions.klaimProposalPayFailure())
        if (cb) cb(false)
    }
} 

export function * klaimProposalEditRequest (api, action) {
    const { dataedit, cb } = action
  
    let dokumentParam = {}
    let dokumentFile = {}

    for (const key in dataedit) {
        if (key.includes('file_') && (dataedit[key])) {
            if (!dataedit[key].isremote) {
                dokumentFile[key] = dataedit[key] 
            }
            dokumentParam[key] = key+GetFileExt(dataedit[key].name) 
        } else {
            dokumentParam[key] = ''
        }
    }
    
    for (const key in dataedit) {
        if (!key.includes('file_')) 
            dokumentParam[key] = dataedit[key]
    }
    console.log('param edit', JSON.stringify(dokumentParam))
    console.log('file edit', dokumentFile)
    // return //wait
    // eslint-disable-next-line no-undef
    const formdata = new FormData() 
    formdata.append('document', JSON.stringify(dokumentParam))
    for (const key in dokumentFile) {
        formdata.append('file', dokumentFile[key], `${key}${GetFileExt(dokumentFile[key].name)}`) 
    }  

    const response = yield call(api.klaimProposalEditRequest, formdata) 
  
    // success?
    if (response.ok) {  
        notification.success({
            message: 'Success',
            description: 'Data Klaim Modified',
            placement: 'topRight',
        });

        console.log('klaimProposalEdit response.data', response.data)

        const currentData = _.keyBy([...yield select(KlaimProposalSelectors.getPayload)], 'klaim_id')

        let cloneDataEdit = {...dataedit}
        const defaultPatcher = {...dataedit}
        for (const key in cloneDataEdit) {
            if (key.includes('file_')) 
                delete cloneDataEdit[key]
        }

        cloneDataEdit = _.merge(defaultPatcher, cloneDataEdit)

        const modifier = _.keyBy([cloneDataEdit], 'klaim_id')

        const merge = {
            ...currentData,
            ...modifier
        }

        yield put(KlaimProposalActions.klaimProposalSuccess(_.values(merge)))
        yield put(KlaimProposalActions.klaimProposalEditSuccess(response.data))
        if (cb) cb(true)
    } else {
        let err = (response.data && response.data.meta && response.data.meta.message) ? response.data.meta.message : 'Error when Modifying Data Faktur, Please try again'
        notification.error({
            message: 'Failed',
            description: err,
            placement: 'topRight',
        });
        yield put(KlaimProposalActions.klaimProposalEditFailure())
        if (cb) cb(false)
    }
}

export function * klaimProposalApproveStatusRequest (api, action) {
    const { dataapprovestatus, cb } = action
 

    const response = yield call(api.klaimProposalApproveStatusRequest, dataapprovestatus) 

    // success?
    if (response.ok) {      
        //disini kasih pesan
        notification.success({
            message: 'Success',
            description: response.data.message,
            placement: 'topRight',
        })
        yield put(KlaimProposalActions.klaimProposalApproveStatusSuccess(response.data)) 
        if (cb) cb(true)
    } else {
        let err = (response.data && response.data.meta && response.data.meta.message) ? response.data.meta.message : 'Error when Modifying Data Faktur, Please try again'
        notification.error({
            message: 'Failed',
            description: err,
            placement: 'topRight',
        });
        yield put(KlaimProposalActions.klaimProposalApproveStatusFailure())
        if (cb) cb(false)
    }
} 


export function * klaimProposalDeleteRequest (api, action) {
    const { datadelete, cb } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.klaimProposalDeleteRequest, datadelete) 

    // success?
    if (response.ok) {
        notification.success({
            message: 'Success',
            description: response.data.message,
            placement: 'topRight',
        });

        console.log('klaimProposalDelete response.data', response.data)
        yield put(KlaimProposalActions.klaimProposalDeleteSuccess(datadelete.klaim_id))
        if (cb) cb(true)
    } else {
        if (response.data && response.data.meta && response.data.meta.message) {
            notification.error({
                message: 'Failed',
                description: response.data.meta.message,
                placement: 'topRight',
            })
        } else {
            notification.error({
                message: 'Failed',
                description: 'Unknown error Saat menghapus Data Proposal, Harap Coba Lagi',
                placement: 'topRight',
            })
        }
        yield put(KlaimProposalActions.klaimProposalDeleteFailure())
        if (cb) cb(false)
    }
}