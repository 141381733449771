import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    dropdownRequest: ['property','data', 'cb'],
    dropdownSuccess: ['property', 'payload'],
    dropdownFailure: ['property']
})

export const DropdownTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: null,
    fetching: null,
    payload: {},
    error: null
})

/* ------------- Selectors ------------- */

export const DropdownSelectors = {
    getData: state => state.data
}

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { property, data }) =>
    state.merge({ fetching: {...state.fetching, [property]: true}, data: {...state.data,[property]: data}, payload: {...state.payload, [property]: []}, error: {...state.error,[property]: null}   })

// successful api lookup
export const success = (state, action) => {
    const { property, payload } = action
    return state.merge({ fetching: {...state.fetching,[property]: false}, error: {...state.error,[property]: null}, payload: {...state.payload, [property]: payload} })
}

// Something went wrong somewhere.
export const failure = (state, {property}) =>
    state.merge({ fetching: {...state.fetching,[property]: false}, error: {...state.error,[property]: true } })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.DROPDOWN_REQUEST]: request,
    [Types.DROPDOWN_SUCCESS]: success,
    [Types.DROPDOWN_FAILURE]: failure
})
