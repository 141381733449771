import { call, put } from 'redux-saga/effects'
import { notification } from 'antd'
import  EditItemCMOActions  from '../appRedux/reducers/EditItemCMORedux'

export function * cmorequest(api, action){
    const { data } = action
}


export function * dataItemCMORequest(api, action){
    const { dataItemCMO, cb } = action
    const response = yield call(api.edititemcmo, dataItemCMO)
    // console.log('order res', response.data.result)
    if (response.ok) {
        const result = response.data.results ? response.data.results : [response.data.result]
        console.log(response.data.message);
        yield put(EditItemCMOActions.dataItemCmoSuccess(result))
    } else {
    //         // yield put(OrderActions.orderFailure())
    }

}

//sorry mas, saya buat baru disini sagas functionnya, saya samakan nama functionnya menjadi 'dataEditItemCmoRequest' seperti di actionnya agar tidak bingung
export function * dataEditItemCmoRequest(api, action){
    const { dataItemCMO, cb } = action
    const response = yield call(api.edititemcmo, dataItemCMO)
    // console.log('order res', response.data.result)
    if (response.ok) {
        // const result = response.data.results ? response.data.results : [response.data.result]
        console.log(response.data.message);

        if (response.data.error) {
            if (cb) cb(false, response.data.message)
            // notification.success({
            //     message: 'Success',
            //     description: response.data.message,
            //     placement: 'topRight',
            // })
            yield put(EditItemCMOActions.dataEditItemCmoFailure())
            return
        }
        if (cb) cb(true, response.data.message)
        yield put(EditItemCMOActions.dataEditItemCmoSuccess(response.data.message))
    } else {
        if (cb) cb(false, null)
        yield put(EditItemCMOActions.dataEditItemCmoFailure())
    }

}

