/* ***********************************************************
* A short word on how to use this automagically generated file.
* We're often asked in the ignite gitter channel how to connect
* to a to a third party api, so we thought we'd demonstrate - but
* you should know you can use sagas for other flow control too.
*
* Other points:
*  - You'll need to add this saga to sagas/index.js
*  - This budgetWorkflow uses the api declared in sagas/index.js, so
*    you'll need to define a constant in that file.
*************************************************************/

import { call, put } from 'redux-saga/effects'
import WorkflowActions from '../../appRedux/reducers/MasterData/WorkflowRedux'
// import { GetAlatPengamanSelectors } from '../Redux/GetAlatPengamanRedux'

export function * getMasterWorkflow (api, action) {
    const { data } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.getMasterWorkflow, data)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetWorkflow response.data', response.data)
        yield put(WorkflowActions.masterWorkflowSuccess(response.data.results, response.data.meta))
    } else {
        yield put(WorkflowActions.masterWorkflowFailure())
    }
}

export function * getDetailMasterWorkflow (api, action) {
    const { data, id } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.getMasterWorkflow, data, id)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetWorkflow response.data', response.data)
        let multiple_activity = response.data.result.activity
        if (multiple_activity && multiple_activity.length) {
            multiple_activity = multiple_activity.map(value => {
                return `${value.activity_id}_${value.activity_code}`
            })

        }

        yield put(WorkflowActions.masterWorkflowDetailSuccess({
            ...response.data.result,
            multiple_activity
        }))
    } else {
        yield put(WorkflowActions.masterWorkflowDetailFailure())
    }
}

export function * createMasterWorkflow (api, action) {
    const { data, util: { info, callback } } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.postMasterWorkflow, data)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetWorkflow response.data', response.data)
        info.success(response.data.message)
        callback()
        yield put(WorkflowActions.masterWorkflowCreateSuccess())
    } else {
        info.error(response.data.message)
        yield put(WorkflowActions.masterWorkflowCreateFailure())
    }
}

export function * editMasterWorkflow (api, action) {
    const { data, util: { info, callback } } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.putMasterWorkflow, data)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetWorkflow response.data', response.data)
        info.success(response.data.message)
        callback()
        yield put(WorkflowActions.masterWorkflowEditSuccess())
    } else {
        info.error(response.data.message)
        yield put(WorkflowActions.masterWorkflowEditFailure())
    }
}

export function * deleteMasterWorkflow (api, action) {
    const { data, id, info } = action
    // get current data from Store
    // const currentData = yield select(GetAlatPengamanSelectors.getData)
    // make the call to the api
    const response = yield call(api.deleteMasterWorkflow, data, id)

    // success?
    if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetWorkflow response.data', response.data)
        info.success(response.data.message)
        yield put(WorkflowActions.masterWorkflowDeleteSuccess(id))
        yield put(WorkflowActions.masterWorkflowRequest())
    } else {
        info.error(response.data.message)
        yield put(WorkflowActions.masterWorkflowDeleteFailure())
    }
}

export function * deleteMasterWorkflowDetail (api, action) {
  const { data, id, info } = action
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.deleteMasterWorkflowDetail, data, id.detail)

  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log('budgetWorkflow response.data', response.data)
    info.success(response.data.message)
    yield put(WorkflowActions.masterWorkflowDeleteDetailSuccess())
    yield put(WorkflowActions.masterWorkflowDetailRequest(null, id.workflow))
  } else {
    info.error(response.data.message)
    yield put(WorkflowActions.masterWorkflowDeleteDetailFailure())
  }
}
