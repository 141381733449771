import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    fakturRequest: ['data'],
    fakturSuccess: ['payload', 'pagination'],
    fakturFailure: null,

    fakturDetailRequest: ['datadetail', 'id'],
    fakturDetailSuccess: ['payloaddetail'],
    fakturDetailFailure: null,

    fakturAddRequest: ['dataadd', 'cb'],
    fakturAddSuccess: ['payloadadd'],
    fakturAddFailure: null,

    fakturDeleteRequest: ['datadelete', 'cb'],
    fakturDeleteSuccess: ['payloaddelete'],
    fakturDeleteFailure: null,

    fakturEditRequest: ['dataedit', 'cb'],
    fakturEditSuccess: ['payloadedit'],
    fakturEditFailure: null,

    fakturApproveRejectRequest : ['act', 'dataar', 'cb'],
    fakturApproveRejectSuccess: ['payloadar'],
    fakturApproveRejectFailure: null,

    fakturCekBastRequest: ['datacekbast'], 
    fakturCekBastEnd: ['doneid'], 

})

export const FakturTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: null,
    fetching: null,
    payload: [],
    error: null,

    datadetail: null,
    fetchingdetail: null,
    payloaddetail: null,
    errordetail: null,

    dataadd: null,
    fetchingadd: null,
    payloadadd: null,
    erroradd: null,

    dataedit: null,
    fetchingedit: null,
    payloadedit: null,
    erroredit: null,

    datadelete: null,
    fetchingdelete: null,
    payloaddelete: null,
    errordelete: null,

    dataar: null,
    fetchingar: null,
    payloadar: null,
    errorar: null,

    datacekbast: [],
}) 

/* ------------- Selectors ------------- */

export const FakturSelectors = {
    getData: state => state.faktur.data,
    getPayload: state => state.faktur.payload,
}

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
    state.merge({ fetching: true, data })

// successful api lookup
export const success = (state, action) => {
    const { payload, pagination } = action
    return state.merge({ fetching: false, error: null, payload, pagination: (pagination) ? pagination : state.pagination })
}

// Something went wrong somewhere.
export const failure = state =>
    state.merge({ fetching: false, error: true })

  
//DETAIL
export const requestdetail = (state, { datadetail }) =>
    state.merge({ fetchingdetail: true, datadetail, payloaddetail: null })

// successful api lookup
export const successdetail = (state, action) => {
    const { payloaddetail } = action
    return state.merge({ fetchingdetail: false, errordetail: null, payloaddetail })
}

// Something went wrong somewhere.
export const failuredetail = state =>
    state.merge({ fetchingdetail: false, errordetail: true, payloaddetail: null })


//ADD
export const requestadd = (state, { dataadd }) =>
    state.merge({ fetchingadd: true, dataadd, payloadadd: null })

// successful api lookup
export const successadd = (state, action) => {
    const { payloadadd } = action
    return state.merge({ fetchingadd: false, erroradd: null, payloadadd, payload:[payloadadd, ...state.payload ] })
}

// Something went wrong somewhere.
export const failureadd = state =>
    state.merge({ fetchingadd: false, erroradd: true, payloadadd: null })


//EDIT
export const requestedit = (state, { dataedit }) =>
    state.merge({ fetchingedit: true, dataedit, payloadedit: null })

// successful api lookup
export const successedit = (state, action) => {
    const { payloadedit } = action
    return state.merge({ fetchingedit: false, erroredit: null, payloadedit/*, payload:[...state.payload, payloadedit]*/ })
}

// Something went wrong somewhere.
export const failureedit = state =>
    state.merge({ fetchingedit: false, erroredit: true, payloadedit: null })


//DELETE
export const requestdelete = (state, { datadelete }) =>
    state.merge({ fetchingdelete: true, datadelete, payloaddelete: null })

// successful api lookup
export const successdelete = (state, action) => {
    const { payloaddelete } = action
    return state.merge({ fetchingdelete: false, errordelete: null, payloaddelete, payload:[...state.payload.filter( x => x.c_invoice_id !== payloaddelete )] })
}

// Something went wrong somewhere.
export const failuredelete = state =>
    state.merge({ fetchingdelete: false, errordelete: true, payloaddelete: null })  



export const requestar = (state, { dataar }) =>
    state.merge({ fetchingar: true, dataar, payloadar: null })

// successful api lookup
export const successar = (state, action) => {
    const { payloadar } = action
    return state.merge({ fetchingar: false, errorar: null, payloadar/*, payload:[...state.payload, payloadar]*/ })
}

// Something went wrong somewhere.
export const failurear = state =>
    state.merge({ fetchingar: false, errorar: true, payloadar: null })


//CEK BAST
export const bastreq = (state, { datacekbast }) =>
    state.merge({ datacekbast: [...state.datacekbast, datacekbast.c_invoice_id] })

export const bastend = (state, action) => {
    const { doneid } = action
    return state.merge({ datacekbast: [...state.datacekbast.filter(x => x !== doneid ) ] })
}
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.FAKTUR_REQUEST]: request,
    [Types.FAKTUR_SUCCESS]: success,
    [Types.FAKTUR_FAILURE]: failure,

    [Types.FAKTUR_DETAIL_REQUEST]: requestdetail,
    [Types.FAKTUR_DETAIL_SUCCESS]: successdetail,
    [Types.FAKTUR_DETAIL_FAILURE]: failuredetail,

    [Types.FAKTUR_ADD_REQUEST]: requestadd,
    [Types.FAKTUR_ADD_SUCCESS]: successadd,
    [Types.FAKTUR_ADD_FAILURE]: failureadd,

    [Types.FAKTUR_EDIT_REQUEST]: requestedit,
    [Types.FAKTUR_EDIT_SUCCESS]: successedit,
    [Types.FAKTUR_EDIT_FAILURE]: failureedit,

    [Types.FAKTUR_DELETE_REQUEST]: requestdelete,
    [Types.FAKTUR_DELETE_SUCCESS]: successdelete,
    [Types.FAKTUR_DELETE_FAILURE]: failuredelete,

    [Types.FAKTUR_APPROVE_REJECT_REQUEST]: requestar,
    [Types.FAKTUR_APPROVE_REJECT_SUCCESS]: successar,
    [Types.FAKTUR_APPROVE_REJECT_FAILURE]: failurear,

    [Types.FAKTUR_CEK_BAST_REQUEST]: bastreq,
    [Types.FAKTUR_CEK_BAST_END]: bastend,
    
})
