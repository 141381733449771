/* eslint-disable no-undef */
import React from 'react';
import { Layout, Spin, Alert } from 'antd';
import _ from 'lodash'

import Sidebar from '../Sidebar/index';
import HorizontalDefault from '../Topbar/HorizontalDefault/index';
import HorizontalDark from '../Topbar/HorizontalDark/index';
import InsideHeader from '../Topbar/InsideHeader/index';
import AboveHeader from '../Topbar/AboveHeader/index';
import BelowHeader from '../Topbar/BelowHeader/index';

import Topbar from '../Topbar/index';
import { footerText } from 'util/config';
import App from 'routes/index';
import { useSelector } from 'react-redux';
import {
    NAV_STYLE_ABOVE_HEADER,
    NAV_STYLE_BELOW_HEADER,
    NAV_STYLE_DARK_HORIZONTAL,
    NAV_STYLE_DEFAULT_HORIZONTAL,
    NAV_STYLE_DRAWER,
    NAV_STYLE_FIXED,
    NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
    NAV_STYLE_MINI_SIDEBAR,
    NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    TAB_SIZE
} from '../../constants/ThemeSetting';
import NoHeaderNotification from '../Topbar/NoHeaderNotification/index';
import { useRouteMatch, useLocation } from 'react-router-dom';

import { connect } from 'react-redux';

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import { css } from '@emotion/core';
const { Content, Footer } = Layout;

const MainApp = ({ loadingoverlay }) => {

    const { width, navStyle } = useSelector(({ settings }) => settings);
    const { loading } = useSelector(({commonData})=> commonData)
    const match = useRouteMatch();

    const getContainerClass = (navStyle) => {
        switch (navStyle) {
        case NAV_STYLE_DARK_HORIZONTAL:
            return 'gx-container-wrap';
        case NAV_STYLE_DEFAULT_HORIZONTAL:
            return 'gx-container-wrap';
        case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
            return 'gx-container-wrap';
        case NAV_STYLE_BELOW_HEADER:
            return 'gx-container-wrap';
        case NAV_STYLE_ABOVE_HEADER:
            return 'gx-container-wrap';
        default:
            return '';
        }
    };
    const getNavStyles = (navStyle) => {
        switch (navStyle) {
        case NAV_STYLE_DEFAULT_HORIZONTAL:
            return <HorizontalDefault />;
        case NAV_STYLE_DARK_HORIZONTAL:
            return <HorizontalDark />;
        case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
            return <InsideHeader />;
        case NAV_STYLE_ABOVE_HEADER:
            return <AboveHeader />;
        case NAV_STYLE_BELOW_HEADER:
            return <BelowHeader />;
        case NAV_STYLE_FIXED:
            return <Topbar />;
        case NAV_STYLE_DRAWER:
            return <Topbar />;
        case NAV_STYLE_MINI_SIDEBAR:
            return <Topbar />;
        case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
            return <NoHeaderNotification />;
        case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
            return <NoHeaderNotification />;
        default:
            return null;
        }
    };

    const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

    const overrideOverlay = css`
  text-color: red;
`;

    const getSidebar = (navStyle, width) => {
        if (width < TAB_SIZE) {
            return <Sidebar />;
        }
        switch (navStyle) {
        case NAV_STYLE_FIXED:
            return <Sidebar />;
        case NAV_STYLE_DRAWER:
            return <Sidebar />;
        case NAV_STYLE_MINI_SIDEBAR:
            return <Sidebar />;
        case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
            return <Sidebar />;
        case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
            return <Sidebar />;
        default:
            return null;
        }
    };

    const location = useLocation()
    // window.location.reload()
    const menus = JSON.parse(localStorage.getItem('menus'))
    let userDoesntHaveThisMenu = false
    if (menus) {
    // const menusRouteOnly = ['/dashboard', ...(menus.map(x => x.route))]
        let menusRouteOnly = [...(menus.map(x => {
            
            if (x.sub)  {
                let tempsub = []
                x.sub.forEach(y => {
                    tempsub.push(y.route)
                });
                return tempsub
            }
            
            return x.route
        }))] 

        //sisipkan logout-sso karena route ini mandatory di segalanya
        menusRouteOnly.push('/logout-sso');
        // console.log('menusRouteOnly 2', menusRouteOnly)
        
        // console.log('[menusRouteOnly]', _.flatten(menusRouteOnly))

        userDoesntHaveThisMenu = !_.flatten(menusRouteOnly).includes(`/${location.pathname.split('/')[1]}`)
    // console.log('terpanggil 2 - menusRouteOnly', location, menusRouteOnly, userDoesntHaveThisMenu);
    }
    // const dashboardLoc = {pathname: "/dashboard", search: "", hash: "", state: undefined, key: "efmtpm"}
    // console.log('locationzzz', location.pathname.split('/')[1])

    if (userDoesntHaveThisMenu) {
        window.location = menus[0].route
        return (
            <LoadingOverlay
                active={loadingoverlay.isloading}
                styles={overrideOverlay}
                text={loadingoverlay.text}
                spinner={<BounceLoader css={override} color="#8CC545" />}
            />
        )
    }

    return (
        <LoadingOverlay
            active={loadingoverlay.isloading}
            styles={overrideOverlay}
            text={loadingoverlay.text}
            spinner={<BounceLoader css={override} color="#8CC545" />}
        >
            <Spin tip="Loading..." spinning={loading}>
                <Layout className="gx-app-layout">
                    {getSidebar(navStyle, width)}
                    <Layout>
                        {getNavStyles(navStyle)}
                        <Content className={`gx-layout-content ${getContainerClass(navStyle)} `}>


                            <App match={match} />
                            {/* <Footer>
              <div className="gx-layout-footer-content">
                {footerText}
              </div>
            </Footer> */}
                        </Content>
                    </Layout>
                </Layout>
            </Spin>
        </LoadingOverlay>
    )
};

const mapStateToProps = (state) => {
    return {
        loadingoverlay: state.loadingoverlay
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainApp);

